import React, { useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { UserListInterface } from './../types';
import { toggleBanned } from './../usersApi';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';

type Props = {
  user: UserListInterface | undefined;
  setModalState: (modalState: boolean) => void;
  fetchUsers: () => void;
};

const BanUserModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (banned: boolean, banned_email: boolean): void => {
    setLoading(true);
    if (props.user) {
      toggleBanned(props.user.id, banned, banned_email)
        .then(() => {
          if (banned) {
            notifySuccess('Användaren avslutad!');
          } else {
            notifySuccess('Användaren återställt!');
          }
        })
        .then(() => {
          props.fetchUsers();
        })
        .catch(() => notifyError('Det gick inte att uppdatera användaren.'))
        .finally(() => {
          setLoading(false);
          props.setModalState(false);
        });
    }
  };

  return (
    <div>
      <DialogTitle>Avsluta anställning</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              <p>Är du säker på att du vill avsluta anställningen på {props.user?.name}?</p>
              <p>Vill du även skicka ett avslutningsmejl?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              color="error"
              onClick={(): void => {
                props.setModalState(false);
              }}
            >
              NEJ
            </Button>
            <Button fullWidth color="primary" onClick={(): void => handleSubmit(true, false)}>
              JA
            </Button>
            <Button fullWidth color="primary" onClick={(): void => handleSubmit(true, true)}>
              JA + MEJL
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </div>
  );
};

export default BanUserModal;
