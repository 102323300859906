import React, { MouseEvent, useState } from 'react';

import { Button, Divider, FormControl, Grid, IconButton, InputAdornment } from '@mui/material';

import { FormTextField, FormToggle } from '../../Common/FormItems';
import AddTeams from './AddTeams';
import { OptionsInterface } from '../../Common/types';
import { initialValues } from '../residenceSchema';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { Link } from 'react-router-dom';
import { ResidenceInterface } from '../types';
import { baseURL } from '../../Common/utilities';
import { authenticateResidencePage } from '../../ResidencePage/residencePageApi';
import { useNotify } from '../../Common/snackbarHooks';
import { setResidenceLoginCookie } from '../../ResidencePage/residenceLoginCookie';
import { Lock, LockOpenOutlined, LockOutlined } from '@mui/icons-material';

interface ResidenceFormProps {
  residence?: ResidenceInterface;
  values: typeof initialValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled: boolean;
  residenceGroupsMinimalList: OptionsInterface[];
  residenceClustersMinimalList: OptionsInterface[];
  regionsMinimalList: OptionsInterface[];
  isCreate: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  removal?: () => void;
}

const ResidenceForm: React.FC<ResidenceFormProps> = (props: ResidenceFormProps) => {
  const { notifyError } = useNotify();
  const [allowPasswordEdit, setAllowPasswordEdit] = useState(false);

  async function residenceSignIn(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!props.residence?.residence_password || !baseURL) return;
    await authenticateResidencePage(props.residence.residence_password)
      .then(({ data }) => {
        setResidenceLoginCookie(data.auth_token);
        window.open(process.env.REACT_APP_RESIDENCE_URL!, '_blank');
      })
      .catch(() => {
        notifyError('Något gick fel; kunde inte logga in');
      });
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="single"
              id="residence_group"
              name="residence_group"
              label="Välj avtalspart för boendet"
              options={props.residenceGroupsMinimalList}
              optionEquality={(option, value) => option.id === value.id}
              value={props.values.residence_group}
              onChange={(_, value) => props.setFieldValue('residence_group', value)}
              error={props.errors.residence_group}
            />
          </FormControl>
        </Grid>
        <FormTextField
          size={6}
          fieldName="name"
          label="Namn på boendet"
          placeholder="Namn"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="multiple"
              id="residence_clusters"
              name="residence_clusters"
              label="Välj grupper boendet skall kopplas till"
              options={props.residenceClustersMinimalList}
              optionEquality={(option, value) => option.id === value.id}
              values={props.values.residence_clusters}
              onChange={(_, values) => props.setFieldValue('residence_clusters', values)}
              error={props.errors.residence_clusters}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="single"
              id="region"
              name="region"
              label="Välj region boendet skall kopplas till"
              options={props.regionsMinimalList}
              optionEquality={(option, value) => option.id === value.id}
              value={props.values.region}
              onChange={(_, values) => props.setFieldValue('region', values)}
              error={props.errors.region}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!props.isCreate && (
        <Grid container spacing={2}>
          <FormTextField
            size={4}
            fieldName="residence_password"
            label="Lösenord"
            placeholder="Lösenord"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            disabled={!allowPasswordEdit}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password editablility"
                    onClick={() => setAllowPasswordEdit(true)}
                    disabled={allowPasswordEdit}
                  >
                    {allowPasswordEdit ? <LockOpenOutlined /> : <LockOutlined />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {process.env.REACT_APP_RESIDENCE_URL && (
            <Grid item sm={2}>
              <Link to={process.env.REACT_APP_RESIDENCE_URL}>
                <Button variant="contained" onClick={residenceSignIn} className="!mt-6">
                  Boendesida
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      )}
      <Divider className="!my-5" />
      <Grid container spacing={2}>
        <FormTextField
          size={6}
          fieldName="visiting_address"
          label="Adress till boendet"
          placeholder="Besökadress"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={3}
          fieldName="zip_code"
          label="Postnummer till boendet"
          placeholder="Postnummer"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={3}
          fieldName="city"
          label="Ort till boendet"
          placeholder="Ort"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <FormToggle
          size={6}
          fieldName="gets_invoice_details_from_residence_group"
          label="Hämta faktureringsuppgifter från avtalspart"
          values={props.values}
          onChange={props.handleChange}
        />
      </Grid>
      {!props.values.gets_invoice_details_from_residence_group && (
        <React.Fragment>
          <Grid container spacing={2}>
            <FormTextField
              size={6}
              fieldName="invoice_detail.recipient"
              label="Namnet på fakturamottagaren"
              placeholder="FakturaMottagare"
              required={true}
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
            <FormTextField
              size={6}
              fieldName="invoice_detail.address_line_1"
              label="Fakturaadress till avtalsparten"
              placeholder="Fakturaadress"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>

          <Grid container spacing={2}>
            <FormTextField
              size={6}
              fieldName="invoice_detail.address_line_2"
              label="Ytterligare adressinformation"
              placeholder="Adress rad 2"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
            <FormTextField
              size={3}
              fieldName="invoice_detail.zip_code"
              label="Postnummer till avtalsparten"
              placeholder="Postnummer"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
            <FormTextField
              size={3}
              fieldName="invoice_detail.city"
              label="Ort till avtalsparten"
              placeholder="Ort"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>

          <Grid container spacing={2}>
            <FormTextField
              size={6}
              fieldName="invoice_detail.reference"
              label="Referensperson till avtalsparten"
              placeholder="Referensperson"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
            {/* <FormTextField
              size={6}
              fieldName="invoice_detail.gln_number"
              label="GLN-nummer till avtalsparten"
              placeholder="GLN-nummer"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            /> */}
          </Grid>

          <Grid container spacing={2}>
            <FormTextField
              size={6}
              fieldName="invoice_detail.electronic_reference"
              label="Elektroniskreferens till avtalsparten"
              placeholder="Elektroniskreferens"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
            <FormTextField
              size={6}
              fieldName="invoice_detail.org_number"
              label="Org-nummer till avtalsparten"
              placeholder="Org-nummer"
              required={false}
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>

          <Grid container spacing={2}>
            <FormTextField
              size={6}
              fieldName="notes"
              label="Här kan du skriva en anteckning"
              placeholder="Anteckning"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>
        </React.Fragment>
      )}
      <Divider style={{ marginBottom: '20px' }} />
      <AddTeams
        isCreate={props.isCreate}
        values={props.values}
        handleChange={props.handleChange}
        errors={props.errors}
      />
      <Grid container spacing={2} alignItems="center" className="mb-3">
        <FormToggle
          size={3}
          fieldName="gets_only_for_locals_from_residence_group"
          label="Hämta kommunspärr från avtalspart"
          values={props.values}
          onChange={props.handleChange}
        />
        {!props.values.gets_only_for_locals_from_residence_group && (
          <FormToggle
            size={7}
            fieldName="only_for_locals"
            label="Kommunspärr"
            values={props.values}
            onChange={props.handleChange}
          />
        )}
        {props.values.gets_only_for_locals_from_residence_group && <Grid item xs={7} />}
        <FormToggle
          size={2}
          fieldName="is_active"
          label="Boendet är aktivt"
          values={props.values}
          onChange={props.handleChange}
        />
      </Grid>
      <Grid container spacing={2} alignItems="center" className="mb-2">
        <Grid item xs={5} />
        <Grid item xs={2}>
          <Button fullWidth type="submit" variant="contained" disabled={props.submitDisabled}>
            {props.isCreate ? 'Lägg till' : 'Spara'}
          </Button>
        </Grid>
        <Grid item xs={3} />
        {!props.isCreate && props.values.deletable && (
          <Grid item xs={2}>
            <FormControl margin="dense" required fullWidth>
              <Button
                variant="outlined"
                color="error"
                onClick={(): void => {
                  if (props.removal) props.removal();
                }}
              >
                Ta Bort
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ResidenceForm;
