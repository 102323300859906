import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import {
  APIProvider,
  AdvancedMarker,
  ControlPosition,
  InfoWindow,
  Map,
  MapControl,
  useAdvancedMarkerRef,
  useMap,
} from '@vis.gl/react-google-maps';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RotateLoader } from 'react-spinners';
import { useNotify } from '../Common/snackbarHooks';
import { stringToPositiveInt, useQuery } from '../Common/utilities';
import { getResidencePoints } from './residenceApi';
import { ResidencePointInterface } from './types';
import ResidenceIcon from './components/ResidenceIcon';
import FormAutocompleteBox from '../Common/FormItems/FormAutocompleteBox';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY ? process.env.REACT_APP_GOOGLE_MAPS_KEY : '';
const DEFAULT_CENTER = { lat: 58.3293, lng: 15.1686 };
const DEFAULT_ZOOM = 6;

export default function ResidenceMap() {
  const [values] = useQuery({
    center: (val) => stringToPositiveInt(val),
  });
  const { notifyError } = useNotify();
  const [residencePoints, setResidencePoints] = useState<ResidencePointInterface[]>();
  const center = useMemo(() => {
    return residencePoints?.find(({ id }) => id === values.center);
  }, [residencePoints]);

  useEffect(() => {
    getResidencePoints()
      .then((data) => setResidencePoints(data.data.residences))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'));
  }, []);

  return (
    <APIProvider apiKey={API_KEY}>
      {residencePoints ? (
        <Map
          mapId="bf51a910020fa25a"
          style={{ width: '100vw', height: '100vh' }}
          defaultCenter={center?.point ?? DEFAULT_CENTER}
          defaultZoom={DEFAULT_ZOOM * (center ? 2.5 : 1)}
        >
          <Markers points={residencePoints} />
        </Map>
      ) : (
        <div className="fixed top-1/2 left-1/2">
          <RotateLoader />
        </div>
      )}
    </APIProvider>
  );
}

function Markers({ points }: { points: ResidencePointInterface[] }) {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [key: number]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);
  const [openInfo, setOpenInfo] = useState<number | null>(null);
  const [showLabels, setShowLabels] = useState(false);

  useEffect(() => {
    if (!map) return;
    if (clusterer.current) return;
    clusterer.current = new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  useEffect(() => {
    if (!map) return;
    map.addListener('zoom_changed', () => {
      setShowLabels((map.getZoom() ?? 0) > 10);
    });
  }, [map]);

  const setMarkerRef = (marker: Marker | null, key: number) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      <MapControl position={ControlPosition.TOP}>
        <FormAutocompleteBox
          type="single"
          placeholder="Sök boende"
          options={points}
          value={null}
          onChange={(_, option) => {
            if (!option || !map) return;
            map.setCenter(option.point);
            map.setZoom(DEFAULT_ZOOM * 2.5);
          }}
          className="!mt-6 !w-64 !bg-white !rounded"
        />
      </MapControl>
      {points.map((point) => (
        <InfoMarker
          key={point.id}
          point={point}
          isOpen={openInfo === point.id}
          setOpenInfo={setOpenInfo}
          setMarkerRef={setMarkerRef}
          showLabel={showLabels}
        />
      ))}
    </>
  );
}

type InfoMarkerProps = {
  point: ResidencePointInterface;
  isOpen: boolean;
  setOpenInfo: (value: number | null) => void;
  setMarkerRef?: (marker: Marker | null, key: number) => void;
  showLabel?: boolean;
};

function InfoMarker({ point, isOpen, setOpenInfo, setMarkerRef, showLabel }: InfoMarkerProps) {
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={(marker) => {
          setMarkerRef?.(marker, point.id);
          return markerRef(marker);
        }}
        onClick={() => setOpenInfo(point.id)}
        position={{ ...point.point }}
        title={point.name}
      >
        <div className="relative" onMouseOver={() => setOpenInfo(point.id)}>
          {showLabel && !isOpen && (
            <span className="absolute bottom-full left-1/2 -translate-x-1/2 font-bold text-center">{point.name}</span>
          )}
          <ResidenceIcon className="text-xl" />
        </div>
      </AdvancedMarker>
      {isOpen && (
        <InfoWindow anchor={marker} maxWidth={200} onCloseClick={() => setOpenInfo(null)}>
          <h3 className="text-sm font-medium">{point.name}</h3>
          <p> {point.address}</p>
        </InfoWindow>
      )}
    </>
  );
}
