import React, { useState } from 'react';

import { getValue } from '../utilities';
import { FormControl, Grid, TextField } from '@mui/material';

type Props = {
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  fieldName: string;
  label: string;
  placeholder: string;
  values: any;
  onChange: any;
  errors: any;
  disabled?: boolean;
};

const FormTextFieldBlur: React.FC<Props> = (props) => {
  // hooks
  const [fieldValue, setFieldValue] = useState(getValue(props.values, props.fieldName));

  // functions
  const handleFieldBlur = (e: any): void => props.onChange(e);

  const handleFieldChange = (e: any): void => setFieldValue(e.target.value);

  // render
  return (
    <Grid item xs={props.size}>
      <FormControl variant="standard" required fullWidth margin="normal">
        <TextField
          disabled={props.disabled}
          error={!!getValue(props.errors, props.fieldName)}
          name={props.fieldName}
          label={props.label}
          placeholder={props.placeholder}
          value={fieldValue}
          onChange={handleFieldChange}
          onBlur={handleFieldBlur}
          helperText={getValue(props.errors, props.fieldName)}
        />
      </FormControl>
    </Grid>
  );
};

export default FormTextFieldBlur;
