import React from 'react';
import { BaseFilterPopover } from '../../Common/FilterPopover';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
  scheduled: boolean | undefined;
  setScheduled: (val: boolean) => void;
  notReported: boolean | undefined;
  setNotReported: (val: boolean) => void;
  reported: boolean | undefined;
  setReported: (val: boolean) => void;
  notExported: boolean | undefined;
  setNotExported: (val: boolean) => void;
  banned: boolean | undefined;
  setBanned: (val: boolean) => void;
};

export default function WorkShiftsFilterPopover({
  scheduled,
  setScheduled,
  notReported,
  setNotReported,
  reported,
  setReported,
  notExported,
  setNotExported,
  banned,
  setBanned,
}: Props) {
  return (
    <BaseFilterPopover>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa schemalagda
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={scheduled ?? true} onChange={(_, checked) => setScheduled(checked)} />}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa ej rapporterade
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={notReported ?? true} onChange={(_, checked) => setNotReported(checked)} />}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa rapporterade
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={reported ?? true} onChange={(_, checked) => setReported(checked)} />}
        />
      </div>
      <br></br>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa ej exporterade
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={notExported ?? true} onChange={(_, checked) => setNotExported(checked)} />}
        />
      </div>
      <br></br>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa avslutade anställda
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={banned ?? false} onChange={(_, checked) => setBanned(checked)} />}
        />
      </div>
    </BaseFilterPopover>
  );
}
