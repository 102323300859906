import { ListParams, sort_order } from '../Common/types';
import * as Yup from 'yup';

export const initialValues = {
  date: new Date().toISOString().split('T')[0],
  scheduled_participants: [],
  editable: true,
  deletable: false,
};

export const filterSortDefaults: ListParams = {
  sort_by: 'date',
  sort_order: sort_order.desc,
  show_reported: true,
  show_not_reported: true,
  show_scheduled: true,
  show_not_locked: true,
};

export const validationSchema = Yup.object().shape({
  require_approved_by: Yup.boolean(),
  date: Yup.date().required('* Passets datum är ett obligatoriskt fält').typeError('* Ogiltigt datum'),
  start_time: Yup.number()
    .nullable()
    .when('end_time', (end_time: number, schema: any) => {
      return schema.test({
        test: (start_time: number) => {
          if (!end_time) return true;
          return start_time < end_time;
        },
        message: '* Starttiden måste vara innan sluttiden',
      });
    })
    .required('* Starttiden är ett obligatoriskt fält')
    .typeError('* Ogiltig starttid'),
  end_time: Yup.number().nullable().required('* Sluttiden är ett obligatoriskt fält').typeError('* Ogiltig sluttid'),
  activity: Yup.string()
    .nullable()
    .when('require_approved_by', {
      is: true,
      then: Yup.string().required('* Passets aktivitet är ett obligatoriskt fält'),
    }),
  approved_by: Yup.string()
    .nullable()
    .when('require_approved_by', {
      is: true,
      then: Yup.string().required('* Godkänt av är ett obligatoriskt fält'),
    }),
});
