import React from 'react';

import { commonStyles, css } from '../../Common/styling';

import { isNotEmpty } from '../../Common/utilities';
import { Button, Grid, Paper, Typography } from '@mui/material';

type Props = {
  values: any;
  openCorrectionModal: any;
};

export const CorrectionsOnEditAgreement: React.FC<Props> = (props) => {
  return (
    <div>
      {isNotEmpty(props.values?.agreement_corrections) && (
        <div>
          <Grid container>
            <Grid item xs={12}>
              <Paper elevation={0} square>
                <Typography
                  style={{
                    textAlign: 'center',
                    padding: '16px',
                    fontWeight: 'bold' as const,
                    fontSize: '1em',
                    marginBottom: '20px',
                  }}
                >
                  Korrigeringar
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          {props.values.agreement_corrections.map((correction: any, index: number) => (
            <div key={index} className={css(commonStyles.greyRow)}>
              <Grid container spacing={2} style={{ marginBottom: '20px', padding: '5px' }}>
                <Grid item xs={3} style={{ paddingLeft: '15px' }}>
                  <b>{correction.type === 'WorkHourCorrection' ? 'Arb tim-korrigering' : 'Timpott-korrigering'}</b>:{' '}
                  {correction.notes}
                </Grid>
                <Grid item xs={3}>
                  {correction.team.name}
                </Grid>
                <Grid item xs={4}>
                  {correction.date}
                </Grid>
                <Grid item xs={2} style={correction.value >= 0 ? { color: 'blue' } : { color: 'red' }}>
                  {correction.value >= 0 ? '+' : ''}
                  {correction.value} timmar
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      )}
      {props.values.is_active && (
        <Grid container spacing={2} style={{ marginBottom: '20px' }} justifyContent="center">
          <Grid item xs={2}>
            <Button color="secondary" variant="outlined" fullWidth onClick={(e): void => props.openCorrectionModal(e)}>
              Gör en korrigering
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
