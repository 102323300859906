import { FieldArray, FieldArrayRenderProps } from 'formik';
import React, { ReactElement } from 'react';

import { Button, Divider, Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import FormHourMinutePicker from '../../Common/FormItems/FormHourMinutePicker';
import { FormTextField } from '../../Common/FormItems';
import { isNotEmpty } from '../../Common/utilities';
import { initialValues } from '../residenceSchema';

type Props = {
  values: typeof initialValues;
  handleChange: any;
  errors: any;
  isCreate: boolean;
};

const AddTeams: React.FC<Props> = (props) => {
  const emptyTeam = { name: '', standard_workday: 2.0, standard_attendance: 0 };

  return (
    <FieldArray
      name="teams"
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
        <React.Fragment>
          {isNotEmpty(props.values.teams) &&
            props.values.teams.map((team: any, index: number) => (
              <Grid container spacing={2} style={{ marginBottom: '20px' }} key={index}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <FormTextField
                    disabled={!props.isCreate}
                    fieldName={`teams.${index}.name`}
                    label={team.is_active || props.isCreate ? 'Namn på teamet' : 'Inaktivt team'}
                    placeholder="Namn"
                    values={props.values}
                    errors={props.errors}
                    onChange={props.handleChange}
                  />
                </Grid>
                {props.isCreate && (
                  <React.Fragment>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <FormHourMinutePicker
                        fieldName={`teams.${index}.standard_workday`}
                        label="Hur långa ska passen vara (i timmar)"
                        values={props.values}
                        errors={props.errors}
                        onChange={props.handleChange}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <FormTextField
                        fieldName={`teams.${index}.standard_attendance`}
                        label="Vilken standardnärvaro teamet skall ha (antal personer)"
                        placeholder="Standardnärvaro"
                        values={props.values}
                        errors={props.errors}
                        onChange={props.handleChange}
                      />
                    </Grid>
                  </React.Fragment>
                )}
                {(props.isCreate || team.invoicable) && (
                  <IconButton size="small" className="!self-center" onClick={(): void => arrayHelpers.remove(index)}>
                    <ClearIcon />
                  </IconButton>
                )}
              </Grid>
            ))}

          {props.isCreate && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                color="secondary"
                style={{ marginBottom: '20px' }}
                onClick={(): void => arrayHelpers.push(emptyTeam)}
              >
                <AddIcon style={{ marginRight: '10px' }} />
                LÄGG TILL TEAM
              </Button>
            </div>
          )}
          {isNotEmpty(props.values.teams) && props.isCreate && (
            <div>
              <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                  <FormTextField
                    size={12}
                    fieldName="teams_notes"
                    label="Här kan du skriva info till teamet"
                    multiline
                    placeholder="Skriv en anteckning till alla teamen på boendet"
                    values={props.values}
                    errors={props.errors}
                    onChange={props.handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          <Divider style={{ marginBottom: '20px' }} />
        </React.Fragment>
      )}
    />
  );
};

export default AddTeams;
