import React from 'react';

import { getValue } from '../utilities';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

type Props = {
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  fieldName: string;
  label: string;
  values: any;
  onChange: any;
  disabled?: boolean;
};

const FormCheckbox: React.FC<Props> = (props) => {
  return (
    <Grid item xs={props.size}>
      <FormControlLabel
        label={props.label}
        control={
          <Checkbox
            name={props.fieldName}
            checked={getValue(props.values, props.fieldName)}
            onChange={props.onChange}
            color="default"
            disabled={props.disabled}
          />
        }
      />
    </Grid>
  );
};

export default FormCheckbox;
