import React, { useContext, useEffect, useState } from 'react';
import { InterviewValues } from './formValues';
import { FormikErrors, FormikHandlers } from 'formik';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import { FormDateField, FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { MinimalTeam } from '../../Team/types';
import { getTeamMinimalList } from '../../Team/teamApi';
import { MinimalUser } from '../../User/types';
import { getUsersMinimalList } from '../../User/usersApi';
import dayjs from 'dayjs';
import AuthContext from '../../Common/AuthContext';
import { AuthContextValue } from '../../Common/types';
import { InterviewDetails } from '../types';
import { deleteInterview } from '../interviewApi';
import { useNotify } from '../../Common/snackbarHooks';
import { useNavigate } from 'react-router-dom';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import FormFreeDateField from '../../Common/FormItems/FormFreeDateField';
import ConditionalParent from '../../Common/ConditionalParent';

type InterviewFormProps = {
  values: InterviewValues;
  errors?: FormikErrors<InterviewValues>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  interview?: InterviewDetails;
  isSubmitting?: boolean;
  isValid?: boolean;
  dirty?: boolean;
};

export default function InterviewForm({
  values,
  errors,
  handleChange,
  setFieldValue,
  interview,
  isSubmitting,
  isValid,
  dirty,
}: InterviewFormProps) {
  const { profile } = useContext(AuthContext) as AuthContextValue;
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();

  const [teams, setTeams] = useState<MinimalTeam[]>();
  const [teamLeaders, setTeamLeaders] = useState<MinimalUser[]>();
  const [regionalManagers, setRegionalManagers] = useState<MinimalUser[]>();
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [archiveDialogVisible, setArchiveDialogVisible] = useState<boolean>(false);

  useEffect(() => {
    getTeamMinimalList({ is_active: true }).then(({ data }) => setTeams(data.data));
    getUsersMinimalList({ filter_by_role_name: 'team_leader', hide_banned: true }).then(({ data }) =>
      setTeamLeaders(data.data)
    );
    if (profile.role.short_name !== 'RC')
      getUsersMinimalList({ filter_by_role_name: 'regional_manager', hide_banned: true }).then(({ data }) =>
        setRegionalManagers(data.data)
      );
  }, []);

  function archiveInterview() {
    if (!interview) return;
    setLoadingArchive(true);
    deleteInterview(interview.id)
      .then(() => {
        notifySuccess('Intervjun har tagits bort.');
        navigate('/interviews');
      })
      .catch((error) => notifyError(`Det gick inte att ta bort intervjun: ${HandleError(error)}`))
      .finally(() => setLoadingArchive(false));
  }

  return (
    <div className="mx-8">
      <Grid container spacing={2} rowSpacing={-2}>
        <Grid item xs={6}>
          <FormControl margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="single"
              label="Team som intervjun avser"
              options={teams ?? []}
              value={values.team}
              error={errors?.team ?? null}
              onChange={(_, value) => setFieldValue('team', value)}
              loading={!teams}
              disableClearable
              disabled={!!interview?.locked_at}
            />
          </FormControl>
        </Grid>

        <FormFreeDateField
          size={6}
          label="Välj dag"
          placeholder="Dag"
          value={values.date ? dayjs(values.date) : null}
          error={errors?.date ?? null}
          maxDate={dayjs()}
          onlyValid={false}
          disabled={!!interview?.locked_at}
          onChange={(day) => setFieldValue('date', day)}
        />

        <FormTextField
          size={6}
          fieldName="amount"
          label="Antal intervjuer"
          placeholder="Antal"
          values={values}
          errors={errors}
          disabled={!!interview?.locked_at}
          onChange={handleChange}
        />

        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            margin="normal"
            label="Välj typ av intervju"
            helperText={errors?.type ?? ' '}
            value={values.type}
            error={!!errors?.type}
            onChange={({ target }) => {
              setFieldValue('type', target.value);
              if (target.value === 'TeamLeaderInterview') setFieldValue('attending_tl', null);
            }}
            disabled={!!interview?.locked_at}
          >
            <MenuItem value="ParticipantInterview">Deltagare</MenuItem>
            <MenuItem value="TeamLeaderInterview">Teamledare</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <FormControl margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="single"
              label="Närvarande TL"
              options={teamLeaders ?? []}
              value={values.attending_tl}
              error={errors?.attending_tl ?? null}
              onChange={(_, value) => setFieldValue('attending_tl', value)}
              loading={!teamLeaders}
              disabled={!!interview?.locked_at || values.type === 'TeamLeaderInterview'}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="single"
              label="Närvarande RC"
              options={
                profile.role.short_name !== 'RC'
                  ? regionalManagers ?? []
                  : [{ id: profile.id, name: [profile.first_name, profile.surname].filter(Boolean).join(' ') }]
              }
              value={values.attending_rc}
              error={errors?.attending_rc ?? null}
              onChange={(_, value) => setFieldValue('attending_rc', value)}
              loading={!regionalManagers && profile.role.short_name !== 'RC'}
              disabled={!!interview?.locked_at}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} rowSpacing={-2}>
        <Grid item xs={6}>
          <FormControlLabel
            className="!-mt-1"
            control={<Checkbox name="training" checked={values.training} onChange={handleChange} />}
            disabled={!!interview?.locked_at}
            label="Upplärningstillfälle"
          />
        </Grid>

        {interview && (
          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <p>Skapad av: {interview.created_by.name}</p>
                <p>Skapad datum: {dayjs(interview.created_at).format('YYYY-MM-DD')}</p>
              </div>
              {interview.updated_by && (
                <div className="flex justify-between">
                  <p>Uppdaterad av: {interview.updated_by.name}</p>
                  <p>Uppdaterad datum: {dayjs(interview.updated_at).format('YYYY-MM-DD')}</p>
                </div>
              )}
            </div>
          </Grid>
        )}
      </Grid>

      <Divider className="!mt-4 !mb-8" />

      <Grid container spacing={2} className="!pb-4">
        <Grid item xs={5} />
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            disabled={!dirty || !isValid || isSubmitting}
            fullWidth
            type="submit"
          >
            {isSubmitting ? (
              <CircularProgress size="1rem" className="!my-1" color="inherit" />
            ) : interview ? (
              'Spara'
            ) : (
              'Lägg till'
            )}
          </Button>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={2}>
          {interview && (
            <ConditionalParent
              on={!!interview.locked_at}
              parent={(children) => (
                <Tooltip title="Det går inte att ta bort en exporterad intervju">
                  <span>{children}</span>
                </Tooltip>
              )}
            >
              <Button
                color="error"
                variant="outlined"
                disabled={!!interview.locked_at || loadingArchive}
                fullWidth
                onClick={() => setArchiveDialogVisible(true)}
              >
                {loadingArchive ? <CircularProgress size="1rem" className="!my-1" color="inherit" /> : 'Ta bort'}
              </Button>
            </ConditionalParent>
          )}
          <ConfirmationDialog
            isVisible={archiveDialogVisible}
            title="Ta bort intervju"
            message="Är du säker på att du vill ta bort denna intervjun?"
            onClose={() => setArchiveDialogVisible(false)}
            onConfirm={archiveInterview}
          />
        </Grid>
      </Grid>
    </div>
  );
}
