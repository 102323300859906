import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../Common/styling';
import { OptionsInterface, RoleOptionsInterface } from '../Common/types';

import NewsForm from './components/NewsForm';
import { NewsInterface } from './types';
import { validationSchema, initialNewsValues } from './newsSchema';

import { getRolesMinimalList } from '../Common/commonApi';
import { createNews } from './newsApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { getResidenceClustersMinimalList } from '../ResidenceCluster/residenceClusterApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import { getRegionsMinimalList } from '../Region/regionApi';

const CreateNews: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const [residenceClustersMinimalList, setResidenceClustersMinimalList] = useState([]);
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [regionsMinimalList, setRegionsMinimalList] = useState([]);
  const [roleMinimalList, setRoleMinimalList] = useState<RoleOptionsInterface[]>([]);
  const [loading, setLoading] = useState(false);

  // functions
  useEffect(() => {
    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClustersMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'));

    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart'));

    getRegionsMinimalList()
      .then(({ data }) => setRegionsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'));

    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'));

    getRolesMinimalList({ hide_banned: true })
      .then(({ data }) => {
        setRoleMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över roller'));
  }, []); // eslint-disable-line

  const handleFormSubmit = ({ roles, residences, ...values }: NewsInterface, actions: FormikHelpers<any>): void => {
    setLoading(true);
    createNews({
      ...values,
      role_ids: roles!.map((r) => r.id as number),
      residence_ids: residences!.map((r) => r.id as number),
    })
      .then(() => setLoading(false))
      .then(() => notifySuccess('Nyheten har skapats'))
      .then(() => navigate(`/news`))
      .catch((error: any) => {
        setLoading(false);
        notifyError(`Det gick inte att skapa nyheten: \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till nyhet</h1>
        <CancelLink link={`/news`} />
      </div>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}
      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialNewsValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <NewsForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                roleMinimalList={roleMinimalList}
                residenceMinimalList={residenceMinimalList}
                residenceClustersMinimalList={residenceClustersMinimalList}
                residenceGroupsMinimalList={residenceGroupsMinimalList}
                regionsMinimalList={regionsMinimalList}
                isCreate={true}
                setFieldValue={setFieldValue}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateNews;
