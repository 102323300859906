import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { decode, encode } from 'base-64';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { RotateLoader } from 'react-spinners';

import { Button, Dialog, Divider, Grid, MenuItem, Table, TableBody, TableCell } from '@mui/material';

import { filterSortDefaults } from './usersSchema';
import { paginationDefaults } from '../Common/commonSchema';
import { EmptyList, MenuTableCell, Pagination, TableHead, TableRow } from '../Common/Table';
import { BackLink, CreateFab } from '../Common/ButtonLinks';
import { ListPagination, ListParams } from '../Common/types';
import FilterPopover from './components/FilterPopover';
import NewUserModal from './Profile/components/NewUserModal';
import BanUserModal from './components/BanUserModal';
import ContractModal from './components/ContractModal';
import SetPasswordModal from './components/SetPasswordModal';
import SendResetPasswordEmailModal from './components/SendResetPasswordEmailModal';

import { StyleSheet, commonStyles, css } from '../Common/styling';

import { SearchBar } from '../Common/SearchBar';
import appTheme from '../Common/styling/appTheme';

import { FilterAutocomplete } from '../Common/FilterPopover';

import { getRolesMinimalList } from '../Common/commonApi';
import { getUsers, sendWelcomeEmail, toggleBanned, updateUser } from './usersApi';
import { getTeamMinimalList } from '../Team/teamApi';
import { getResidenceClustersMinimalList } from '../ResidenceCluster/residenceClusterApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import { getRegionsMinimalList } from '../Region/regionApi';

import InfoModal from './components/InfoModal';

import { UserListInterface } from './types';
import { AuthContextValue, OptionsInterface } from '../Common/types';

import { useNotify } from '../Common/snackbarHooks';
import paramsFromUrl from '../Common/Hooks/paramsFromUrl';
import { EmailPopover } from '../Common/Popovers';
import { QuickAction } from '../Common/ButtonLinks';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import GroupIcon from '@mui/icons-material/Group';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HowToVoteIcon from '@mui/icons-material/HowToVote';

import AuthContext from '../Common/AuthContext';
import useGlobalPopstateListener from '../Common/Hooks/useGlobalPopstateListener';
import { MinimalTeam } from '../Team/types';
import { CustomTableHeadColumn } from '../Common/Table/CustomTableHead';

const styles = StyleSheet.create({
  mobile: {
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: '#5699ff',
    fontWeight: 'bold',
    justifyContent: 'flex-end',
    textDecoration: 'none',
  },
  email: {
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: '#5699ff',
    fontWeight: 'bold',
    justifyContent: 'flex-end',
    textDecoration: 'none',
  },
});

const Users: React.FC = () => {
  // hooks
  const pageTopRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermissions, profile } = useContext(AuthContext) as AuthContextValue;
  const [allowSearch, setAllowSearch] = useState(true);
  const [userList, setUserList] = useState<Array<UserListInterface>>([]);
  const [selectedUser, setSelectedUser] = useState<UserListInterface>();
  const [infoModalState, setInfoModalState] = useState(false);
  const [banUserModalState, setBanUserModalState] = useState(false);
  const [contractModalState, setContractModalState] = useState(false);
  const [setPasswordModalState, setSetPasswordModalState] = useState(false);
  const [sendResetPasswordEmailModalState, setSendResetPasswordEmailModalState] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(true);
  const { notifyInfo, notifyError, notifySuccess } = useNotify();
  const [usersCount, setUsersCount] = useState<number>(0);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingResidenceClusters, setLoadingResidenceClusters] = useState(true);
  const [loadingResidenceGroups, setLoadingResidenceGroups] = useState(true);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [roles, setRoles] = useState<Array<OptionsInterface>>([]);
  const [residenceClusters, setResidenceClusters] = useState<Array<OptionsInterface>>([]);
  const [residenceGroups, setResidenceGroups] = useState<Array<OptionsInterface>>([]);
  const [regions, setRegions] = useState<Array<OptionsInterface>>([]);
  const [teams, setTeams] = useState<MinimalTeam[]>([]);

  // get cached filters and pagination from url if any
  // otherwise load default values
  const query = paramsFromUrl('query');
  const page = paramsFromUrl('page');
  const [pagination, setPagination] = useState(
    page === '' ? (paginationDefaults as ListPagination) : (page as ListPagination)
  );
  const [listParams, setGetListParams] = useState(
    query === '' ? (filterSortDefaults as ListParams) : (query as ListParams)
  );

  const [searchParams] = useSearchParams();
  const from = useMemo(() => {
    const from = searchParams.get('from');
    if (!from) return null;
    return decode(from);
  }, [searchParams]);

  function handleHistoryChange(event: PopStateEvent) {
    // Handle popstate event
    setPagination(paramsFromUrl('page'));
  }
  useGlobalPopstateListener(handleHistoryChange);

  const mobileNumberFormat = (mobile: string): string => {
    switch (mobile.split(' ').join('').length) {
      case 8:
        return '### - ### ##';
      case 9:
        return '### - ## ## ##';
      case 10:
        return '### - ### ## ##';
      default:
        return '### - ### ## ## ## ## ## ## ##';
    }
  };

  const formattedMobileNumber = (mobile: string): JSX.Element | string => {
    if (mobile.substring(0, 3) === '+46') {
      const formattedMobile = ('0' + mobile.substring(3, mobile.length)).split(' ').join('');
      return <NumberFormat value={formattedMobile} displayType={'text'} format={mobileNumberFormat(formattedMobile)} />;
    } else {
      return mobile;
    }
  };

  const deviceType = (): string => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  const copyMobile = (mobile: string): void => {
    navigator.clipboard.writeText(mobile.split(' ').join(''));
    notifyInfo('Mobilnummer har kopierats', {
      variant: 'default',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  };

  const mobileLink = (mobile: string): JSX.Element => {
    if (deviceType() === 'mobile') {
      return (
        <a
          href={'tel:' + mobile.split(' ').join('')}
          className={css(styles.mobile)}
          onClick={(e): void => {
            e.stopPropagation();
            copyMobile(mobile);
          }}
        >
          {formattedMobileNumber(mobile)}
        </a>
      );
    } else {
      return (
        <a
          className={css(styles.mobile)}
          onClick={(e): void => {
            e.stopPropagation();
            copyMobile(mobile);
          }}
        >
          {formattedMobileNumber(mobile)}
        </a>
      );
    }
  };

  const rows: CustomTableHeadColumn[] = [
    { id: 'name', label: 'Namn' },
    { id: 'employment_nr', label: 'Anställningsnr' },
    { id: 'team_name', label: 'Team', minWidth: '10rem' },
    { id: 'mobile', label: 'Mobil', notSortable: true, minWidth: '8rem' },
    { id: 'email', label: 'E-post', notSortable: true },
    { id: 'last_login_at', label: 'Senast aktiv' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  // This useEffect takes place on first page load
  useEffect(() => {
    setPagination(pagination);
    fetchRoles();
    fetchResidenceClusters();
    fetchResidenceGroups();
    fetchRegions();
    fetchTeams();
    pageTopRef?.current?.scrollIntoView();
  }, []);

  // This useEffect takes place whenever listParams or allowSearch or pagination changes
  useEffect(() => {
    const query = encode(JSON.stringify(listParams));
    const page = encode(JSON.stringify(pagination));
    const fromParam = from ? `&from=${encode(from)}` : '';
    navigate(`/users?query=${query}&page=${page}${fromParam}${location.hash}`, { replace: true });

    if (allowSearch) {
      fetchUsers();
    }
  }, [
    // We don't want to listen to changes on listParams.hide_inactive_teams, since this is
    // a special case which is handled in the next useEffect
    listParams.query,
    listParams.sort_by,
    listParams.sort_order,
    listParams.filter_roles,
    listParams.filter_residence_clusters,
    listParams.filter_residence_groups,
    listParams.filter_regions,
    listParams.filter_teams,
    listParams.hide_employed,
    listParams.hide_banned,
    listParams.banned_at_start,
    listParams.sort_by,
    listParams.banned_at_end,
    listParams.from_birthdate,
    listParams.to_birthdate,
    allowSearch,
    pagination,
    from,
  ]); // eslint-disable-line

  // Clear inactive teams from the filter when the 'Show inactive teams' toggle is off
  useEffect(() => {
    if (listParams.hide_inactive_teams && !loadingTeams) {
      setGetListParams({
        ...listParams,
        ['filter_teams']: teams
          .filter((t) => t.is_active === true && listParams.filter_teams?.includes(t.id))
          .map((t) => t.id),
      });
    }
  }, [listParams.hide_inactive_teams, loadingTeams]); // eslint-disable-line

  const fetchUsers = (): void => {
    setLoadingUsers(true);
    getUsers({ ...listParams, ...pagination })
      .then(({ data }) => {
        setUserList(data.data);
        setUsersCount(data.meta.count);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över användare'))
      .finally(() => {
        const element = document.getElementById(location.hash.substring(1)); // need to drop '#' from the beginning of the userId
        if (element) {
          element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
        }
        setLoadingUsers(false);
      });
  };

  const fetchRoles = (): void => {
    setLoadingRoles(true);
    getRolesMinimalList({ hide_banned: true })
      .then(({ data }) => setRoles(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över roller'))
      .finally(() => setLoadingRoles(false));
  };

  const fetchResidenceClusters = (): void => {
    setLoadingResidenceClusters(true);
    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClusters(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'))
      .finally(() => setLoadingResidenceClusters(false));
  };

  const fetchResidenceGroups = (): void => {
    setLoadingResidenceGroups(true);
    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroups(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalsparten'))
      .finally(() => setLoadingResidenceGroups(false));
  };

  const fetchRegions = (): void => {
    setLoadingRegions(true);
    getRegionsMinimalList()
      .then(({ data }) => setRegions(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'))
      .finally(() => setLoadingRegions(false));
  };

  const fetchTeams = (): void => {
    setLoadingTeams(true);

    getTeamMinimalList({})
      .then(({ data }) => setTeams(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'))
      .finally(() => setLoadingTeams(false));
  };

  const handleToggleBanned = (id: string, banned: boolean): void => {
    setLoading(true);
    toggleBanned(id, banned, false)
      .then(() => {
        if (banned) {
          notifySuccess('Användaren avslutad!');
        } else {
          notifySuccess('Användaren återställt!');
        }
      })
      .then(() => {
        fetchUsers();
      })
      .catch(() => notifyError('Det gick inte att uppdatera användaren.'))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleToggleTaxEqualization = (id: string, taxEqualization: boolean): void => {
    setLoading(true);
    updateUser(id, { tax_equalization: taxEqualization })
      .then(() => {
        if (taxEqualization) {
          notifySuccess('Användaren är satt för jämkning.');
        } else {
          notifySuccess('Användaren är satt för att inte jämka.');
        }
      })
      .then(() => {
        fetchUsers();
      })
      .catch(() => notifyError('Det gick inte att uppdatera användaren.'))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendWelcomeEmail = (id: string): void => {
    setLoading(true);
    sendWelcomeEmail(id)
      .then(() => {
        notifySuccess('Välkomstmail har skickat');
      })
      .catch(() => notifyError('Det gick inte att skicka välkomstmail.'))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div ref={pageTopRef} className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <div className="flex items-center">
              {from && <BackLink link={from} goBack />}
              <h1 className={css(commonStyles.headerTextStyle)}>Användare</h1>
            </div>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>{usersCount} Användare</p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <SearchBar
              setGetListParams={setGetListParams}
              listParams={listParams}
              setAllowSearch={setAllowSearch}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            {hasPermissions(['admin', 'operational_leader', 'operational_manager', 'regional_manager']) && (
              <Button
                color="primary"
                sx={{
                  width: '180px',
                }}
                variant="outlined"
                onClick={(): void => {
                  setInfoModalState(true);
                }}
              >
                HÄMTA INFO
              </Button>
            )}
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={3} sm={3} md={3} lg={3}>
            <FilterAutocomplete
              listParams={listParams}
              setGetListParams={setGetListParams}
              fieldName="filter_roles"
              label="Välj användartyp"
              values={roles.filter((r) => listParams.filter_roles?.includes(r.id))}
              options={roles}
              multiple={true}
              limitTags={1}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={1} sm={1} md={1} lg={1}>
            <FilterPopover
              setGetListParams={setGetListParams}
              listParams={listParams}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
        </Grid>
        <Divider />
        <div className={css(commonStyles.filtersBarWrapper)}>
          <Grid container>
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={3} sm={3} md={3} lg={3}>
              <FilterAutocomplete
                listParams={listParams}
                setGetListParams={setGetListParams}
                fieldName="filter_residence_clusters"
                values={residenceClusters.filter((r) => listParams.filter_residence_clusters?.includes(r.id))}
                label="Välj grupp"
                options={residenceClusters}
                multiple={true}
                limitTags={1}
                setPagination={setPagination}
                paginationDefaults={paginationDefaults}
              />
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={3} sm={3} md={3} lg={3}>
              <FilterAutocomplete
                listParams={listParams}
                setGetListParams={setGetListParams}
                fieldName="filter_residence_groups"
                values={residenceGroups.filter((r) => listParams.filter_residence_groups?.includes(r.id))}
                label="Välj avtalspart"
                options={residenceGroups}
                multiple={true}
                limitTags={1}
                setPagination={setPagination}
                paginationDefaults={paginationDefaults}
              />
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={3} sm={3} md={3} lg={3}>
              <FilterAutocomplete
                listParams={listParams}
                setGetListParams={setGetListParams}
                fieldName="filter_regions"
                values={regions.filter((r) => listParams.filter_regions?.includes(r.id))}
                label="Välj region"
                options={regions}
                multiple={true}
                limitTags={1}
                setPagination={setPagination}
                paginationDefaults={paginationDefaults}
              />
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={3} sm={3} md={3} lg={3}>
              <FilterAutocomplete
                listParams={listParams}
                setGetListParams={setGetListParams}
                fieldName="filter_teams"
                label="Välj team"
                options={listParams.hide_inactive_teams ? teams.filter((t) => t.is_active === true) : teams}
                groupBy={(option) => (option.is_active ? 'Aktiva team' : 'Inaktiva team')}
                values={teams.filter((t) => listParams.filter_teams?.includes(t.id))}
                multiple={true}
                limitTags={1}
                setPagination={setPagination}
                paginationDefaults={paginationDefaults}
              />
            </Grid>
          </Grid>
        </div>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />
          <TableBody>
            {userList.length === 0 && <EmptyList />}
            {userList.length > 0 &&
              userList.map((row, idx) => (
                <TableRow
                  customRoot={
                    row.banned ? css(commonStyles.archivedRow) : idx % 2 !== 0 ? css(commonStyles.greyRow) : ''
                  }
                  row={row}
                  idx={idx}
                  id={row.id}
                  key={row.id}
                  onClick={(): void => {
                    const query = encode(JSON.stringify(listParams));
                    const page = encode(JSON.stringify(pagination));
                    navigate(`/users?query=${query}&page=${page}#${row.id}`, { replace: true });
                    navigate(`/users/${row.id}`);
                  }}
                >
                  <TableCell scope="row">
                    {row.confirmed ? <b>{row.name}</b> : <b style={{ color: appTheme.redMedium }}>{row.name}</b>}
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>{row.employment_nr}</TableCell>
                  <TableCell className={css(commonStyles.tableCellLarge)}>{row.team_name}</TableCell>
                  <TableCell className={css(commonStyles.tableCellLarge)}>
                    {row.mobile && mobileLink(row.mobile)}
                  </TableCell>
                  <TableCell className={css(styles.email, commonStyles.tableCellLarger)}>
                    {row.email && (
                      <div>
                        <EmailPopover email={row.email}></EmailPopover>
                      </div>
                    )}
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellLarge)}>{row.last_login_at}</TableCell>
                  <MenuTableCell>
                    {hasPermissions(['admin', 'operational_leader', 'operational_manager', 'regional_manager']) && (
                      <MenuItem>
                        <QuickAction
                          onClick={(): void => {
                            navigate(`/teams/${row.team_id}`);
                          }}
                          text="Visa team"
                          icon={<GroupIcon style={{ marginRight: '10px' }} />}
                          label="show_team"
                          styles={commonStyles.selectionFabStyle3}
                        />
                      </MenuItem>
                    )}
                    {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
                      <MenuItem>
                        <QuickAction
                          text={row.tax_equalization ? 'Stäng av Jämkning' : 'Sätt på Jämkning'}
                          icon={<AttachMoneyIcon style={{ marginRight: '10px' }} />}
                          label="start_tax_equalization"
                          styles={commonStyles.selectionFabStyle3}
                          onClick={(): void => {
                            handleToggleTaxEqualization(row.id, !row.tax_equalization);
                          }}
                        />
                      </MenuItem>
                    )}
                    {row.banned ? (
                      <MenuItem>
                        <QuickAction
                          onClick={(): void => {
                            handleToggleBanned(row.id, false);
                          }}
                          text="Återanställ användare"
                          icon={<CheckIcon style={{ marginRight: '10px' }} />}
                          label="unban_user"
                          styles={commonStyles.selectionFabStyle3}
                        />
                      </MenuItem>
                    ) : (
                      <MenuItem>
                        <QuickAction
                          onClick={(): void => {
                            setSelectedUser(row);
                            setBanUserModalState(true);
                          }}
                          text="Avsluta användare"
                          icon={<ClearIcon style={{ marginRight: '10px' }} />}
                          label="ban_user"
                          styles={commonStyles.selectionFabStyle3}
                        />
                      </MenuItem>
                    )}
                    <MenuItem>
                      <QuickAction
                        onClick={(): void => {
                          handleSendWelcomeEmail(row.id);
                        }}
                        text="Skicka välkomstmail"
                        icon={<FlightTakeoffIcon style={{ marginRight: '10px' }} />}
                        label="send_welcome_email"
                        styles={commonStyles.selectionFabStyle3}
                      />
                    </MenuItem>
                    {hasPermissions(['admin', 'operational_leader', 'operational_manager', 'regional_manager']) && (
                      <MenuItem>
                        <QuickAction
                          onClick={(): void => {
                            setSelectedUser(row);
                            setSendResetPasswordEmailModalState(true);
                          }}
                          text="Skicka nytt lösenord"
                          icon={<FingerprintIcon style={{ marginRight: '10px' }} />}
                          label="send_reset_password_email"
                          styles={commonStyles.selectionFabStyle3}
                        />
                      </MenuItem>
                    )}
                    {hasPermissions(['admin']) && (
                      <MenuItem>
                        <QuickAction
                          onClick={(): void => {
                            setSelectedUser(row);
                            setSetPasswordModalState(true);
                          }}
                          text="Sätt nytt lösenord"
                          icon={<KeyOutlinedIcon style={{ marginRight: '10px' }} />}
                          label="reset_password"
                          styles={commonStyles.selectionFabStyle3}
                        />
                      </MenuItem>
                    )}
                    {(hasPermissions(['admin', 'operational_leader', 'operational_manager']) ||
                      (hasPermissions(['regional_manager']) && row.id === profile?.id)) && (
                      <MenuItem>
                        <QuickAction
                          onClick={(): void => {
                            setSelectedUser(row);
                            setContractModalState(true);
                          }}
                          text="Se kontrakt"
                          icon={<HowToVoteIcon style={{ marginRight: '10px' }} />}
                          label="show_contract"
                          styles={commonStyles.selectionFabStyle3}
                        />
                      </MenuItem>
                    )}
                  </MenuTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Pagination
          pageTopRef={pageTopRef}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={usersCount}
        />

        <Dialog open={modalState} onClose={(): void => setModalState(false)} fullWidth={true} maxWidth="sm">
          <NewUserModal setModalState={setModalState} callback={fetchUsers} />
        </Dialog>

        {infoModalState && (
          <Dialog
            open={infoModalState}
            onClose={(): void => {
              setInfoModalState(false);
            }}
            fullWidth={true}
            maxWidth="sm"
          >
            <InfoModal setModalState={setInfoModalState} params={listParams} />
          </Dialog>
        )}

        {banUserModalState && (
          <Dialog
            open={banUserModalState}
            onClose={(): void => {
              setBanUserModalState(false);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <BanUserModal user={selectedUser} setModalState={setBanUserModalState} fetchUsers={fetchUsers} />
          </Dialog>
        )}

        {contractModalState && (
          <Dialog
            open={contractModalState}
            onClose={(): void => {
              setContractModalState(false);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <ContractModal user={selectedUser} setModalState={setContractModalState} />
          </Dialog>
        )}

        {sendResetPasswordEmailModalState && (
          <Dialog
            open={sendResetPasswordEmailModalState}
            onClose={(): void => {
              setSendResetPasswordEmailModalState(false);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <SendResetPasswordEmailModal user={selectedUser} setModalState={setSendResetPasswordEmailModalState} />
          </Dialog>
        )}

        {setPasswordModalState && (
          <Dialog
            open={setPasswordModalState}
            onClose={(): void => {
              setSetPasswordModalState(false);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <SetPasswordModal user={selectedUser} setModalState={setSetPasswordModalState} />
          </Dialog>
        )}

        <CreateFab link={`/users/create`} />
        {(loadingUsers ||
          loadingRoles ||
          loadingResidenceClusters ||
          loadingResidenceGroups ||
          loadingRegions ||
          loadingTeams) && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader
              loading={
                loadingUsers ||
                loadingRoles ||
                loadingResidenceClusters ||
                loadingResidenceGroups ||
                loadingRegions ||
                loadingTeams
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Users;
