import React, { useEffect } from 'react';

import { Button, Divider, FormControl, Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FormSelect } from '../../Common/FormItems';
import FormDateField from '../../Common/FormItems/FormDateField';
import FormTextField from '../../Common/FormItems/FormTextField';
import FormToggle from '../../Common/FormItems/FormToggle';
import { AddPricePeriods } from './AddPricePeriods';
import { CorrectionsOnEditAgreement } from './EditAgreementCorrection';
import { TeamOnEditAgreement } from './EditAgreementTeamForm';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  openCorrectionModal: any;
  handleDelete: any;
  submitDisabled: boolean;
};

type DateKey = 'start_date' | 'end_date';

export const EditAgreementForm: React.FC<Props> = (props) => {
  const invoicing_routines = [
    { id: '-1', name: 'En månad före' },
    { id: '0', name: 'Samma månad' },
    { id: '1', name: 'En månad efter' },
  ];

  const agreementTypes = [
    { id: 'ContinuousAgreement', name: 'Löpande' },
    { id: 'FixedAgreement', name: 'Anpassad' },
  ];

  const isValidDate = (date: Date): boolean => {
    const dateTime = date.getTime(); // getTime() returns NaN if the date is invalid
    return typeof dateTime === 'number' && !isNaN(dateTime);
  };

  const getValidDates = (dates: Date[], dateKey: DateKey): Dayjs[] => {
    return dates
      .filter((teamAgreement: any) => teamAgreement.team.is_active === true)
      .map((team: any) => dayjs(team[dateKey]))
      .filter((date) => date.isValid());
  };

  useEffect(
    () => {
      const minDate = dayjs.min(getValidDates(props.values.team_agreements, 'start_date'));
      if (minDate?.isValid()) {
        const minPricePeriodDate = dayjs.min(
          props.values.price_periods.map((pricePeriod: any) => dayjs(pricePeriod.start_date))
        );

        const minPricePeriodIndex = props.values.price_periods.findIndex((pricePeriod: any) =>
          minPricePeriodDate?.isSame(pricePeriod.start_date)
        );
        props.setFieldValue(
          `price_periods[${minPricePeriodIndex}].start_date`,
          minDate.format('YYYY-MM-DD')
          // formatISO(minDate, { representation: 'date' })
        );
        props.setFieldValue('start_date', minDate.format('YYYY-MM-DD'));
      }
    },
    props.values.team_agreements.map((team: any) => team.start_date) // eslint-disable-line
  );

  useEffect(
    () => {
      const maxDate = dayjs.max(getValidDates(props.values.team_agreements, 'end_date'));
      if (maxDate?.isValid()) {
        const maxPricePeriodDate = dayjs.max(
          props.values.price_periods.map((pricePeriod: any) => dayjs(pricePeriod.end_date))
        );
        const maxPricePeriodIndex = props.values.price_periods.findIndex((pricePeriod: any) =>
          maxPricePeriodDate?.isSame(pricePeriod.end_date)
        );
        props.setFieldValue(`price_periods[${maxPricePeriodIndex}].end_date`, maxDate.format('YYYY-MM-DD'));
        props.setFieldValue('end_date', maxDate.format('YYYY-MM-DD'));
      }
    },
    props.values.team_agreements.map((team: any) => team.end_date) // eslint-disable-line
  );

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }} alignItems="center">
        <FormSelect
          size={3}
          fieldName="type"
          label="Faktureringsrutin"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          options={agreementTypes}
          disabled={true}
        />

        <FormSelect
          size={3}
          fieldName="invoicing_routine"
          label="Faktureringstid"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          options={invoicing_routines}
          disabled={true}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }} alignItems="center">
        <FormDateField
          size={3}
          fieldName="start_date"
          label="Från"
          placeholder="Från"
          values={props.values}
          errors={props.errors}
          onChange={props.setFieldValue}
          disabled={true}
        />
        <FormDateField
          size={3}
          fieldName="end_date"
          label="Till"
          placeholder="Till"
          values={props.values}
          errors={props.errors}
          onChange={props.setFieldValue}
          disabled={true}
        />
        <FormTextField
          size={2}
          disabled={true}
          fieldName="budgeted_hours"
          label="Timpott (tim)"
          placeholder="Timpott (tim)"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={2}
          fieldName="startup_fee"
          label="Uppstart (kr)"
          placeholder="Uppstart (kr)"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>

      <AddPricePeriods
        values={props.values}
        handleChange={props.handleChange}
        errors={props.errors}
        isEdit={true}
        setFieldValue={props.setFieldValue}
      />

      <TeamOnEditAgreement
        values={props.values}
        handleChange={props.handleChange}
        errors={props.errors}
        setFieldValue={props.setFieldValue}
      />

      <Divider className="!mb-5" />

      <CorrectionsOnEditAgreement values={props.values} openCorrectionModal={props.openCorrectionModal} />

      <Divider className="!mb-5" />

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormToggle
          size={3}
          fieldName="is_active"
          label="Avtalet är aktivt"
          values={props.values}
          onChange={props.handleChange}
        />
        <Grid item xs={2} />
        {props.values.editable && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button type="submit" variant="contained" color="primary" disabled={props.submitDisabled}>
                Spara
              </Button>
            </FormControl>
          </Grid>
        )}
        {props.values.deletable && (
          <>
            <Grid item xs={3} />
            <Grid item xs={2}>
              <FormControl variant="standard" margin="dense" required fullWidth>
                <Button onClick={props.handleDelete} variant="outlined" color="error">
                  Ta Bort
                </Button>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
