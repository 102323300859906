import React, { useEffect, useState } from 'react';

import { FormControl, FormHelperText, Grid, IconButton, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import {
  BaseSingleInputFieldProps,
  CalendarIcon,
  ClearIcon,
  DatePicker,
  DateValidationError,
  FieldSection,
  UseDateFieldProps,
} from '@mui/x-date-pickers';

const DAYJS_FORMATS = ['YYYYMMDD', 'YYYY-MM-DD'];

interface InputFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  onChange: (date: Dayjs | null) => void;
  onOpen: () => void;
  error?: boolean;
  onlyValid?: boolean;
}

function InputField({
  label,
  id,
  disabled,
  value,
  onChange,
  onOpen,
  InputProps: { ref } = {},
  inputProps: { 'aria-label': ariaLabel } = {},
  onlyValid = true,
  error,
  className,
}: InputFieldProps) {
  const [input, setInput] = useState(value?.isValid() ? value.format('YYYY-MM-DD') : '');

  useEffect(() => {
    if (!value) return setInput('');
    const curr = dayjs(input, DAYJS_FORMATS);
    if (value.isValid() && !value.isSame(curr, 'day')) setInput(value.format('YYYY-MM-DD') ?? '');
  }, [value]);

  return (
    <TextField
      ref={ref}
      id={id}
      aria-label={ariaLabel}
      label={label}
      placeholder="YYYY-MM-DD"
      value={input}
      color={error ? 'error' : undefined}
      onChange={({ target }) => {
        setInput(target.value);
        if (!target.value) return onChange(null);
        const day = dayjs(target.value, DAYJS_FORMATS);
        if (!onlyValid || day.isValid()) onChange(day);
      }}
      onBlur={() => {
        const day = dayjs(input, DAYJS_FORMATS);
        setInput(day?.isValid() ? day.format('YYYY-MM-DD') : onlyValid ? '' : input);
      }}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <IconButton onClick={onOpen}>
            <CalendarIcon />
          </IconButton>
        ),
        endAdornment: !disabled && (
          <IconButton onClick={() => onChange(null)}>
            <ClearIcon />
          </IconButton>
        ),
      }}
      className={className}
    />
  );
}

type Props = {
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  label: string;
  placeholder: string;
  error?: string | null;
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  onlyValid?: boolean;
  disabled?: boolean;
  required?: boolean;
};

const FormFreeDateField: React.FC<Props> = ({
  size,
  label,
  error,
  value,
  onChange,
  minDate,
  maxDate,
  onlyValid,
  required,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Grid item xs={size}>
      <FormControl variant="standard" margin="normal" required={required} fullWidth>
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slots={{ field: InputField as any }}
          slotProps={{ field: { onChange, onOpen: () => setOpen(true), onlyValid, error: !!error } as any }}
          onChange={onChange}
          label={label}
          views={['year', 'month', 'day']}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
        {error !== undefined && <FormHelperText error>{error || ' '}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default FormFreeDateField;
