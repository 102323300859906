import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { paginationDefaults } from '../commonSchema';

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Chip,
  FormControl,
  TextField,
} from '@mui/material';

import { createFilterOptions } from '@mui/material/Autocomplete';

import { ListParams, OptionsInterface } from '../../Common/types';

type Props = {
  setGetListParams: (p: ListParams) => void;
  listParams: ListParams;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
  fieldName: string;
  label: string;
  options: Array<OptionsInterface>;
  multiple?: boolean;
  limitTags?: number;
  componentProps?: object;
  values?: Array<OptionsInterface> | OptionsInterface | null;
  groupBy?: ((option: OptionsInterface) => string) | undefined;
  width?: number;
  disableClearable?: boolean;
};

const FilterAutocomplete: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(1450));
  const chipConfig = isMatch ? { maxWidth: 110 } : { maxWidth: 170 };

  // functions
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: OptionsInterface | OptionsInterface[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionsInterface> | undefined
  ): void => {
    if (props.setPagination) {
      props.setPagination({
        page: 1,
        page_size: props.paginationDefaults ? props.paginationDefaults.page_size : paginationDefaults.page_size,
      });
    }
    if (!value) {
      props.setGetListParams({ ...props.listParams, [props.fieldName]: '' });
    } else {
      // if it's a multiple choice autocomplete, set the field value as an array of ids
      if (Array.isArray(value)) {
        props.setGetListParams({
          ...props.listParams,
          [props.fieldName]: value.map((v: OptionsInterface) => v?.id),
        });
        // if it's a single choise autocomplete, set the field value as an id
      } else {
        props.setGetListParams({
          ...props.listParams,
          [props.fieldName]: value?.id,
        });
      }
    }
  };

  // render
  return (
    <FormControl sx={{ width: props.width ? props.width : '200px', marginRight: '5px' }}>
      <Autocomplete
        componentsProps={props.componentProps}
        disableCloseOnSelect={props.multiple ? true : false}
        disableClearable={props.disableClearable}
        multiple={props.multiple}
        limitTags={props.limitTags}
        options={props.options}
        filterOptions={createFilterOptions({ ignoreAccents: false })}
        groupBy={props.groupBy}
        getOptionLabel={(option: OptionsInterface): string => (option.name ? option.name : '')}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip label={option.name} style={chipConfig} {...getTagProps({ index })} />
          ))
        }
        value={props.values}
        isOptionEqualToValue={(option: OptionsInterface, value: number | string | OptionsInterface): boolean => {
          // when no value is chosen, it is an empty string or undefined
          if (value === undefined) {
            return true;
          }
          if (typeof value === 'string') {
            if (value === '') {
              return true;
            }
            // when the form value is prefilled, it is a number
          } else if (typeof value === 'number') {
            if (value === option.id) {
              return true;
            }
            // otherwise it is a chosen option from the autocomplete with format {id: ..., name: ...}
          } else if (option.id === value.id) {
            return true;
          }
          return false;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name ? option.name : ''}
          </li>
        )}
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{ ...params.InputProps, disableUnderline: true, placeholder: props.label }}
          />
        )}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default FilterAutocomplete;
