import * as Yup from 'yup';

import { sort_order } from '../Common/types';

export const initialValues = {
  residence_group_id: undefined,
  type: 'ContinuousAgreement',
  start_date: new Date().toISOString().split('T')[0],
  end_date: new Date().toISOString().split('T')[0],
  startup_fee: 0,
  is_active: true,
  residences: [],
  invoicing_routine: '1',
  price_periods: [
    {
      start_date: new Date().toISOString().split('T')[0],
      end_date: new Date().toISOString().split('T')[0],
    },
  ],
};
export const initialEditValues = {
  residence_group_id: undefined,
  type: 'ContinuousAgreement',
  invoicing_routine: '1',
  start_date: new Date().toISOString().split('T')[0],
  end_date: new Date().toISOString().split('T')[0],
  hourly_rate: 1,
  budgeted_hours: 1,
  startup_fee: 0,
  is_active: true,
  price_periods: [
    {
      start_date: new Date().toISOString().split('T')[0],
      end_date: new Date().toISOString().split('T')[0],
      hourly_rate: 1,
    },
  ],
};

export const validationSchema = Yup.object().shape({
  residence_group_id: Yup.mixed().required('* Välj en avtalspart'),
  type: Yup.string(),
  start_date: Yup.date()
    .when('end_date', {
      is: (v) => v != null,
      then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
    })
    .when('type', {
      is: (type: string) => type === 'FixedAgreement',
      then: Yup.date().test({
        name: 'start-of-month',
        test: (value) => new Date(value.getTime() + 86400000).getDate() === 2,
        message: '* Måste vara den första i månaden',
      }),
    })
    .required('* Startdatum är ett obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
  end_date: Yup.date()
    .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
    .when('type', {
      is: (type: string) => type === 'FixedAgreement',
      then: Yup.date().test({
        name: 'start-of-month',
        test: (value) => new Date(value.getTime() + 86400000).getDate() === 1,
        message: '* Måste vara den sista i månaden',
      }),
    })
    .required('* Slutdatum är ett obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
  budgeted_hours: Yup.number()
    .typeError('* Måste vara ett nummer')
    .min(1, '* Får ej vara lägre än 1')
    .required('* Timpott är ett obligatoriskt fält'),
  startup_fee: Yup.number().typeError('* Måste vara ett nummer').min(0, '* Får ej vara lägre än 1').required(),
  price_periods: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date()
        .when('end_date', {
          is: (v) => v != null,
          then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
        })
        .test('start-of-month', '* Måste vara den första i månaden', function (value) {
          if (!value) return false;
          // @ts-ignore
          if (this.options.context && this.options.context['type'] === 'FixedAgreement')
            return new Date(value.getTime() + 86400000).getDate() === 2;
          else return true;
        })
        .test(
          'fill-agreement-start-date',
          '* Prisperioderna måste täcka hela avtalet och får inte överlappa',
          function (value) {
            if (!value) return false;
            // @ts-ignore
            const array = this.options.context['price_periods'];
            const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
            if (index === 0) {
              // @ts-ignore
              const date = new Date(this.options.context['start_date']);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
              if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
            } else {
              const date = new Date(array[index - 1].end_date);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate() - 1);
              if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
            }
            return true;
          }
        )
        .required('* Startdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      end_date: Yup.date()
        .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
        .test('end-of-month', '* Måste vara den sista i månaden', function (value) {
          if (!value) return false;
          // @ts-ignore
          if (this.options.context && this.options.context['type'] === 'FixedAgreement')
            return new Date(value.getTime() + 86400000).getDate() === 1;
          else return true;
        })
        .test(
          'fill-agreement-end-date',
          '* Prisperioderna måste täcka hela avtalet och får inte överlappa',
          function (value) {
            if (!value) return false;
            // @ts-ignore
            const array = this.options.context['price_periods'];
            const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
            if (index === array.length - 1) {
              // @ts-ignore
              const date = new Date(this.options.context['end_date']);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
              if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
            }
            return true;
          }
        )
        .required('* Slutdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      hourly_rate: Yup.number()
        .typeError('* Måste vara ett nummer')
        .min(0, '* Får ej vara lägre än 0')
        .required('* Timpris är ett obligatoriskt fält'),
    })
  ),
  residences: Yup.array().of(
    Yup.object().shape({
      teams: Yup.array().of(
        Yup.object().shape({
          start_date: Yup.date()
            .when('end_date', {
              is: (v) => v != null,
              then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
            })
            .test('test-date', '* Datumet måste vara inom avtalets gränser', function (value) {
              if (!value) return false;
              // @ts-ignore
              let date = new Date(this.options.context['start_date']);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              // @ts-ignore
              date = new Date(this.options.context['end_date']);
              const dateOnly3 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
              return dateOnly1.valueOf() <= dateOnly2.valueOf() && dateOnly3.valueOf() >= dateOnly2.valueOf();
            })
            .required('* Startdatum är ett obligatoriskt fält')
            .typeError('* Ogiltigt datum'),
          end_date: Yup.date()
            .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
            .test('test-date', '* Datumet måste vara inom avtalets gränser', function (value) {
              if (!value) return false;
              // @ts-ignore
              let date = new Date(this.options.context['start_date']);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              // @ts-ignore
              date = new Date(this.options.context['end_date']);
              const dateOnly3 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
              return dateOnly1.valueOf() <= dateOnly2.valueOf() && dateOnly3.valueOf() >= dateOnly2.valueOf();
            })
            .required('* Slutdatum är ett obligatoriskt fält')
            .typeError('* Ogiltigt datum'),
          budgeted_hours: Yup.number()
            .typeError('* Måste vara ett nummer')
            .min(1, '* Får ej vara lägre än 1')
            .required('* Timpott är ett obligatoriskt fält'),
          startup_fee: Yup.number().typeError('* Måste vara ett nummer').min(0, '* Får ej vara lägre än 1').required(),
        })
      ),
      budgeted_hours: Yup.number()
        .typeError('* Måste vara ett nummer')
        .min(1, '* Får ej vara lägre än 1')
        .required('* Timpott är ett obligatoriskt fält'),
      startup_fee: Yup.number().typeError('* Måste vara ett nummer').min(0, '* Får ej vara lägre än 1').required(),
    })
  ),
});

export const validationEditSchema = Yup.object().shape({
  start_date: Yup.date()
    .when('end_date', {
      is: (v) => v != null,
      then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
    })
    .required('* Startdatum är ett obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
  end_date: Yup.date()
    .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
    .required('* Slutdatum är ett obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
  budgeted_hours: Yup.number().typeError('* Måste vara ett nummer').min(1, '* Får ej vara lägre än 1').required(),
  startup_fee: Yup.number().typeError('* Måste vara ett nummer').min(0, '* Får ej vara lägre än 1').required(),
  price_periods: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date()
        .when('end_date', {
          is: (v) => v != null,
          then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
        })
        .test('start-of-month', '* Måste vara den första i månaden', function (value) {
          if (!value) return false;
          // @ts-ignore
          if (this.options.context && this.options.context['type'] === 'FixedAgreement')
            return new Date(value.getTime() + 86400000).getDate() === 2;
          else return true;
        })
        .test(
          'fill-agreement-start-date',
          '* Prisperioderna måste täcka hela avtalet och får inte överlappa',
          function (value) {
            if (!value) return false;
            // @ts-ignore
            const array = this.options.context['price_periods'];
            const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
            if (index === 0) {
              // @ts-ignore
              const date = new Date(this.options.context['start_date']);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
              if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
            } else {
              const date = new Date(array[index - 1].end_date);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate() - 1);
              if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
            }
            return true;
          }
        )
        .required('* Startdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      end_date: Yup.date()
        .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
        .test('end-of-month', '* Måste vara den sista i månaden', function (value) {
          if (!value) return false;
          // @ts-ignore
          if (this.options.context && this.options.context['type'] === 'FixedAgreement')
            return new Date(value.getTime() + 86400000).getDate() === 1;
          else return true;
        })
        .test(
          'fill-agreement-end-date',
          '* Prisperioderna måste täcka hela avtalet och får inte överlappa',
          function (value) {
            if (!value) return false;
            // @ts-ignore
            const array = this.options.context['price_periods'];
            const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
            if (index === array.length - 1) {
              // @ts-ignore
              const date = new Date(this.options.context['end_date']);
              const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
              const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
              if (dateOnly1.valueOf() !== dateOnly2.valueOf()) return false;
            }
            return true;
          }
        )
        .required('* Slutdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      hourly_rate: Yup.number().typeError('* Måste vara ett nummer').min(1, '* Får ej vara lägre än 1').required(),
    })
  ),
  team_agreements: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date()
        .when('end_date', {
          is: (v) => v != null,
          then: (s: any) => s.max(Yup.ref('end_date'), '* Startdatumet måste vara innan slutdatumet'),
        })
        .test('start-of-month', '* Måste vara den första i månaden', function (value) {
          if (!value) return false;
          // @ts-ignore
          if (this.options.context && this.options.context['type'] === 'FixedAgreement')
            return new Date(value.getTime() + 86400000).getDate() === 2;
          else return true;
        })
        .test('test-date', '* Datumet måste vara inom avtalets gränser', function (value) {
          if (!value) return false;
          // @ts-ignore
          let date = new Date(this.options.context['start_date']);
          const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          // @ts-ignore
          date = new Date(this.options.context['end_date']);
          const dateOnly3 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
          return dateOnly1.valueOf() <= dateOnly2.valueOf() && dateOnly3.valueOf() >= dateOnly2.valueOf();
        })
        .required('* Startdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      end_date: Yup.date()
        .label('Error')
        .min(Yup.ref('start_date'), '* Slutdatumet måste vara efter startdatumet')
        .test('end-of-month', '* Måste vara den sista i månaden', function (value) {
          if (!value) return false;
          // @ts-ignore
          if (this.options.context && this.options.context['type'] === 'FixedAgreement')
            return new Date(value.getTime() + 86400000).getDate() === 1;
          else return true;
        })
        .test('test-date', '* Datumet måste vara inom avtalets gränser', function (value) {
          if (!value) return false;
          // @ts-ignore
          let date = new Date(this.options.context['start_date']);
          const dateOnly1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          // @ts-ignore
          date = new Date(this.options.context['end_date']);
          const dateOnly3 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          const dateOnly2 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
          return dateOnly1.valueOf() <= dateOnly2.valueOf() && dateOnly3.valueOf() >= dateOnly2.valueOf();
        })
        .required('* Slutdatum är ett obligatoriskt fält')
        .typeError('* Ogiltigt datum'),
      budgeted_hours: Yup.number()
        .typeError('* Måste vara ett nummer')
        .min(1, '* Får ej vara lägre än 1')
        .required('* Timpott är ett obligatoriskt fält'),
      startup_fee: Yup.number()
        .typeError('* Måste vara ett nummer')
        .min(0, '* Får ej vara lägre än 1')
        .required('* Får ej vara lägre än 1'),
    })
  ),
});

export const paginationDefaults = { page: 1, page_size: 20 };

export const filterSortDefaults = {
  query: '',
  sort_by: 'residence_name',
  sort_order: sort_order.asc,
  start_date: null,
  end_date: null,
  is_active: true,
};
