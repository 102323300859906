export interface ListParams {
  query?: string;
  sort_by?: string;
  sort_order?: sort_order;
  created_at_start?: string | null;
  created_at_end?: string | null;
  is_active?: boolean;
  is_invoiced?: boolean;
  page_size?: number;
  residence_id?: number[];
  page?: number;
  start_date?: string | null;
  end_date?: string | null;
  date_after?: string;
  processing?: boolean;
  date_before?: string;
  type?: string;
  hide_banned?: boolean;
  hide_employed?: boolean;
  banned_at_start?: string | null;
  banned_at_end?: string | null;
  hide_inactive_teams?: boolean;
  filter_languages?: number[] | undefined;
  filter_month_year?: string | null;
  filter_residence?: number | null;
  filter_region?: string | number;
  filter_roles?: (string | number)[];
  filter_residence_clusters?: (string | number)[];
  filter_residence_groups?: (string | number)[];
  filter_residence_group?: string | null;
  filter_regions?: (string | number)[];
  filter_teams?: (string | number)[];
  filter_team?: string | number;
  filter_user?: string;
  filter_operational_manager?: string | null;
  filter_residence_cluster?: number;
  show_reported?: boolean;
  show_not_reported?: boolean;
  show_scheduled?: boolean;
  show_not_locked?: boolean;
  from_month?: string;
  to_month?: string;
  from_birthdate?: string;
  to_birthdate?: string;
}

export interface ListPagination {
  page: number;
  page_size: number;
}

export enum sort_order {
  asc = 'asc',
  desc = 'desc',
}

export interface DateRangeInterface {
  start_date: string;
  end_date: string;
}

export type AuthProfile = {
  id: string;
  first_name: string;
  surname?: string;
  email: string;
  role: RoleOptionsInterface;
  mobile: string;
  team_id?: number;
};

export type AuthContextValue = {
  isAuth: boolean;
  profile: AuthProfile;
  loading: boolean;
  login: (credentials: { email: string; password: string }) => void;
  logout: () => void;
  hasPermissions: (roles: Array<string>) => boolean;
};

export type NotificationContextValue = {
  JobOpeningsNotification: number;
  refreshJobOpeningsNotification: () => void;
};

export interface OptionsInterface {
  id: number | string;
  name: string;
  created_at?: string;
  is_active?: boolean;
}

export interface UserOptionsInterface {
  id: string;
  name: string;
}

export interface RoleOptionsInterface {
  id: number;
  name: string;
  short_name: string;
  permission_level: number;
}

export interface PersonInterface {
  id?: number;
  name: string;
  email: string;
  phone_nr?: string;
  mobile?: string;
  person_type: 'guardian' | 'ambassador' | 'residence_manager';
  _destroy?: number;
}

export interface MinimalMonth {
  month: string;
  not_reported_hours: number;
  reported_hours: number;
  scheduled_hours: number;
}

export interface ScheduledUser {
  id: string;
  name: string;
  team: string;
  role: string;
  shifts: UserShift[] | null;
}

export interface UserShift {
  id: number;
  work_shift_id: number;
  hours: number;
  confirmed: boolean;
  salary_class: { id: number; name: string };
  work_shift_type: 'participant' | 'substitute' | 'temp_team_leader';
  user_id: string;
}

export interface DetailedMonth {
  participants: ScheduledUser[];
  substitutes: ScheduledUser[];
  work_shifts: {
    id: number;
    activity: string | null;
    date: string;
    deletable: boolean;
    editable: boolean;
    start_time: number;
    end_time: number;
    hours: number;
    locked_at: string | null;
    reported_at: string | null;
  }[];
}

export interface Payslip {
  url: string;
  name: string;
  date: string;
}
