import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers, yupToFormErrors } from 'formik';
import { RotateLoader } from 'react-spinners';

import MakeCorrectionsModal from '../Management/components/MakeCorrectionsModal';
import { getAgreement, updateAgreement, deleteAgreement } from './agreementApi';
import { initialEditValues, validationEditSchema } from './agreementSchema';
import { EditAgreementForm } from './components/EditAgreementForm';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { BackLink } from '../Common/ButtonLinks';
import { AgreementInterface } from './types';
import { Dialog } from '@mui/material';

const EditAgreement: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notifySuccess, notifyError, notifyInfo } = useNotify();
  const [agreementState, setAgreementState] = useState(initialEditValues as AgreementInterface);
  const [correctionState, setCorrectionState] = useState({} as any);
  const [correctionModalState, setCorrectionModalState] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAgreement(id)
      .then(({ data }) => setAgreementState(data.data))
      .catch(() => notifyError('Det gick inte att hämta avtalet'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (values: any, actions: FormikHelpers<any>): void => {
    if (values.team_agreements) {
      const agreement = { ...values, team_agreements: values.team_agreements.filter((team: any) => team.checked) };

      updateAgreement(id, agreement)
        .then(() => notifySuccess('Avtalet har uppdateras'))
        .then(() => navigate('/agreements'))
        .catch(() => {
          notifyError('Det gick inte att uppdatera avtalet');
          actions.setSubmitting(false);
        });
    }
  };

  const openCorrectionModal = (e: any): void => {
    e.stopPropagation();
    setCorrectionState({ team: undefined, agreement: agreementState });
    setCorrectionModalState(true);
  };

  const makeCorrectionCallback = (_: any): void => {
    getAgreement(id)
      .then(({ data }) => setAgreementState(data.data))
      .catch(() => notifyError('Det gick inte att skapa korrigeringen'));
  };

  const handleDeleteAgreement = (): void => {
    if (agreementState.deletable) {
      deleteAgreement(agreementState.id)
        .then(() => notifyInfo('Avtalet har tagits bort'))
        .then(() => navigate('/agreements'))
        .catch(() => notifyError('Det gick inte att ta bort avtalet'));
    }
  };

  // render
  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/agreements'} />
            {agreementState.residence && (
              <h1 className={css(commonStyles.headerTextStyle)}>Redigera avtal för {agreementState.residence.name}</h1>
            )}
          </div>
          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={agreementState}
              validate={(values): Promise<any> => {
                return validationEditSchema
                  .validate(values, {
                    abortEarly: false,
                    context: {
                      start_date: values.start_date,
                      end_date: values.end_date,
                      price_periods: values.price_periods,
                    },
                  })
                  .then(() => Promise.resolve())
                  .catch((err) => Promise.resolve(yupToFormErrors(err))); // In Formik 2.x, rejection will be interpreted as an actual exception and it won't update the form error state, therefore we return a resolved promise of errors instead
              }}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, isValid }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <EditAgreementForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    openCorrectionModal={openCorrectionModal}
                    handleDelete={handleDeleteAgreement}
                    submitDisabled={isSubmitting || !isValid}
                  />
                </form>
              )}
            </Formik>
          </div>
          <Dialog
            open={correctionModalState}
            onClose={(): void => setCorrectionModalState(false)}
            fullWidth={true}
            maxWidth="sm"
          >
            <MakeCorrectionsModal
              team={correctionState.team}
              agreement={correctionState.agreement}
              setModalState={setCorrectionModalState}
              correctionCallback={makeCorrectionCallback}
            />
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditAgreement;
