import React from 'react';

import { BaseFilterPopover, FilterCheckbox, FilterDateField } from '../../Common/FilterPopover';

type Props = {
  setGetListParams: any;
  listParams: any;
};

export const FilterPopover: React.FC<Props> = (props) => {
  return (
    <BaseFilterPopover>
      <FilterDateField
        fieldName="start_date"
        label="Startdatum"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
      />

      <FilterDateField
        fieldName="end_date"
        label="Slutdatum"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
      />
      <FilterCheckbox
        fieldName="is_active"
        label="Visa inaktiva"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
      />
    </BaseFilterPopover>
  );
};
