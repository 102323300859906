import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { Table, TableBody, TableCell, Dialog, Checkbox, Grid, Tooltip, MenuItem } from '@mui/material';
import { TableHead, TableRow, LoadMoreButton, MenuTableCell } from '../Common/Table';
import { paginationDefaults, filterSortDefaults } from './newsSchema';
import { ListParams, ListPagination, OptionsInterface, RoleOptionsInterface } from '../Common/types';
import { formatDate, isEmpty, isNotEmpty } from '../Common/utilities';
import { StyleSheet, commonStyles, css } from '../Common/styling';
import FilterPopover from './components/FilterPopover';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import { NewsInterface } from './types';
import theme from './../Common/styling/appTheme';
import { getNewsList, batchRestoreNews } from './newsApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import DeleteNewsModal from './components/DeleteNewsModal';
import ImportantNewsModal from './components/ImportantNewsModal';
import { ResidenceInterface } from '../Residence/types';
import { CreateFab, ImportantFab, ArchiveFab, ResetFab } from '../Common/ButtonLinks';
import InventoryIcon from '@mui/icons-material/Inventory';
import PushPinIcon from '@mui/icons-material/PushPin';
import AuthContext from '../Common/AuthContext';
import { AuthContextValue } from '../Common/types';

const styles = StyleSheet.create({
  tableCell: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '2px',
    paddingLeft: '10px',
    align: 'left',
  },
  paddingCheckbox: {
    padding: '2px',
  },
  titleString: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '750px',
  },
  infoString: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '600px',
  },
  content: {
    marginTop: '10px',
  },
  centered: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
});

const News: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyInfo, notifyError, notifySuccess } = useNotify();
  const [pagination, setPagination] = useState(paginationDefaults as ListPagination);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [residenceList, setResidenceList] = useState<Array<ResidenceInterface>>([]);
  const [newsList, setNewsList] = useState<Array<NewsInterface>>([]);
  const [selectedNews, setSelectedNews] = useState([] as NewsInterface[]);
  const [quickActionNews, setQuickActionNews] = useState<Array<NewsInterface>>([]);
  const [newsCount, setNewsCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [archiveModalState, setArchiveModalState] = useState(false);
  const [importantModalState, setImportantModalState] = useState(false);
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  useEffect(() => {
    setPagination(paginationDefaults);
    fetchNewsList();
    fetchResidences();
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchNewsList = (): void => {
    setLoading(true);
    getNewsList({ ...listParams, ...paginationDefaults })
      .then(({ data }) => {
        setNewsList(data.data);
        setNewsCount(data.meta.count);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över nyheter'))
      .finally(() => setLoading(false));
  };

  const fetchResidences = (): void => {
    setLoading(true);
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => {
        setResidenceList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över boende'))
      .finally(() => setLoading(false));
  };

  const restoreNews = (newsIds: (number | undefined)[]): void => {
    setLoading(true);
    if (newsIds) {
      batchRestoreNews(newsIds)
        .then(() => notifySuccess('Nyheterna har uppdaterats'))
        .then(() => {
          setLoading(false);
          fetchNewsList();
          setSelectedNews([]);
          setQuickActionNews([]);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera nyheterna: ${error}`));
    }
  };

  const rowIsSelected = (id: number | undefined): boolean => {
    return selectedNews.some((element) => element.id === id);
  };

  const selectRow = (event: any, row: NewsInterface): void => {
    // Avoid to navigate to job application detail view
    event.stopPropagation();

    // Push or pop element from list
    const newSelectedList = event.target.checked
      ? [...selectedNews, row]
      : selectedNews.filter((a: any) => a.id !== row.id);

    setSelectedNews(newSelectedList);
  };

  const getResidencesString = (residences: OptionsInterface[] | undefined): string => {
    if (residences) {
      return residences
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((r: OptionsInterface) => r.name)
        .join(', ');
    } else {
      return '';
    }
  };

  const getRolesString = (roles: RoleOptionsInterface[] | undefined): string => {
    if (roles) {
      if (roles.length === 3) {
        return 'Alla';
      } else {
        return roles
          .map((r: RoleOptionsInterface) => {
            return r.short_name;
          })
          .join(' + ');
      }
    } else {
      return '';
    }
  };

  const truncate = (string: string, limit: number): string => {
    if (string.length <= limit) {
      return string;
    }
    return string.slice(0, limit) + '...';
  };

  const SelectionFabs: React.FC = () => {
    return (
      <Grid container className={css(commonStyles.selectionFabContainerStyle2)}>
        {selectedNews.length == 1 && !hasPermissions(['regional_manager']) && (
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <ImportantFab onClick={(): void => setImportantModalState(true)} text="Viktig nyhet" />
          </Grid>
        )}
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <ArchiveFab onClick={(): void => setArchiveModalState(true)} text="Arkivera" />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <ResetFab
            onClick={(): void => {
              restoreNews(selectedNews.map((j) => j.id));
            }}
            text="Återställ"
          />
        </Grid>
      </Grid>
    );
  };

  // render
  const rows = [
    { id: 'select', label: 'Välj', notSortable: true },
    { id: 'title', label: 'Innehåll' },
    { id: 'residences', label: 'Valda boenden', notSortable: true },
    { id: 'roles', label: 'Visas för', notSortable: true },
    { id: 'author', label: 'Skapad av', notSortable: true },
    { id: 'published_at', label: 'Publiceringsdatum', align: 'center' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Nyheter</h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>{newsCount} Nyheter</p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <SearchBar setGetListParams={setGetListParams} listParams={listParams} />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={1} sm={1} md={1} lg={1}>
            <FilterPopover
              setGetListParams={setGetListParams}
              listParams={listParams}
              setSelectedRowItems={setSelectedNews}
              setQuickActionItem={setQuickActionNews}
            />
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(newsList) ? (
              <EmptyList />
            ) : (
              newsList.map((row: NewsInterface, idx: number) => (
                <TableRow
                  customRoot={
                    rowIsSelected(row.id)
                      ? css(commonStyles.greenRow, commonStyles.newsRowHeight)
                      : row.deleted_at
                      ? css(commonStyles.archivedRow, commonStyles.newsRowHeight)
                      : idx % 2 !== 0
                      ? css(commonStyles.greyRow, commonStyles.newsRowHeight)
                      : css(commonStyles.newsRowHeight)
                  }
                  row={row}
                  idx={idx}
                  key={row.id}
                  onClick={(): void => navigate(`${row.id}`)}
                >
                  <TableCell width="50" className={css(styles.paddingCheckbox)}>
                    {row.editable && (
                      <Checkbox onClick={(e): void => selectRow(e, row)} checked={rowIsSelected(row.id)} />
                    )}
                  </TableCell>
                  <TableCell scope="row" width="400" className={css(styles.tableCell)}>
                    <div className={css(styles.titleString)}>
                      <b>{truncate(row.title, 100)}</b>
                    </div>
                    <div className={css(styles.titleString, styles.content)}>
                      {truncate(row.content.replace(/&nbsp;/g, ' ').replace(/(<([^>]+)>)/gi, ''), 100)}
                    </div>
                  </TableCell>
                  <TableCell width="400" className={css(styles.tableCell)}>
                    <Tooltip
                      title={<p style={{ fontSize: '1.25em' }}>{getResidencesString(row.residences)}</p>}
                      placement="bottom-start"
                    >
                      <div className={css(styles.infoString)}>{truncate(getResidencesString(row.residences), 100)}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <div>{getRolesString(row.roles)}</div>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    {row.author?.first_name + ' ' + row.author?.surname?.charAt(0)}
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <div>{formatDate(row.published_at)}</div>
                    {row.deleted_at && (
                      <div className={css(styles.centered)}>
                        <span style={{ marginRight: '5px' }}>
                          <InventoryIcon sx={{ fontSize: '1rem' }} />
                        </span>
                        <span>
                          <b>Arkiverad</b>
                        </span>
                      </div>
                    )}
                    {row.important && (
                      <div className={css(styles.centered)}>
                        <span style={{ marginRight: '5px' }}>
                          <PushPinIcon sx={{ color: theme.themeGreen, fontSize: '1rem' }} />
                        </span>
                        <span style={{ color: theme.themeGreen }}>
                          <b>Viktig nyhet</b>
                        </span>
                      </div>
                    )}
                  </TableCell>
                  {row.editable ? (
                    <MenuTableCell>
                      {!hasPermissions(['regional_manager']) && (
                        <MenuItem
                          onClick={(): void => {
                            setQuickActionNews([row]);
                            setSelectedNews([]);
                          }}
                        >
                          <ImportantFab onClick={(): void => setImportantModalState(true)} text="Viktig nyhet" />
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={(): void => {
                          setQuickActionNews([row]);
                          setSelectedNews([]);
                        }}
                      >
                        <ArchiveFab onClick={(): void => setArchiveModalState(true)} text="Arkivera" />
                      </MenuItem>
                      <MenuItem>
                        <ResetFab
                          onClick={(): void => {
                            restoreNews([row.id]);
                          }}
                          text="Återställ"
                        />
                      </MenuItem>
                    </MenuTableCell>
                  ) : (
                    <TableCell align="left"></TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {importantModalState && (
          <Dialog
            open={importantModalState}
            onClose={(): void => {
              setImportantModalState(false);
              setQuickActionNews([]);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <ImportantNewsModal
              // only one news item should be selected as important
              news={quickActionNews[0] ? quickActionNews[0] : selectedNews[0]}
              setModalState={setImportantModalState}
              fetchNewsList={fetchNewsList}
              setSelectedNews={setSelectedNews}
              setQuickActionNews={setQuickActionNews}
            />
          </Dialog>
        )}
        {archiveModalState && (
          <Dialog
            open={archiveModalState}
            onClose={(): void => {
              setArchiveModalState(false);
              setQuickActionNews([]);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <DeleteNewsModal
              news={quickActionNews[0] ? quickActionNews : selectedNews}
              setModalState={setArchiveModalState}
              fetchNewsList={fetchNewsList}
              setSelectedNews={setSelectedNews}
              setQuickActionNews={setQuickActionNews}
            />
          </Dialog>
        )}

        <LoadMoreButton
          loadList={getNewsList}
          state={newsList}
          setState={setNewsList}
          params={listParams}
          pagination={pagination}
          setPagination={setPagination}
        />

        {!isNotEmpty(selectedNews) && <CreateFab link="create" />}
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}

        <Grid
          container
          spacing={2}
          style={{ justifyContent: 'flex-start', position: 'sticky', bottom: '20px', zIndex: '2' }}
        >
          <Grid item xs={1} sm={1} md={1} lg={2}></Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            {isNotEmpty(selectedNews) && <SelectionFabs />}
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={2}></Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default News;
