import * as Yup from 'yup';

import { sort_order } from '../Common/types';

export const initialValues = {
  name: '',
  notes: `Utförare:
Arbetssätt:

ANDRA JOURNR:

ÖVERGRIPANDE INFORMATION
* Hänger av sig...
* Struktur för passen (uppdelning, våningar)...
* Uppföljningsrutin...
* Några risker i arbetsmiljö som bör noteras?
* Övriga rutiner på boendet som bör noteras?
------------------------------------------------------------------------

MÖTE 20XX ÅRSTID (DD/M-YY)
Närvarande: TL Förnamn Efternamn, RC Förnamn Efternamn, UOA....

* Feedback från boendet:
* Aktiviteter i fokus:
* Inköpsmöjlighet/ rutin:
* Ev närståendemöte: (Om nej, behöver ej vara med)
* Övrigt:
------------------------------------------------------------------------
MÖTE 20XX ÅRSTID (DD/M-YY)
Närvarande: TL Förnamn Efternamn, RC Förnamn Efternamn, UOA....
`,
  standard_attendance: 0,
  standard_workday: 2.0,
  residence_id: undefined,
  is_active: true,
  deletable: false,
  editable: false,
  residence: { name: '' },
};

export const initialEditValues = {
  name: '',
  is_active: true,
  deletable: false,
  editable: false,
  residence: { name: '' },
};

export const validationSchema = Yup.object().shape({
  residence_id: Yup.mixed().required('* Välj ett boende'),
  standard_attendance: Yup.number()
    .integer('* Måste vara ett heltal')
    .typeError('* Måste vara ett heltal')
    .min(0, '* Kan inte vara negativt'),
  standard_workday: Yup.number().min(0, '* Får inte vara negativ'),
  name: Yup.string().required('* Obligatoriskt fält').max(28, '* Namnet får inte vara längre än 28 tecken'),
  people_attributes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when('_destroy', {
        is: (value) => value !== 1,
        then: Yup.string().required('* Obligatoriskt fält'),
      }),
    })
  ),
});

export const editValidationSchema = Yup.object().shape({
  name: Yup.string().required('* Obligatoriskt fält').max(28, '* Namnet är för långt'),
  standard_attendance: Yup.number()
    .integer('* Måste vara ett heltal')
    .typeError('* Måste vara ett heltal')
    .min(0, '* Kan inte vara negativt'),
  standard_workday: Yup.number().min(0, '* Får inte vara negativ'),
  people_attributes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when('_destroy', {
        is: (value) => value !== 1,
        then: Yup.string().required('* Obligatoriskt fält'),
      }),
    })
  ),
});

export const filterSortDefaults = {
  query: '',
  sort_by: 'region_name',
  sort_order: sort_order.asc,
  is_active: true,
};
