import React from 'react';

import { FormControl, Grid, IconButton } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { ClearIcon, DesktopDatePicker } from '@mui/x-date-pickers';
import { getValue } from '../utilities';

type Props = {
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  fieldName: string;
  label: string;
  placeholder: string;
  errors: any;
  values: any;
  onChange: any;
  minDate?: any;
  maxDate?: any;
  disabled?: any;
  required?: boolean;
};

const FormDateField: React.FC<Props> = (props) => {
  const value = getValue(props.values, props.fieldName);
  const error = getValue(props.errors, props.fieldName);

  const onDateChange = (e: Dayjs | null): void => {
    props.onChange(props.fieldName, e?.format('YYYY-MM-DD') ?? null);
  };

  return (
    <Grid item xs={props.size}>
      <FormControl variant="standard" margin="normal" required={props.required} fullWidth>
        <DesktopDatePicker
          format="YYYY-MM-DD"
          value={value ? dayjs(value) : null}
          label={props.label}
          name={props.fieldName}
          onChange={onDateChange}
          slotProps={{
            field: {
              InputProps: {
                endAdornment: !props.required && (
                  <IconButton onClick={() => onDateChange(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              },
            },
            textField: {
              helperText: error,
              error: !!error,
            },
            inputAdornment: { position: 'start', className: 'ml-3.5' },
          }}
          minDate={dayjs(props.minDate || '1970-01-01')}
          maxDate={dayjs(props.maxDate || '2100-12-31')}
          disabled={props.disabled}
        />
      </FormControl>
    </Grid>
  );
};

export default FormDateField;
