import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
} from '@mui/material';
import { Formik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { OptionsInterface } from '../../Common/types';
import { getTeamMinimalList } from '../../Team/teamApi';
import { sendWelcomeEmail } from '../../User/usersApi';
import { employ } from './../jobApplicationApi';
import { employValidationSchema } from './../jobApplicationSchema';
import { JobApplicationInterface } from './../types';

type Props = {
  jobApplication?: JobApplicationInterface;
  setModalState: any;
  setSelectedJobApplications: any;
  fetchJobApplicationsList: any;
  type: 'participant' | 'team_leader';
};

const EmployJobApplicationModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [jobApplicationId, setJobApplicationId] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  // hooks
  const [teamMinimalList, setTeamMinimalList] = useState<Array<OptionsInterface>>([]);

  useEffect(() => {
    if (!props.jobApplication?.id) return;
    setLoading(true);
    setShowSendEmailModal(false);
    setJobApplicationId(props.jobApplication.id);

    fetchTeamsMinimalList();
  }, [props.jobApplication]); // eslint-disable-line

  // functions
  const fetchTeamsMinimalList = (): void => {
    getTeamMinimalList({ is_active: true })
      .then(({ data }) => setTeamMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'))
      .finally(() => setLoading(false));
  };

  const handleFormSubmit = (values: {
    email: string;
    first_name: string;
    surname: string;
    team: OptionsInterface;
  }): void => {
    setLoading(true);
    if (jobApplicationId) {
      const { team, ...rest } = values;
      employ(jobApplicationId, props.type, { ...rest, team_id: team.id as number })
        .then(({ data }) => {
          setUserId(data.user.data.id);
          notifySuccess('Kandidaten anställd.');
          setShowSendEmailModal(true);
          props.fetchJobApplicationsList();
        })
        .catch((error) => {
          if (error.response.data.errors == 'E-mail already in use.') {
            notifyError(
              `Det gick inte att anställa kandidaten. En person med angiven mejladress finns redan i databasen och arbetar i ett annat team: OBS. Lägg inte till personen igen utan kontakta info@ungomsorg.se och be att personen flyttas till ditt team, alt. lägg till personen med knappen "Lägg till Deltagare" vid schemaläggning/inrapportering av pass.`
            );
          } else if (error.response.data.errors == 'Duplicate personNr.') {
            notifyError(
              `Det gick inte att anställa kandidaten. En person med angiven personnummer finns redan i databasen och arbetar i ett annat team. OBS. Lägg inte till personen igen utan kontakta info@ungomsorg.se och be att personen flyttas till ditt team, alt. lägg till personen med knappen "Lägg till Deltagare" vid schemaläggning/inrapportering av pass.`
            );
          } else if (error.response.data.errors == 'Invalid e-mail.') {
            notifyError(`Det gick inte att anställa kandidaten. Ogiltig mejladress.`);
          } else {
            notifyError(
              `Det gick inte att anställa kandidaten: ${HandleError(
                error.response.data
              )}. Lägg inte till personen igen utan kontakta info@ungomsorg.se.`
            );
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handleEmailSend = (): void => {
    if (userId) {
      sendWelcomeEmail(userId.toString())
        .then(() => {
          notifySuccess('Välkomstmail har skickats.');
        })
        .catch((error) => {
          notifyError(`Det gick inte att skicka välkomstmail till den anställde. Kontakta info@ungomsorg.se.`);
        });
    }
    setShowSendEmailModal(false);
    props.setModalState(false);
    props.setSelectedJobApplications([]);
  };

  if (!props.jobApplication) return null;

  return loading ? (
    <div className={css(commonStyles.spinner)}>
      <RotateLoader loading={loading} />
    </div>
  ) : (
    <>
      {showSendEmailModal ? (
        <div>
          <div style={{ marginLeft: '40px' }}>
            <DialogTitle>Skicka inloggningsuppgifter?</DialogTitle>
          </div>
          <div className={css(commonStyles.formContainer)}>
            <DialogContentText>
              Vill du skicka ett email till den anställde med information och inloggningsuppgifter?
            </DialogContentText>
            <Divider className="!mb-5" />
            <DialogContent>
              <DialogActions>
                <Button
                  fullWidth
                  color="error"
                  onClick={(): void => {
                    props.setModalState(false);
                    props.setSelectedJobApplications([]);
                  }}
                >
                  NEJ
                </Button>
                <Button fullWidth color="primary" onClick={(): void => handleEmailSend()}>
                  JA, SKICKA E-POST
                </Button>
              </DialogActions>
            </DialogContent>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ marginLeft: '40px' }}>
            <DialogTitle>
              Anställ {props.jobApplication.first_name} {props.jobApplication.surname}
            </DialogTitle>
          </div>
          <div className={css(commonStyles.formContainer)}>
            <DialogContentText> Kontrollera personuppgifterna. </DialogContentText>

            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={{
                first_name: props.jobApplication.first_name,
                surname: props.jobApplication.surname,
                email: props.jobApplication.email,
                team: null as OptionsInterface | null,
              }}
              validationSchema={employValidationSchema}
              onSubmit={(values): void => {
                handleFormSubmit({ ...values, team: values.team! });
              }}
            >
              {({ values, errors, isValid, handleChange, handleSubmit, setFieldValue }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                      <FormTextField
                        size={6}
                        fieldName="first_name"
                        label={'Förnamn'}
                        placeholder="Förnamn"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                      />
                      <FormTextField
                        size={6}
                        fieldName="surname"
                        label={'Efternamn'}
                        placeholder="Efternamn"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                      <FormTextField
                        size={6}
                        fieldName="email"
                        label={'E-post adress'}
                        placeholder="Email"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                      />
                      <Grid item xs={6}>
                        <FormControl margin="normal" required fullWidth>
                          <FormAutocompleteBox
                            type="single"
                            id="team_id"
                            name="team"
                            label="Team"
                            getOptionLabel={(opt) => opt.name}
                            options={teamMinimalList}
                            value={values.team}
                            onChange={(_, value) => {
                              setFieldValue('team', value);
                            }}
                            error={errors.team}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <DialogContentText style={{ color: 'black' }}>
                    Kom ihåg: Ge besked och ta bort de ansökningar som inte fick jobbet efter intervjun!
                  </DialogContentText>
                  <Divider className="!my-5" />
                  <DialogContent>
                    <DialogActions>
                      <Button
                        fullWidth
                        color="error"
                        onClick={(): void => {
                          props.setModalState(false);
                        }}
                      >
                        AVBRYT
                      </Button>
                      <Button fullWidth color="primary" disabled={!isValid} onClick={(): void => handleSubmit()}>
                        ANSTÄLL
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployJobApplicationModal;
