import API from '../Common/axios';
import { AxiosResponse } from 'axios';

import {
  MinimalUser,
  MinimalWorkShiftUserInterface,
  PasswordPayloadInterface,
  ResetPasswordInterface,
  UserInterface,
  UserUpdateParamsInterface,
} from './types';
import { ListParams, Payslip } from '../Common/types';

export const changePassword = (id: string | undefined, params: PasswordPayloadInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/users/${id}/change_password`, params);
};

export const updateProfile = (id: string | undefined, first_name: string, surname: string): Promise<AxiosResponse> => {
  return API.patch(`v1/users/${id}`, { first_name: first_name, surname: surname });
};

export const createUser = (
  user: Omit<UserInterface, 'created_at' | 'role' | 'team'> & { role_id: number; team_id: number }
) => {
  return API.post<{ data: UserInterface }>(`v1/users`, { user });
};

export const updateUser = (
  id: string,
  user:
    | (Omit<UserInterface, 'created_at' | 'role' | 'team'> & { role_id: number; team_id: number })
    | UserUpdateParamsInterface
): Promise<AxiosResponse> => {
  return API.patch(`v1/users/${id}`, { user });
};

export const toggleBanned = (id: string, banned: boolean, banned_email: boolean): Promise<AxiosResponse> => {
  return API.patch(`v1/users/${id}/toggle_banned`, { banned: banned, banned_email: banned_email });
};

export const sendWelcomeEmail = (id: string): Promise<AxiosResponse> => {
  return API.post(`v1/users/${id}/send_welcome_email`);
};

export const sendResetPasswordEmail = (id: string): Promise<AxiosResponse> => {
  return API.post(`v1/users/${id}/send_reset_password_email`);
};

export const setPasswordQuickAction = (id: string, password: string): Promise<AxiosResponse> => {
  return API.patch(`v1/users/${id}/set_password`, { password: password });
};

export const resetPassword = (params: ResetPasswordInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/users/reset_password`, params);
};

export type GetUsersParams = {
  query?: string;
  user_id?: string;
  hide_banned?: boolean;
  from_birthdate?: string;
  to_birthdate?: string;
  filter_residence_clusters?: (string | number)[];
  filter_residence_groups?: (string | number)[];
  filter_regions?: (string | number)[];
  filter_teams?: (string | number)[];
  banned_at_start?: string | null;
  banned_at_end?: string | null;
  page?: number;
};

export const getUsers = (params: GetUsersParams): Promise<AxiosResponse> => {
  return API.get('v1/users', { params });
};

export const getUser = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/users/${id}`);
};

type GetUsersMinimalListParams = {
  hide_banned?: boolean;
  filter_by_role_name?: string;
  filter_regional_manager?: string;
  filter_teams?: (string | number)[];
  name_with_role?: boolean;
};

export async function getUsersMinimalList(
  params: GetUsersMinimalListParams & { with_role?: false }
): Promise<AxiosResponse<{ data: MinimalUser[] }, any>>;
export async function getUsersMinimalList(
  params: GetUsersMinimalListParams & { with_role: true }
): Promise<AxiosResponse<{ data: MinimalWorkShiftUserInterface[] }, any>>;
export async function getUsersMinimalList(params: GetUsersMinimalListParams) {
  return API.get('v1/users/minimal_list', { params });
}

export const getContracts = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/users/${id}/agreements`);
};

export const getInfo = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/users/fetch_info', { params });
};

export const getPayslips = (id?: string): Promise<AxiosResponse> => {
  return API.get<{ data: Payslip[] }>(`v1/users/${id}/payslips`);
};
