import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { batchDeleteAgreements, getDeletableAgreements } from '../agreementApi';
import { AgreementInterface } from '../types';

type Props = {
  agreements: AgreementInterface[];
  fetchAgreementsList: () => void;
  setModalState: any;
};

const BatchRemoveAgreementModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [agreementIds, setAgreementIds] = useState<number[]>([]);
  const [detailedAgreements, setDetailedAgreements] = useState<Array<AgreementInterface>>([]);
  const [loading, setLoading] = useState(true);

  // Get the agreements ids into a list
  useEffect(() => {
    const ids: number[] = [];
    props.agreements.forEach((agreement) => {
      if (agreement.id) ids.push(parseInt(agreement.id));
    });
    setAgreementIds(ids);
  }, [props.agreements]);

  // When we get the ids, check if they are deletable
  useEffect(() => {
    setLoading(true);
    if (agreementIds.length > 0) {
      getDeletableAgreements(agreementIds)
        .then((response) => setDetailedAgreements(response.data.data))
        .catch((error: any) => {
          notifyError(`Det gick inte att ladda något av avtalen : ${error}`);
          props.setModalState(false);
        })
        .then(() => setLoading(false));
    }
  }, [agreementIds]); // eslint-disable-line

  const handleSubmit = (): void => {
    setLoading(true);
    batchDeleteAgreements(agreementIds)
      .then(() => notifySuccess('Avtalen tas nu bort, det kan ta några minuter om det är många.'))
      .then(() => props.fetchAgreementsList()) // Refresh the agreements list
      .catch((error: any) => notifyError(`Avtalen gick inte att ta bort : ${error}`))
      .finally(() => {
        setLoading(false);
        props.setModalState(false);
      });
  };

  const ListRow: React.FC<{ agreement: AgreementInterface }> = (props) => {
    return (
      <div className={css(commonStyles.batchRemoveListElement)}>
        {props.agreement.residence?.name}
        <div>
          {props.agreement.start_date} till {props.agreement.end_date}
        </div>
      </div>
    );
  };

  return (
    <div>
      <DialogTitle>Ta bort avtal</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          {detailedAgreements.every((agreement) => agreement.deletable) ? (
            <DialogContent>
              <DialogContentText>{detailedAgreements.length} avtal valda</DialogContentText>
              <List>
                {detailedAgreements.map((agreement: AgreementInterface) => (
                  <ListItem key={agreement.id}>
                    <ListRow agreement={agreement} />
                  </ListItem>
                ))}
              </List>
              <DialogContentText>Ta bort de valda avtalen?</DialogContentText>
              <DialogActions>
                <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
                  NEJ
                </Button>
                <Button fullWidth color="primary" onClick={(): void => handleSubmit()}>
                  JA
                </Button>
              </DialogActions>
            </DialogContent>
          ) : (
            <React.Fragment>
              <DialogContent>
                <DialogContentText>
                  Tyvärr kan en eller flera av de valda avtalen inte tas bort då de har blivit korrigerade eller har
                  inrapporterade timmar.
                </DialogContentText>
                <DialogContentText>
                  <b>Följande avtal går inte att ta bort:</b>
                </DialogContentText>
                <List>
                  {detailedAgreements
                    .filter((agreement) => !agreement.deletable)
                    .map((agreement: AgreementInterface) => (
                      <ListItem key={agreement.id}>
                        <ListRow agreement={agreement} />
                      </ListItem>
                    ))}
                </List>
              </DialogContent>
              <DialogActions>
                <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
                  AVBRYT
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default BatchRemoveAgreementModal;
