import { AxiosResponse } from 'axios';
import API from '../../Common/axios';
import { EmployeeAgreementInterface } from './types';

export const getEmployeeAgreements = (): Promise<AxiosResponse> => {
  return API.get('v1/employee_agreement_templates');
};

export const getEmployeeAgreement = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/employee_agreement_templates/${id}`);
};

export const updateEmployeeAgreement = (
  id: number,
  employee_agreement_template: EmployeeAgreementInterface
): Promise<AxiosResponse> => {
  return API.patch(`v1/employee_agreement_templates/${id}`, { employee_agreement_template });
};
