import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { Table, TableBody, TableCell, Dialog, Checkbox, Grid, Tooltip, MenuItem } from '@mui/material';
import { TableHead, TableRow, LoadMoreButton, MenuTableCell } from '../Common/Table';
import { paginationDefaults, filterSortDefaults, filterSortDefaultsAdminVaVl } from './jobOpeningSchema';
import {
  ListParams,
  ListPagination,
  OptionsInterface,
  NotificationContextValue,
  AuthContextValue,
  RoleOptionsInterface,
} from '../Common/types';
import NotificationContext from '../Common/NotificationContext';
import { formatDate, isEmpty, isNotEmpty } from '../Common/utilities';
import { StyleSheet, commonStyles, css } from '../Common/styling';
import FilterPopover from './components/FilterPopover';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import { JobOpeningInterface } from './types';
import { getJobOpeningList, batchRestoreJobOpening } from './jobOpeningApi';
import DeleteJobOpeningModal from './components/DeleteJobOpeningModal';
import { CreateFab, ArchiveFab, ResetFab } from '../Common/ButtonLinks';
import InventoryIcon from '@mui/icons-material/Inventory';
import AuthContext from '../Common/AuthContext';

const styles = StyleSheet.create({
  tableCell: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '2px',
    paddingLeft: '10px',
  },
  paddingCheckbox: {
    padding: '2px',
  },
  titleString: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  infoString: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  content: {
    marginTop: '10px',
  },
  centered: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
});

const JobOpening: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;
  const { notifyError, notifySuccess } = useNotify();
  const [pagination, setPagination] = useState(paginationDefaults as ListPagination);
  const [listParams, setGetListParams] = useState(
    hasPermissions(['admin', 'operational_leader', 'operational_manager'])
      ? filterSortDefaultsAdminVaVl
      : (filterSortDefaults as ListParams)
  );
  const [jobOpeningList, setJobOpeningList] = useState<Array<JobOpeningInterface>>([]);
  const [selectedJobOpening, setSelectedJobOpening] = useState([] as JobOpeningInterface[]);
  const [quickActionJobOpening, setQuickActionJobOpening] = useState<Array<JobOpeningInterface>>([]);
  const [jobOpeningCount, setJobOpeningCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [archiveModalState, setArchiveModalState] = useState(false);
  const [importantModalState, setImportantModalState] = useState(false);
  // TODO: call for refreshJobOpeningsNotification every time a JobOpening is created, deleted
  // or updated (if it is possible to change the author of the JobOpening).
  const { refreshJobOpeningsNotification } = useContext(NotificationContext) as NotificationContextValue;

  useEffect(() => {
    setPagination(paginationDefaults);
    fetchJobOpeningList();
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchJobOpeningList = (): void => {
    setLoading(true);
    getJobOpeningList({ ...listParams, ...paginationDefaults })
      .then(({ data }) => {
        setJobOpeningList(data.data);
        setJobOpeningCount(data.meta.count);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över jobbannonser'))
      .finally(() => setLoading(false));
  };

  const restoreJobOpening = (JobOpeningIds: (number | undefined)[]): void => {
    setLoading(true);
    if (JobOpeningIds) {
      batchRestoreJobOpening(JobOpeningIds)
        .then(() => notifySuccess('Jobbannonserna har uppdaterats'))
        .then(() => {
          setLoading(false);
          fetchJobOpeningList();
          setSelectedJobOpening([]);
          setQuickActionJobOpening([]);
          refreshJobOpeningsNotification();
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera Jobbannonserna: ${error}`));
    }
  };

  const rowIsSelected = (id: number | undefined): boolean => {
    return selectedJobOpening.some((element) => element.id === id);
  };

  const selectRow = (event: any, row: JobOpeningInterface): void => {
    // Avoid to navigate to job application detail view
    event.stopPropagation();

    // Push or pop element from list
    const JobOpeningelectedList = event.target.checked
      ? [...selectedJobOpening, row]
      : selectedJobOpening.filter((a: any) => a.id !== row.id);

    setSelectedJobOpening(JobOpeningelectedList);
  };

  const getResidencesString = (residences: OptionsInterface[] | undefined): string => {
    if (residences) {
      return residences
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((r: OptionsInterface) => r.name)
        .join(', ');
    } else {
      return '';
    }
  };

  const getRolesString = (roles: RoleOptionsInterface[] | undefined): string => {
    if (roles) {
      return roles
        .map((r: RoleOptionsInterface) => {
          return r.short_name;
        })
        .join(' + ');
    } else {
      return '';
    }
  };

  const truncate = (string: string, limit: number): string => {
    if (string.length <= limit) {
      return string;
    }
    return string.slice(0, limit) + '...';
  };

  const SelectionFabs: React.FC = () => {
    return (
      <Grid container className={css(commonStyles.selectionFabContainerStyle2)}>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <ArchiveFab onClick={(): void => setArchiveModalState(true)} text="Arkivera" />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <ResetFab
            onClick={(): void => {
              restoreJobOpening(selectedJobOpening.map((j) => j.id));
            }}
            text="Återställ"
          />
        </Grid>
      </Grid>
    );
  };

  // render
  const rows = [
    { id: 'select', label: 'Välj', notSortable: true },
    { id: 'title', label: 'Innehåll' },
    { id: 'residences', label: 'Valda boenden', notSortable: true },
    { id: 'roles', label: 'Sökes' },
    { id: 'author', label: 'Skapad av' },
    { id: 'created_at', label: 'Datum', align: 'center' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Internrekrytering</h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {jobOpeningCount} Annonser
            </p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <SearchBar setGetListParams={setGetListParams} listParams={listParams} />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={1} sm={1} md={1} lg={1}>
            <FilterPopover
              setGetListParams={setGetListParams}
              setSelectedRowItems={setSelectedJobOpening}
              setQuickActionItem={setQuickActionJobOpening}
              listParams={listParams}
            />
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(jobOpeningList) ? (
              <EmptyList
                alternativeText={'Inga skapade annonser (se andras annonser genom filtermenyn uppe till höger)'}
              />
            ) : (
              jobOpeningList.map((row: JobOpeningInterface, idx: number) => (
                <TableRow
                  customRoot={
                    rowIsSelected(row.id)
                      ? css(commonStyles.greenRow, commonStyles.newsRowHeight)
                      : row.deleted_at
                      ? css(commonStyles.archivedRow, commonStyles.newsRowHeight)
                      : idx % 2 !== 0
                      ? css(commonStyles.greyRow, commonStyles.newsRowHeight)
                      : css(commonStyles.newsRowHeight)
                  }
                  row={row}
                  idx={idx}
                  key={row.id}
                  onClick={(): void => navigate(`/job_openings/${row.id}`)}
                >
                  <TableCell className={css(styles.paddingCheckbox)}>
                    {row.editable && (
                      <Checkbox onClick={(e): void => selectRow(e, row)} checked={rowIsSelected(row.id)} />
                    )}
                  </TableCell>
                  <TableCell scope="row" width="300" className={css(styles.tableCell)}>
                    <div className={css(styles.titleString)}>
                      <b>{truncate(row.title, 50)}</b>
                    </div>
                    <div className={css(styles.titleString, styles.content)}>
                      {truncate(row.content.replace(/(<([^>]+)>)/gi, ''), 100)}
                    </div>
                  </TableCell>
                  <TableCell align="left" className={css(styles.tableCell)}>
                    <Tooltip
                      title={<p style={{ fontSize: '1.25em' }}>{getResidencesString(row.residences)}</p>}
                      placement="top-start"
                    >
                      <div className={css(styles.infoString)}>{truncate(getResidencesString(row.residences), 100)}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <div>{getRolesString(row.roles)}</div>
                  </TableCell>
                  <TableCell
                    scope="row"
                    style={{ paddingTop: '2px', paddingBottom: '2px', marginRight: '10px', paddingLeft: '0px' }}
                  >
                    {row.author?.first_name + ' ' + row.author?.surname?.charAt(0)}
                  </TableCell>
                  <TableCell align="center">
                    <div>{formatDate(row.created_at)}</div>
                    {row.deleted_at && (
                      <div className={css(styles.centered)}>
                        <span style={{ marginRight: '5px' }}>
                          <InventoryIcon sx={{ fontSize: '1rem' }} />
                        </span>
                        <span>
                          <b>Arkiverad</b>
                        </span>
                      </div>
                    )}
                  </TableCell>
                  {row.editable ? (
                    <MenuTableCell>
                      <MenuItem
                        onClick={(): void => {
                          setQuickActionJobOpening([row]);
                          setSelectedJobOpening([]);
                        }}
                      >
                        <ArchiveFab onClick={(): void => setArchiveModalState(true)} text="Arkivera" />
                      </MenuItem>
                      <MenuItem>
                        <ResetFab
                          onClick={(): void => {
                            restoreJobOpening([row.id]);
                          }}
                          text="Återställ"
                        />
                      </MenuItem>
                    </MenuTableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {archiveModalState && (
          <Dialog
            open={archiveModalState}
            onClose={(): void => {
              setArchiveModalState(false);
              setQuickActionJobOpening([]);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <DeleteJobOpeningModal
              jobOpening={quickActionJobOpening[0] ? quickActionJobOpening : selectedJobOpening}
              setModalState={setArchiveModalState}
              fetchJobOpeningList={fetchJobOpeningList}
              setSelectedJobOpening={setSelectedJobOpening}
              setQuickActionJobOpening={setQuickActionJobOpening}
            />
          </Dialog>
        )}

        <LoadMoreButton
          loadList={getJobOpeningList}
          state={jobOpeningList}
          setState={setJobOpeningList}
          params={listParams}
          pagination={pagination}
          setPagination={setPagination}
        />

        {!isNotEmpty(selectedJobOpening) && <CreateFab link={`/job_openings/create`} />}
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}

        <Grid
          container
          spacing={2}
          style={{ justifyContent: 'flex-start', position: 'sticky', bottom: '20px', zIndex: '2' }}
        >
          <Grid item xs={1} sm={1} md={1} lg={2}></Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            {isNotEmpty(selectedJobOpening) && <SelectionFabs />}
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={2}></Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default JobOpening;
