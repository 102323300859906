import React, { useState, useEffect, ReactElement } from 'react';
import { RotateLoader } from 'react-spinners';

import {
  Button,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

import { getManagementList, getUpdatedManagementInvoice, lockTimeReports } from './managementApi';
import { MenuTableCell, LoadMoreButton, TableHead } from '../Common/Table';
import { paginationDefaults, filterSortDefaults } from './managementSchema';
import MakeCorrectionsModal from './components/MakeCorrectionsModal';
import { ListParams, ListPagination } from '../Common/types';
import { isEmpty, isNotEmpty } from '../Common/utilities';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { ManageableInvoicesInterface } from './types';
import InvoiceModal from './components/InvoiceModal';
import { UpdateFab } from '../Common/ButtonLinks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import { SubRow } from './components/SubRow';
import { syncExportedStatus } from '../WorkShift/workShiftApi';

const Management: React.FC = () => {
  // hooks
  const { notifyError, notifySuccess, closeNotify } = useNotify();
  const [isSyncExportedStatusButtonDisabled, setIsSyncExportedStatusButtonDisabled] = useState(false);
  const [isLockTimesButtonDisabled, setIsLockedTimesButtonDisabled] = useState(false);
  const [isProcessAllInvoicesButtonDisabled, setIsProcessAllInvoicesButtonDisabled] = useState(false);
  const [managementList, setManagementList] = useState<Array<ManageableInvoicesInterface>>([]);
  const [selectedManagement, setSelectedManagement] = useState<Array<ManageableInvoicesInterface>>([]);
  const [pagination, setPagination] = useState(paginationDefaults as ListPagination);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [correctionModalState, setCorrectionModalState] = useState(false);
  const [invoiceDetailState, setInvoiceDetailState] = useState({} as any);
  const [invoiceModalState, setInvoiceModalState] = useState(false);
  const [correctionState, setCorrectionState] = useState({} as any);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPagination(paginationDefaults);
    fetchManagementList();
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchManagementList = (): void => {
    setLoading(true);
    // Uses paginationDefaults because setState is a async function
    getManagementList({ ...listParams, ...paginationDefaults })
      .then(({ data }) => setManagementList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan'))
      .finally(() => setLoading(false));
  };

  const openInvoiceModal = (row?: any): void => {
    row ? setInvoiceDetailState([row]) : setInvoiceDetailState(selectedManagement);
    setInvoiceModalState(true);
  };

  const openInvoiceModalForAll = (): void => {
    setInvoiceDetailState(null);
    setInvoiceModalState(true);
  };

  const openCorrectionModal = (invoiceAgreement: any, team: any): void => {
    setCorrectionState({ team: team, agreement: invoiceAgreement });
    setCorrectionModalState(true);
  };

  const correctionCallback = (id: number): void => {
    getUpdatedManagementInvoice(id)
      .then(({ data }) => {
        const updatedList = managementList.map((row: any) => (row.id === data.data.id ? data.data : row));
        setManagementList(updatedList);
      })
      .catch(() => notifyError('Det gick inte att hämta de uppdaterade hanteringsobjekten'));
  };

  const invoiceCreated = (): void => {
    setSelectedManagement([]);
    fetchManagementList();
  };

  const selectRow = (event: any, row: ManageableInvoicesInterface): void => {
    // Push or pop element from list
    const newSelectedList = event.target.checked
      ? [...selectedManagement, row]
      : selectedManagement.filter((a: any) => a.id !== row.id);

    setSelectedManagement(newSelectedList);
  };

  const rowIsSelected = (id: number | undefined): boolean => {
    return selectedManagement.some((element) => element.id === id);
  };

  const allInvoicableRowsAreSelected = (): boolean => {
    return (
      selectedManagement.length !== 0 &&
      selectedManagement.length === managementList.filter((i: any) => i.invoicable).length
    );
  };

  const selectAllInvoicableRows = (isChecked: boolean): void => {
    isChecked
      ? setSelectedManagement(
          managementList.filter((i: any) => i.invoicable && !i.previous_active_invoicable_agreements)
        )
      : setSelectedManagement([]);
  };

  const syncWorkShiftExportedStatus = (): void => {
    setIsSyncExportedStatusButtonDisabled(true);
    syncExportedStatus()
      .then(() => notifySuccess(`Exporterad status har synkroniserats korrekt.`))
      .catch(() =>
        notifyError(
          'Det gick inte att synkronisera exporterad status. Försök igen lite senare. Om problemet kvarstår kontakta support för hjälp.'
        )
      )
      .finally(() => setIsSyncExportedStatusButtonDisabled(false));
  };

  const lockWorkTimes = (): void => {
    setIsLockedTimesButtonDisabled(true);
    lockTimeReports()
      .then(() => {
        notifySuccess(
          `Nu kommer arbetspass kopplade till anpassade avtal som rapporterats in till och med sista datumet i föregående månad att låsas. Eventuellt överarbete av timpotten kommer flyttas till framtida avtal om sådana existerar. Det tar några minuter och du får ett mejl så snart det är klart.`,
          { persist: true, action }
        );
      })
      .catch(() => {
        notifyError(
          'Det gick inte att låsa pass. Försök igen lite senare. Om problemet kvarstår kontakta support för hjälp innan du går vidare med fakturering.'
        );
      })
      .finally(() => setIsLockedTimesButtonDisabled(false));
  };

  const processAllInvoices = (): void => {
    setIsProcessAllInvoicesButtonDisabled(true);
    openInvoiceModalForAll();
  };

  // render
  const rows = [
    { id: 'residence_name', label: 'Boende' },
    { id: 'residence_group_name', label: 'Avtalspart' },
    { id: 'type', label: 'Fakt. rutin' },
    { id: 'month', label: 'Fakt. månad' },
    { id: 'startup_fee', numeric: true, label: 'Uppstart (kr)' },
    { id: 'worked_hours_guidance', numeric: true, label: 'Riktvärde (tim)' },
    { id: 'worked_hours', numeric: true, label: 'Upparbetat (tim)' },
    { id: 'invoicable_hours', numeric: true, label: 'Att fakturera (tim)' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  interface TypeObject {
    ContinuousAgreement: string;
    FixedAgreement: string;
    [key: string]: string;
  }
  const type: TypeObject = {
    ContinuousAgreement: 'Löpande',
    FixedAgreement: 'Anpassad',
  };

  // IconButton for the SnackBar when the user should close it manually instead of a timer
  const action = (key: number | string): ReactElement => (
    <IconButton onClick={(): void => closeNotify(key)}>
      <CloseIcon style={{ color: 'white' }} />
    </IconButton>
  );

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h1 className={css(commonStyles.headerTextStyle)}>Fakturarader</h1>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <SearchBar setGetListParams={setGetListParams} listParams={listParams} />
          </Grid>

          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={1} sm={1} md={1} lg={1}>
            {new Date(new Date().setDate(0)).toLocaleDateString('default', { month: 'long', year: 'numeric' })}
          </Grid>
        </Grid>
        <div className={css(commonStyles.filtersBarWrapper)}>
          <Grid container>
            <Grid item sx={{ display: 'flex', justifyContent: 'center', padding: '5px' }} xs={2} sm={2} md={2} lg={2}>
              <Button
                fullWidth
                variant="contained"
                color="success"
                disabled={isSyncExportedStatusButtonDisabled}
                onClick={(): void => syncWorkShiftExportedStatus()}
              >
                SYNK EXPORTERAD
              </Button>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center', padding: '5px' }} xs={2} sm={2} md={2} lg={2}>
              <Button
                fullWidth
                variant="contained"
                color="success"
                disabled={isLockTimesButtonDisabled}
                onClick={(): void => lockWorkTimes()}
              >
                LÅS PASS
              </Button>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center', padding: '5px' }} xs={2} sm={2} md={2} lg={2}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                disabled={isProcessAllInvoicesButtonDisabled}
                onClick={(): void => processAllInvoices()}
              >
                SKAPA ALLA FAKTUROR
              </Button>
            </Grid>
          </Grid>
        </div>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead
            rows={rows}
            listParams={listParams}
            setGetListParams={setGetListParams}
            checkboxComponent={
              <Checkbox
                checked={allInvoicableRowsAreSelected()}
                onClick={(e: any): void => selectAllInvoicableRows(e.target.checked)}
              />
            }
          />

          <TableBody>
            {isEmpty(managementList) ? (
              <EmptyList />
            ) : (
              managementList.map((row: ManageableInvoicesInterface) => (
                <React.Fragment key={row.id}>
                  <TableRow classes={{ root: row.invoicable ? css(commonStyles.greyRow) : css(commonStyles.redRow) }}>
                    <TableCell padding="checkbox" className={css(commonStyles.tableCellSmaller)}>
                      {row.invoicable && (
                        <Checkbox
                          onClick={(e): void => selectRow(e, row)}
                          checked={rowIsSelected(row.id)}
                          disabled={row.previous_active_invoicable_agreements}
                        />
                      )}
                    </TableCell>
                    <TableCell className={css(commonStyles.tableCellSmaller)}>{row.residence.name}</TableCell>
                    <TableCell className={css(commonStyles.tableCell)}>{row.residence_group.name}</TableCell>
                    <TableCell className={css(commonStyles.tableCellSmaller)}>{type[row.agreement.type]}</TableCell>
                    <TableCell className={css(commonStyles.tableCellSmaller)}>{row.month + ' ' + row.year}</TableCell>
                    <TableCell className={css(commonStyles.tableCellSmaller)}>{row.outstanding_startup_fee}</TableCell>
                    <TableCell className={css(commonStyles.tableCellSmaller)}>{row.worked_hours_guidance}</TableCell>
                    <TableCell className={css(commonStyles.tableCellSmaller)}>
                      {row.confirmed_worked_hours + row.additional_confirmed_worked_hours}
                    </TableCell>
                    <TableCell
                      className={
                        row.invoicable
                          ? css(commonStyles.tableCellSmall)
                          : css(commonStyles.textRed, commonStyles.tableCellSmall)
                      }
                    >
                      <b>
                        {row.agreement.type === 'FixedAgreement'
                          ? row.invoicable_hours
                          : `${row.confirmed_worked_hours} + ${row.additional_confirmed_worked_hours}`}
                      </b>
                    </TableCell>
                    <TableCell className={css(commonStyles.tableCellSmaller)}>
                      {row.invoicable ? (
                        <IconButton onClick={(): void => openInvoiceModal(row)} color="success">
                          <AddBoxIcon />
                        </IconButton>
                      ) : !row.has_complete_invoice_details ? (
                        <Tooltip title={<b style={{ fontSize: '1.25em' }}>Ofullständiga faktureringuppgifter</b>}>
                          <ErrorIcon color="error" />
                        </Tooltip>
                      ) : (
                        <ErrorIcon color="error" />
                      )}
                    </TableCell>
                    <MenuTableCell>
                      <MenuItem onClick={(): void => openCorrectionModal(row, undefined)}>Hantera</MenuItem>
                    </MenuTableCell>
                  </TableRow>
                  {row.details.map((detail: any, detailIdx) => (
                    <SubRow
                      key={detailIdx}
                      row={detail}
                      index={detailIdx}
                      invoiceAgreement={row}
                      openCorrection={openCorrectionModal}
                    />
                  ))}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>

        <LoadMoreButton
          loadList={getManagementList}
          state={managementList}
          setState={setManagementList}
          params={listParams}
          pagination={pagination}
          setPagination={setPagination}
        />

        <Dialog
          open={invoiceModalState}
          onClose={(): void => setInvoiceModalState(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <InvoiceModal
            callback={invoiceCreated}
            setLoading={setLoading}
            invoiceDetails={invoiceDetailState}
            setModalState={setInvoiceModalState}
          />
        </Dialog>

        <Dialog
          open={correctionModalState}
          onClose={(): void => setCorrectionModalState(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <MakeCorrectionsModal
            team={correctionState.team}
            agreement={correctionState.agreement}
            setModalState={setCorrectionModalState}
            correctionCallback={correctionCallback}
          />
        </Dialog>

        {isNotEmpty(selectedManagement) && (
          <div className={css(commonStyles.selectionFabContainer)}>
            <UpdateFab onClick={(): void => openInvoiceModal(undefined)} text="SKAPA FAKTUROR" />
          </div>
        )}
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Management;
