import React, { useContext, useEffect, useState } from 'react';

import { getJobOpeningNotification } from '../JobOpening/jobOpeningApi';
import { AuthContextValue, NotificationContextValue } from './types';
import { useNotify } from './snackbarHooks';
import AuthContext from './AuthContext';

export const NotificationContext = React.createContext({});

type Props = {
  children: React.ReactNode;
};

export const NotificationProvider: React.FC<Props> = ({ children }) => {
  // hooks
  const [JobOpeningsNotification, setJobOpeningsNotification] = useState<number>(0);
  const { isAuth } = useContext(AuthContext) as AuthContextValue;
  const { notifyError } = useNotify();

  useEffect(() => {
    if (isAuth) {
      getJobOpeningNotification()
        .then(({ data }) => {
          setJobOpeningsNotification(data.count);
        })
        .catch(() => notifyError('Det gick inte att hämta jobbannons underrättelse'));
    }
  }, [isAuth]);

  // functions
  const refreshJobOpeningsNotification = (): void => {
    getJobOpeningNotification()
      .then(({ data }) => {
        setJobOpeningsNotification(data.count);
      })
      .catch(() => notifyError('Det gick inte att hämta jobbannons underrättelse'));
  };

  const defaultContext: NotificationContextValue = {
    JobOpeningsNotification,
    refreshJobOpeningsNotification,
  };

  // render
  return (
    <React.Fragment>
      <NotificationContext.Provider value={defaultContext}>{children}</NotificationContext.Provider>
    </React.Fragment>
  );
};

export default NotificationContext;
