import React, { useEffect, useState, useMemo, useContext, useCallback, useRef } from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { getTeamMinimalList, getTeamSchedulePdf } from '../Team/teamApi';
import { useNotify } from '../Common/snackbarHooks';
import { FileDownloadOutlined, Search } from '@mui/icons-material';
import { FilterAutocompleteBox } from '../Common/FilterPopover';
import { MinimalTeam } from '../Team/types';
import FilterDatePicker from '../Common/FilterPopover/FilterDatePicker';
import dayjs from 'dayjs';
import BouncingDots from '../Common/BouncingDots';
import AuthContext from '../Common/AuthContext';
import { AuthContextValue } from '../Common/types';
import { BackLink } from '../Common/ButtonLinks';
import { decode } from 'base-64';
import { downloadFile } from '../Common/utilities';

const ScheduleOutline: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { profile } = useContext(AuthContext) as AuthContextValue;
  const teamPickerRef = useRef<HTMLDivElement>(null);
  const [teamPickerOpen, setTeamPickerOpen] = useState(false);

  const from = useMemo(() => {
    const from = searchParams.get('from');
    if (!from) return null;
    return decode(from);
  }, [searchParams]);

  const [teams, setTeams] = useState<MinimalTeam[]>([]);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const selectedMonth = useMemo(() => {
    const month = searchParams.get('month');
    if (!month) return null;
    const date = dayjs(month, 'YYYY-MM');
    return date.isValid() ? date : null;
  }, [searchParams]);

  const [loadingPdf, setLoadingPdf] = useState(false);

  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const { teamId } = useParams();
  const team = useMemo(() => {
    if (!teamId || !teams) return undefined;
    return teams.find((team) => team.id === Number(teamId));
  }, [teams, teamId]);

  useEffect(() => {
    setLoadingTeams(true);

    getTeamMinimalList({
      is_active: true,
      filter_regional_manager: profile.role.short_name === 'RC' ? profile.id : undefined,
    })
      .then(({ data }) => setTeams(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'))
      .finally(() => setLoadingTeams(false));
  }, []);

  const focusTeamPicker = useCallback(() => {
    teamPickerRef.current?.focus();
    setTeamPickerOpen(true);
  }, []);

  const downloadPdf = useCallback(() => {
    if (!team) return;

    setLoadingPdf(true);
    getTeamSchedulePdf(team.id)
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        downloadFile(blob, `Schema ${team.name} ${dayjs().startOf('month').format('YYYY-MM-DD')}.pdf`);
      })
      .finally(() => {
        setLoadingPdf(false);
      });
  }, [team]);

  return (
    <div className="min-h-full bg-gray-100">
      <div className="h-16 p-4 flex items-center">
        {from && <BackLink link={from} goBack />}
        <h1 className="text-2xl font-semibold">
          Schema
          {teamId && (
            <>
              {' för '}
              {team ? team.name : <BouncingDots />}
            </>
          )}
        </h1>
        {team && <p className="text-2xl font-semibold text-gray-600 ml-2">(std {team.standard_attendance})</p>}
      </div>
      <div className="bg-white flex justify-between pr-8 h-13 shadow-std">
        <FilterAutocompleteBox
          ref={teamPickerRef}
          loading={loadingTeams}
          options={teams}
          value={team ?? null}
          onChange={(option) => {
            if (option?.id === team?.id) return;
            if (!option) return;
            if (selectedMonth && !team) {
              navigate(`/schedule/${option.id}?month=${selectedMonth.format('YYYY-MM')}`);
            } else {
              navigate(`/schedule/${option.id}`);
            }
          }}
          placeholder="Välj team"
          getOptionLabel={(team) => team.name}
          isOpen={teamPickerOpen}
          setIsOpen={setTeamPickerOpen}
          startAdornment={
            <span className="pr-1">
              <Search />
            </span>
          }
          className="w-80"
        />
        <div className="flex">
          <FilterDatePicker
            placeholder="Välj datum"
            value={selectedMonth}
            onChange={(date) => setSearchParams(date ? `month=${date.format('YYYY-MM')}` : '')}
            views={['month', 'year']}
            actions={['clear']}
            className="w-48"
          />
          <Button
            variant="text"
            className="!rounded-none"
            disabled={!team}
            endIcon={loadingPdf ? <CircularProgress size="20px" /> : <FileDownloadOutlined />}
            onClick={downloadPdf}
          >
            Ladda ner
          </Button>
        </div>
      </div>

      <Outlet context={focusTeamPicker} />
    </div>
  );
};

export default ScheduleOutline;
