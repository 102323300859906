import { css } from 'aphrodite';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { commonStyles } from '../../Common/styling';
import { TableHead, TableRow } from '../../Common/Table';
import { EmployeeAgreementInterface } from './types';
import { useNotify } from '../../Common/snackbarHooks';
import { getEmployeeAgreements } from './EmployeeAgreementAPI';
import { Grid, Table, TableBody, TableCell } from '@mui/material';
import { sort_order, ListParams } from '../../Common/types';
import { isEmpty } from '../../Common/utilities';
import EmptyList from '../../Common/Table/EmptyList';

export const filterSortDefaults = {
  query: '',
  sort_by: 'ranking',
  sort_order: sort_order.asc,
  is_active: true,
};

const EmployeeAgreement: React.FC = () => {
  const rows = [{ id: 'type_of', label: 'Typ', notSortable: true }];
  const navigate = useNavigate();
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [employeeAgreements, setEmployeeAgreements] = useState<Array<EmployeeAgreementInterface>>([]);
  const { notifyError } = useNotify();

  useEffect(() => {
    const fetchEmployeeAgreements = (): void => {
      getEmployeeAgreements()
        .then((data) => {
          setEmployeeAgreements(data.data.employee_agreement_templates);
        })
        .catch(() => notifyError('Det gick inte att hämta listan över avtalsmall'));
    };
    fetchEmployeeAgreements();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Avtalsmall</h1>
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />
          <TableBody>
            {isEmpty(employeeAgreements) ? (
              <EmptyList />
            ) : (
              employeeAgreements.map((row: EmployeeAgreementInterface, idx: number) => (
                <TableRow row={row} idx={idx} key={idx} onClick={(): void => navigate(`${row.id}`)}>
                  <TableCell scope="row">
                    <b>{row.type_of}</b>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default EmployeeAgreement;
