import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { validationSchema, initialValues } from './residenceClusterSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import ResidenceClusterForm from './components/ResidenceClusterForm';
import { BackLink } from '../Common/ButtonLinks';
import { ResidenceClusterInterface } from './types';
import { OptionsInterface } from '../Common/types';

import { getRegionsMinimalList } from '../Region/regionApi';
import { updateResidenceCluster, getResidenceCluster, deleteResidenceCluster } from './residenceClusterApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { getResidenceClustersMinimalList } from './residenceClusterApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import { getDefaultsWithActualValues } from '../Common/utilities';

const EditResidenceCluster: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notifyError, notifySuccess } = useNotify();
  const [residenceClusterState, setResidenceClusterState] = useState<ResidenceClusterInterface>();
  const [loadingResidenceCluster, setLoadingResidenceCluster] = useState(true);
  const [loadingResidenceClustersMinimalList, setLoadingResidenceClustersMinimalList] = useState(true);
  const [loadingResidenceGroupsMinimalList, setLoadingResidenceGroupsMinimalList] = useState(true);
  const [loadingRegionsMinimalList, setLoadingRegionsMinimalList] = useState(true);
  const [loadingResidenceMinimalList, setLoadingResidenceMinimalList] = useState(true);
  const [residenceClustersMinimalList, setResidenceClustersMinimalList] = useState([]);
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [regionsMinimalList, setRegionsMinimalList] = useState([]);

  useEffect(() => {
    setLoadingResidenceCluster(true);
    setLoadingResidenceClustersMinimalList(true);
    setLoadingResidenceGroupsMinimalList(true);
    setLoadingRegionsMinimalList(true);
    setLoadingResidenceMinimalList(true);

    getResidenceCluster(id)
      .then(({ data }) => setResidenceClusterState(data.data))
      .catch(() => notifyError('Det gick inte att hämta gruppen'))
      .finally(() => setLoadingResidenceCluster(false));

    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClustersMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'))
      .finally(() => setLoadingResidenceClustersMinimalList(false));

    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart'))
      .finally(() => setLoadingResidenceGroupsMinimalList(false));

    getRegionsMinimalList()
      .then(({ data }) => setRegionsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'))
      .finally(() => setLoadingRegionsMinimalList(false));

    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'))
      .finally(() => setLoadingResidenceMinimalList(false));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (values: ResidenceClusterInterface, actions: FormikHelpers<any>): void => {
    handleUpdateResidenceCluster(values, actions.setSubmitting);
  };

  const handleUpdateResidenceCluster = ({ residences, ...values }: typeof initialValues, setSubmitting: any): void => {
    if (!residenceClusterState) return;

    updateResidenceCluster(residenceClusterState.id, {
      ...values,
      residence_ids: residences.map((r) => r.id as number),
    })
      .then(() => notifySuccess('Gruppen är uppdaterad'))
      .then(() => navigate(`/residence-clusters`))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera gruppen: ${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  const handleDeleteResidenceCluster = (): void => {
    if (residenceClusterState?.id) {
      deleteResidenceCluster(residenceClusterState.id)
        .then(() => notifySuccess('Gruppen har tagits bort'))
        .then(() => navigate(`/residence-clusters`))
        .catch((error: any) => notifyError(`Det gick inte att ta bort gruppen: ${HandleError(error.response.data)}`));
    }
  };

  // render
  return (
    <React.Fragment>
      {(loadingResidenceCluster ||
        loadingResidenceClustersMinimalList ||
        loadingResidenceGroupsMinimalList ||
        loadingRegionsMinimalList ||
        loadingResidenceMinimalList) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader
            loading={
              loadingResidenceCluster ||
              loadingResidenceClustersMinimalList ||
              loadingResidenceGroupsMinimalList ||
              loadingRegionsMinimalList ||
              loadingResidenceMinimalList
            }
          />
        </div>
      )}
      {!loadingResidenceCluster &&
        !loadingResidenceClustersMinimalList &&
        !loadingResidenceGroupsMinimalList &&
        !loadingRegionsMinimalList &&
        !loadingResidenceMinimalList &&
        residenceClusterState && (
          <div>
            <div className={css(commonStyles.editViewHeader)}>
              <BackLink link={`/residence-clusters`} />

              <h1 className={css(commonStyles.headerTextStyle)}>Redigera gruppen</h1>
            </div>

            <div className={css(commonStyles.formContainer)}>
              <Formik
                enableReinitialize
                validateOnMount={true}
                initialValues={getDefaultsWithActualValues(initialValues, residenceClusterState)}
                validationSchema={validationSchema}
                onSubmit={(values, actions): void => {
                  handleFormSubmit(values, actions);
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  isValid,
                }): ReactElement => (
                  <form onSubmit={handleSubmit}>
                    <ResidenceClusterForm
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      isCreate={false}
                      setFieldValue={setFieldValue}
                      submitDisabled={isSubmitting || !isValid}
                      residenceMinimalList={residenceMinimalList}
                      residenceClustersMinimalList={residenceClustersMinimalList}
                      residenceGroupsMinimalList={residenceGroupsMinimalList}
                      regionsMinimalList={regionsMinimalList}
                      removal={handleDeleteResidenceCluster}
                    />
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default EditResidenceCluster;
