import React from 'react';

import { FormDateField, FormTextField, FormCheckbox } from '../../Common/FormItems';
import { commonStyles, css } from '../../Common/styling';
import { Divider, Grid } from '@mui/material';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
};

export const TeamOnEditAgreement: React.FC<Props> = (props) => {
  return (
    <div>
      {props.values.team_agreements && (
        <div className={css(commonStyles.greyRow)} style={{ paddingRight: '5px' }}>
          {props.values.team_agreements
            .filter((team_agreement: any) => team_agreement.team.is_active)
            .map((team: any, teamIndex: number) => (
              <div key={teamIndex}>
                <Divider />
                <Grid container alignItems="center" spacing={2} style={{ marginBottom: '0px', padding: '0 15px' }}>
                  <FormCheckbox
                    size={1}
                    fieldName={`team_agreements.${teamIndex}.checked`}
                    label=""
                    values={props.values}
                    onChange={props.handleChange}
                    disabled={!!team.id}
                  />

                  <Grid item xs={3} container alignItems="center">
                    <div style={{ paddingLeft: '10px' }}>
                      <b>Team: </b>
                      {team.team.name}
                    </div>
                  </Grid>

                  <FormDateField
                    size={2}
                    fieldName={`team_agreements.${teamIndex}.start_date`}
                    label="Från"
                    placeholder=""
                    values={props.values}
                    errors={props.errors}
                    onChange={props.setFieldValue}
                  />

                  <FormDateField
                    size={2}
                    fieldName={`team_agreements.${teamIndex}.end_date`}
                    label="Till"
                    placeholder=""
                    values={props.values}
                    errors={props.errors}
                    onChange={props.setFieldValue}
                  />

                  <FormTextField
                    size={2}
                    disabled={!!team.id}
                    fieldName={`team_agreements.${teamIndex}.budgeted_hours`}
                    label="Timpott (tim)"
                    placeholder=""
                    values={props.values}
                    errors={props.errors}
                    onChange={props.handleChange}
                  />
                  <FormTextField
                    disabled={team.startup_fee_is_payed}
                    size={2}
                    fieldName={`team_agreements.${teamIndex}.startup_fee`}
                    label="Uppstart (kr)"
                    placeholder=""
                    values={props.values}
                    errors={props.errors}
                    onChange={props.handleChange}
                  />
                </Grid>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
