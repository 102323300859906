import React, { useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import { StyleSheet, css } from '../styling';
import { ListParams } from '../../Common/types';
import { paginationDefaults } from '../commonSchema';

const styles = StyleSheet.create({
  searchField: {
    marginLeft: '10px',
    width: '100%',
    border: 'none',
    fontSize: '16px',
    outline: 'none',
  },
});

type Props = {
  setGetListParams: (p: ListParams) => void;
  listParams: ListParams;
  setAllowSearch?: (p: boolean) => void;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
  placeholder?: string;
};

const SearchBar: React.FC<Props> = (props) => {
  useEffect(() => {
    if (props.listParams.query === '') {
      if (props.setAllowSearch) {
        props.setAllowSearch(true);
      }
    }
  }, [props.listParams.query]);

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (props.setAllowSearch) {
        props.setAllowSearch(true);
      }
      if (props.setPagination) {
        props.setPagination({
          page: 1,
          page_size: props.paginationDefaults ? props.paginationDefaults.page_size : paginationDefaults.page_size,
        });
      }
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <SearchIcon />

      <input
        placeholder={props.placeholder ? props.placeholder : 'Sök'}
        className={css(styles.searchField)}
        value={props.listParams.query}
        onKeyDown={handleKeyDown}
        onChange={(e): void => {
          props.setGetListParams({ ...props.listParams, query: e.target.value });
          if (props.setAllowSearch) {
            props.setAllowSearch(false);
          }
        }}
      />
    </div>
  );
};

export default SearchBar;
