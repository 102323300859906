import { AxiosResponse } from 'axios';

import { ListParams, DateRangeInterface } from '../Common/types';
import { AgreementInterface, BatchUpdateInterface } from './types';
import API from '../Common/axios';

export const getAgreementList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/agreements', { params });
};

export const createAgreement = (agreements: any[]): Promise<AxiosResponse> => {
  return API.post('v1/agreements/batch_create', { agreements });
};

export const updateAgreement = (id: string | undefined, agreement: AgreementInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/agreements/${id}`, { agreement });
};

export const batchUpdateAgreements = (ids: number[], agreement: BatchUpdateInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/agreements/batch_update`, { agreement_ids: ids, agreement: agreement }, { timeout: 30000 });
};

export const getAgreement = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/agreements/${id}`);
};

export const exportAgreementOverview = (params: DateRangeInterface): Promise<AxiosResponse> => {
  return API.get(`v1/agreements/book_closing_data_dump`, { params, timeout: 30000 });
};

export const deleteAgreement = (id: string | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/agreements/${id}`);
};

export const batchDeleteAgreements = (agreement_ids: number[]): Promise<AxiosResponse> => {
  return API.delete(`v1/agreements/batch_destroy`, { params: { agreement_ids } });
};

export const getDeletableAgreements = (agreement_ids: number[]): Promise<AxiosResponse> => {
  return API.get(`v1/agreements/deletable`, { params: { agreement_ids } });
};

export const getTeamAgreement = (id: number) => {
  return API.get(`v1/team_agreements/${id}`);
};
