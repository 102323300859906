import React, { useEffect, useState } from 'react';

import { Button, Dialog, Divider, FormControl, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { FormDateField, FormSelect, FormTextFieldBlur, FormToggle } from '../../Common/FormItems';
import { getValue, isEmpty, isNotEmpty } from '../../Common/utilities';
import { getResidenceTeamsList } from '../../Residence/residenceApi';
import { getResidenceGroupsMinimalList } from '../../ResidenceGroup/residenceGroupApi';
import { AddPricePeriods } from './AddPricePeriods';
import { AddTeamsToAgreementModal } from './AddTeamsToAgreementModal';
import { ResidenceWithTeamsCreate } from './ResidenceWithTeamsCreate';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  submitDisabled: boolean;
};

export const CreateAgreementForm: React.FC<Props> = (props) => {
  // hooks
  const [modalState, setModalState] = useState(false);
  const [residenceMinimalList, setResidenceMinimalList] = useState([]);
  const [residenceGroupMinimalList, setResidenceGroupMinimalList] = useState([]);

  const isValidDate = (date: Date): boolean => {
    const dateTime = date.getTime(); // getTime() returns NaN if the date is invalid
    return typeof dateTime === 'number' && !isNaN(dateTime);
  };

  useEffect(() => {
    getResidenceTeamsList({ is_active: true, residence_group_id: [props.values.residence_group_id] }).then(
      ({ data }) => {
        props.setFieldValue('residences', []);
        const residences = data.data.map((residence: any) => {
          let teams = [];
          if (residence.teams) {
            const active_teams = residence.teams.filter((team: any) => team.is_active === true);
            teams = active_teams.map((team: any) => {
              return {
                team_id: team.id,
                name: team.name,
                start_date: props.values.start_date,
                end_date: props.values.end_date,
                budgeted_hours: props.values.budgeted_hours,
                startup_fee: props.values.startup_fee,
                checked: true,
              };
            });
          }
          return {
            ...residence,
            teams: teams,
            start_date: props.values.start_date,
            end_date: props.values.end_date,
            budgeted_hours: props.values.budgeted_hours,
            startup_fee: props.values.startup_fee,
          };
        });
        setResidenceMinimalList(residences);
      }
    );
  }, [props.values.residence_group_id]); // eslint-disable-line

  useEffect(() => {
    if (props.values.residences) {
      const end_date = new Date(props.values.start_date);

      if (isValidDate(end_date)) {
        const residences = props.values.residences.map((residence: any) => {
          let teams = [];
          if (residence.teams) {
            teams = residence.teams.map((team: any) => {
              return { ...team, start_date: props.values.start_date };
            });
          }
          return { ...residence, teams: teams, start_date: props.values.start_date };
        });
        const price_periods = props.values.price_periods.map((price_period: any) => {
          return { ...price_period, start_date: props.values.start_date };
        });

        end_date.setMonth(end_date.getMonth() + 12);
        end_date.setHours(-1);
        props.setFieldValue('residences', residences);
        props.setFieldValue('price_periods', price_periods);
        props.setFieldValue('end_date', dayjs(end_date).format('YYYY-MM-DD'));
      }
    }
  }, [props.values.start_date]); // eslint-disable-line

  useEffect(() => {
    if (props.values.residences) {
      const residences = props.values.residences.map((residence: any) => {
        let teams = [];
        if (residence.teams) {
          teams = residence.teams.map((team: any) => {
            return { ...team, end_date: props.values.end_date };
          });
        }
        return { ...residence, teams: teams, end_date: props.values.end_date };
      });
      const price_periods = props.values.price_periods.map((price_period: any) => {
        return { ...price_period, end_date: props.values.end_date };
      });
      props.setFieldValue('residences', residences);
      props.setFieldValue('price_periods', price_periods);
    }
  }, [props.values.end_date]); // eslint-disable-line

  useEffect(() => {
    if (props.values.residences) {
      const residences = props.values.residences.map((residence: any) => {
        let teams = [];
        if (residence.teams.length > 0) {
          teams = residence.teams.map((team: any) => {
            return { ...team, budgeted_hours: props.values.budgeted_hours };
          });
        }
        return { ...residence, teams: teams, budgeted_hours: props.values.budgeted_hours };
      });
      props.setFieldValue('residences', residences);
    }
  }, [props.values.budgeted_hours]); // eslint-disable-line

  useEffect(() => {
    if (props.values.residences) {
      const residences = props.values.residences.map((residence: any) => {
        let teams = [];
        if (residence.teams) {
          teams = residence.teams.map((team: any) => {
            return { ...team, startup_fee: props.values.startup_fee };
          });
        }
        return { ...residence, teams: teams, startup_fee: props.values.startup_fee };
      });
      props.setFieldValue('residences', residences);
    }
  }, [props.values.startup_fee]); // eslint-disable-line

  useEffect(() => {
    if (props.values.type === 'ContinuousAgreement') {
      props.setFieldValue('invoicing_routine', '1');
    }
  }, [props.values.type]); // eslint-disable-line

  useEffect(() => {
    getResidenceGroupsMinimalList({ is_active: true }).then(({ data }) => {
      setResidenceGroupMinimalList(data.data);
    });
  }, []);

  // render
  const invoicing_routines = [
    { id: '-1', name: 'En månad före' },
    { id: '0', name: 'Samma månad' },
    { id: '1', name: 'En månad efter' },
  ];

  const isTeamSelected = props.values.residences.some((residence: any) =>
    residence.teams.some((team: any) => team.checked)
  );

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormSelect
          size={3}
          fieldName="residence_group_id"
          label="Välj avtalspart för boendet"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          options={residenceGroupMinimalList}
        />

        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          {isEmpty(props.values.residences) ? (
            <p>Inga boenden tillagda</p>
          ) : (
            <p>{props.values.residences.length} boende tillagda</p>
          )}
        </Grid>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Button
              disabled={isEmpty(residenceMinimalList)}
              color="primary"
              variant="contained"
              onClick={(): void => setModalState(true)}
            >
              {isEmpty(props.values.residences) ? <span>Lägg till</span> : <span>Ändra</span>}
            </Button>
          </div>
          <Dialog open={modalState} onClose={(): void => setModalState(false)} fullWidth={true} maxWidth="sm">
            <AddTeamsToAgreementModal
              options={residenceMinimalList}
              values={props.values}
              handleChange={props.handleChange}
              setFieldValue={props.setFieldValue}
              setModalState={setModalState}
            />
          </Dialog>
        </div>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormSelect
          size={3}
          fieldName="type"
          label="Välj faktureringsrutin"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          options={[
            { id: 'ContinuousAgreement', name: 'Löpande' },
            { id: 'FixedAgreement', name: 'Anpassad' },
          ]}
        />

        <FormSelect
          size={3}
          fieldName="invoicing_routine"
          label="Välj faktureringstid"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          options={invoicing_routines}
          disabled={getValue(props.values, 'type') === 'ContinuousAgreement'}
        />
      </Grid>
      {isEmpty(props.values.residences) ? (
        <Grid container spacing={2} style={{ marginBottom: '20px' }} alignItems="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            Du måste välja avtalspart sedan boende innan du kan skapa ett avtal
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <FormDateField
              size={3}
              fieldName="start_date"
              label="Startdatum"
              placeholder="Startdatum"
              values={props.values}
              errors={props.errors}
              onChange={props.setFieldValue}
            />
            <FormDateField
              size={3}
              fieldName="end_date"
              label="Slutdatum"
              placeholder="Slutdatum"
              values={props.values}
              errors={props.errors}
              onChange={props.setFieldValue}
            />
            <FormTextFieldBlur
              size={3}
              fieldName="budgeted_hours"
              label="Timpott"
              placeholder="Timpott"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
            <FormTextFieldBlur
              size={3}
              fieldName="startup_fee"
              label="Uppstartskostnad"
              placeholder="Uppstartskostnad"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>
          <AddPricePeriods
            values={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            setFieldValue={props.setFieldValue}
          />

          <div>
            <ResidenceWithTeamsCreate
              values={props.values}
              errors={props.errors}
              handleChange={props.handleChange}
              setFieldValue={props.setFieldValue}
            />
          </div>
        </React.Fragment>
      )}

      <Divider className="!mb-5" />

      {isNotEmpty(props.values.residences) && (
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <FormToggle
            size={2}
            fieldName="is_active"
            label="Avtalet är aktivt"
            values={props.values}
            onChange={props.handleChange}
          />
          <Grid item xs={3} />
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isTeamSelected || props.submitDisabled}
              >
                Lägg till
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
