import React, { useState, ReactElement, useRef, MouseEvent } from 'react';

import {
  FormControl,
  Grid,
  Button,
  Divider,
  IconButton,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
  MenuList,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

import { FormTextField, FormToggle, FormSelect, FormHourMinutePicker, FormDateField } from '../../Common/FormItems';
import { TeamInterface } from '../types';
import commonStyles from '../../Common/styling/commonStyles';
import { css, theme } from '../../Common/styling';
import ConfirmDeletePersonModal from './ConfirmDeletePersonModal';

import { FieldArray, FieldArrayRenderProps } from 'formik';
import { OptionsInterface, PersonInterface } from '../../Common/types';
import { Business, Launch } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { authenticateResidencePage } from '../../ResidencePage/residencePageApi';
import { baseURL } from '../../Common/utilities';
import { useNotify } from '../../Common/snackbarHooks';
import { setResidenceLoginCookie } from '../../ResidencePage/residenceLoginCookie';

interface Props {
  team?: TeamInterface;
  values: TeamInterface;
  initialValues: TeamInterface;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  submitDisabled: boolean;
  residenceMinimalList?: OptionsInterface[];
  handleDelete: any;
  isCreate: boolean;
}

const TeamForm: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const openResidencePageRef = useRef<HTMLButtonElement>(null);
  const [residencePagePopoverOpen, setResidencePagePopoverOpen] = useState(false);
  const [vaLink] = useState(`/users/${props.values.operational_manager?.id}`);
  const [rcLink] = useState(`/users/${props.values.regional_manager?.id}`);
  const [residenceLink] = useState(`/residences/${props.values.residence?.id}`);
  const [residenceAddress] = useState(
    props.values.residence?.visiting_address ? props.values.residence.visiting_address : ''
  );
  const [residenceCity] = useState(props.values.residence?.city ? ', ' + props.values.residence.city : '');
  const [residenceZipcode] = useState(props.values.residence?.zip_code ? ', ' + props.values.residence.zip_code : '');
  const [selectedPerson, setSelectedPerson] = useState<PersonInterface>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showDeletePersonModal, setShowDeletePersonModal] = useState<boolean>(false);

  const emptyAmbassador = {
    name: '',
    email: '',
    mobile: '',
    person_type: 'ambassador',
  };

  const emptyResidenceManager = {
    name: '',
    email: '',
    mobile: '',
    person_type: 'residence_manager',
  };

  async function residenceSignIn(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!props.values.residence?.residence_password || !baseURL) return;
    console.log('base', baseURL.hostname);
    await authenticateResidencePage(props.values.residence.residence_password)
      .then(({ data }) => {
        setResidenceLoginCookie(data.auth_token);
        window.open(process.env.REACT_APP_RESIDENCE_URL!, '_blank');
      })
      .catch(() => {
        notifyError('Något gick fel; kunde inte logga in');
      });
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center" alignContent="space-between">
        {props.isCreate ? (
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <FormSelect
              size={10}
              fieldName="residence_id"
              label="Välj boende för teamet"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
              options={props.residenceMinimalList}
            />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid
              container
              xs={4}
              sm={4}
              md={4}
              lg={4}
              alignItems="center"
              alignContent="space-between"
              className={css(commonStyles.text4)}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingBottom: '10px', paddingTop: '20px', paddingLeft: '15px' }}
              >
                <b>Boende: </b>
                <a className={css(commonStyles.greenLink)} href={residenceLink}>
                  {props.values.residence?.name ? props.values.residence?.name : ''}
                </a>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingLeft: '15px' }}>
                <b>Address: </b>
                {`${residenceAddress}${residenceZipcode}${residenceCity}`.trim()}
              </Grid>
            </Grid>
            <Grid
              container
              xs={4}
              sm={4}
              md={4}
              lg={4}
              alignItems="center"
              alignContent="space-between"
              className={css(commonStyles.text4)}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingBottom: '10px', paddingTop: '20px', paddingLeft: '15px' }}
              >
                <div className="flex items-center gap-2">
                  <b>Boendeinlogg:</b>
                  <p>{props.values.residence?.residence_password}</p>
                  {process.env.REACT_APP_RESIDENCE_URL && (
                    <>
                      <IconButton
                        ref={openResidencePageRef}
                        size="small"
                        onClick={() => setResidencePagePopoverOpen(true)}
                      >
                        <Launch color="primary" fontSize="inherit" />
                      </IconButton>
                      <Popover
                        anchorEl={openResidencePageRef.current}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={residencePagePopoverOpen}
                        onClose={() => setResidencePagePopoverOpen(false)}
                      >
                        <MenuList>
                          <Link className="text-inherit" to={process.env.REACT_APP_RESIDENCE_URL}>
                            <MenuItem onClick={residenceSignIn}>
                              <ListItemIcon>
                                <Business fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Öppna boendesida</ListItemText>
                            </MenuItem>
                          </Link>
                        </MenuList>
                      </Popover>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingLeft: '15px' }}>
                <b>Kommunspärr: </b>
                {props.values.residence?.only_for_locals ? 'ja' : 'nej'}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {!props.isCreate && (
          <>
            <Grid
              container
              xs={4}
              sm={4}
              md={4}
              lg={4}
              alignItems="center"
              alignContent="space-between"
              className={css(commonStyles.text4)}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingBottom: '10px', paddingTop: '20px', paddingLeft: '20px' }}
              >
                <b>VA: </b>
                <a className={css(commonStyles.greenLink)} href={vaLink}>
                  {props.values.operational_manager?.name ? props.values.operational_manager?.name : ''}
                </a>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingLeft: '20px' }}>
                <b>RC: </b>
                <a className={css(commonStyles.greenLink)} href={rcLink}>
                  {props.values.regional_manager?.name ? props.values.regional_manager?.name : ''}
                </a>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              alignItems="center"
              alignContent="space-between"
              className={css(commonStyles.text4)}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingBottom: '0px', paddingTop: '30px', paddingLeft: '15px' }}
              >
                Äldreboendet kan använda sitt boendeinlogg för att logga in på{' '}
                <a className={css(commonStyles.greenLink)} target="_bank" href="http://boende.ungomsorg.se">
                  http://boende.ungomsorg.se
                </a>{' '}
                där de kan se inrapporterade pass och kontaktuppgifter m.m.
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Divider style={{ marginBottom: '20px', marginTop: '40px' }} />
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <FormTextField
            size={12}
            required={true}
            fieldName="name"
            label="Namn på teamet"
            placeholder="Namn"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <FormHourMinutePicker
            fieldName="standard_workday"
            label="Standardpasslängd (tim)"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <FormTextField
            size={12}
            fieldName="standard_attendance"
            label="Standardnärvaro (antal inkl. TL)"
            placeholder="Standardnärvaro (antal inkl. TL)"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: '20px' }} />
      <FieldArray
        name="people_attributes"
        render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
          <React.Fragment>
            {props.values.people_attributes &&
            props.values.people_attributes.filter(
              (person: PersonInterface) => person.person_type === 'ambassador' && person._destroy !== 1
            ).length > 0 ? (
              props.values.people_attributes
                .map((person: PersonInterface, index: number) => ({ person, index }))
                .filter(({ person }) => person.person_type === 'ambassador' && person._destroy !== 1)
                .map(({ person, index }) => (
                  <React.Fragment key={index}>
                    <Grid container spacing={2} alignItems="center" alignContent="space-between">
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.name`}
                          label="Ambassadör namn"
                          placeholder="Ambassadör namn"
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.email`}
                          label="Ambassadör e-post"
                          placeholder="Ambassadör e-post"
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.mobile`}
                          label="Ambassadör telefon"
                          placeholder="Ambassadör telefon"
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item style={{ display: 'none' }}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.person_type`}
                          label=""
                          placeholder=""
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button
                          variant="text"
                          color="error"
                          style={{ marginBottom: '15px' }}
                          onClick={(): void => {
                            setSelectedPerson(person);
                            setSelectedIndex(index);
                            setShowDeletePersonModal(true);
                          }}
                        >
                          <ClearIcon className={css(commonStyles.deleteIcon)} />
                        </Button>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Button
                          variant="outlined"
                          style={{ color: theme.themeGreen, borderColor: theme.themeGreen, marginBottom: '15px' }}
                          onClick={(): void => arrayHelpers.push(emptyAmbassador)}
                        >
                          <AddIcon style={{ color: theme.themeGreen, marginTop: '1px', marginBottom: '1px' }} />
                          LÄGG TILL
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button
                      variant="outlined"
                      className="!my-5"
                      onClick={(): void => arrayHelpers.push(emptyAmbassador)}
                    >
                      <AddIcon style={{ color: theme.themeGreen, marginRight: '10px' }} />
                      LÄGG TILL UO-AMBASSADÖR
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      />
      {props.values.people_attributes &&
        props.values.people_attributes.filter(
          (person: PersonInterface) => person.person_type === 'ambassador' && person._destroy !== 1
        ).length > 0 && <Divider style={{ marginBottom: '20px' }} />}
      <FieldArray
        name="people_attributes"
        render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
          <React.Fragment>
            {props.values.people_attributes &&
            props.values.people_attributes.filter(
              (person: PersonInterface) => person.person_type === 'residence_manager' && person._destroy !== 1
            ).length > 0 ? (
              props.values.people_attributes
                .map((person: PersonInterface, index: number) => ({ person, index }))
                .filter(({ person }) => person.person_type === 'residence_manager' && person._destroy !== 1)
                .map(({ person, index }) => (
                  <React.Fragment key={index}>
                    <Grid container spacing={2} alignItems="center" alignContent="space-between">
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.name`}
                          label="Verksamhetschef namn"
                          placeholder="Verksamhetschef namn"
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.email`}
                          label="Verksamhetschef e-post"
                          placeholder="Verksamhetschef e-post"
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.mobile`}
                          label="Verksamhetschef telefon"
                          placeholder="Verksamhetschef telefon"
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item style={{ display: 'none' }}>
                        <FormTextField
                          size={12}
                          fieldName={`people_attributes.${index}.person_type`}
                          label=""
                          placeholder=""
                          values={props.values}
                          errors={props.errors}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button
                          variant="text"
                          color="error"
                          style={{ marginBottom: '15px' }}
                          onClick={(): void => {
                            setSelectedPerson(person);
                            setSelectedIndex(index);
                            setShowDeletePersonModal(true);
                          }}
                        >
                          <ClearIcon className={css(commonStyles.deleteIcon)} />
                        </Button>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Button
                          variant="outlined"
                          style={{ color: theme.themeGreen, borderColor: theme.themeGreen, marginBottom: '15px' }}
                          onClick={(): void => arrayHelpers.push(emptyResidenceManager)}
                        >
                          <AddIcon style={{ color: theme.themeGreen, marginTop: '1px', marginBottom: '1px' }} />
                          LÄGG TILL
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button
                      variant="outlined"
                      className="!my-5"
                      onClick={(): void => arrayHelpers.push(emptyResidenceManager)}
                    >
                      <AddIcon style={{ color: theme.themeGreen, marginRight: '10px' }} />
                      LÄGG TILL VERKSAMHETSCHEF
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      />
      {props.values.people_attributes &&
        props.values.people_attributes.filter(
          (person: PersonInterface) => person.person_type === 'residence_manager' && person._destroy !== 1
        ).length > 0 && <Divider style={{ marginBottom: '20px' }} />}

      <Grid container spacing={2} alignItems="center" alignContent="space-between">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormTextField
            size={12}
            fieldName="notes"
            label="Här kan du skriva info till teamet"
            multiline
            rows={5}
            placeholder="Anteckning till teamet"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container spacing={2} alignItems="center" alignContent="space-between">
        {!props.isCreate && !props.values.is_active && !props.initialValues.is_active && (
          <FormDateField
            size={3}
            disabled
            fieldName="updated_at"
            label="Avaktiveringstid"
            placeholder="Avaktiveringstid"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        )}
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ paddingBottom: '50px', marginTop: '8px' }}>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <FormToggle
            size={12}
            fieldName="is_active"
            label="Teamet är aktivt"
            values={props.values}
            onChange={props.handleChange}
          />
        </Grid>

        {(props.isCreate || props.values.editable) && (
          <Grid item xs={2}>
            <Button fullWidth type="submit" color="primary" variant="contained" disabled={props.submitDisabled}>
              {props.isCreate ? 'Lägg till' : 'Spara'}
            </Button>
          </Grid>
        )}
        {props.values.deletable && (
          <React.Fragment>
            <Grid item xs={3} />
            <Grid item xs={2}>
              <FormControl margin="dense" required fullWidth>
                <Button onClick={props.handleDelete} variant="outlined" color="error">
                  Ta bort
                </Button>
              </FormControl>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {showDeletePersonModal && (
        <ConfirmDeletePersonModal
          onConfirm={(): void => {
            if (selectedPerson) {
              // If it's an existing person (has an id), we mark it for destruction
              if (selectedPerson.id) {
                props.setFieldValue(`people_attributes[${selectedIndex}]._destroy`, 1);
              } else {
                // Otherwise, we remove it from the array
                if (props.values.people_attributes) {
                  const newPeople = [...props.values.people_attributes];
                  newPeople.splice(selectedIndex, 1);
                  props.setFieldValue('people_attributes', newPeople);
                }
              }
            }
            setShowDeletePersonModal(false);
          }}
          onClose={(): void => {
            setShowDeletePersonModal(false);
          }}
          isVisible={showDeletePersonModal}
          person={selectedPerson}
        />
      )}
    </React.Fragment>
  );
};

export default TeamForm;
