import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getMinimalMonths } from './scheduleApi';
import { MinimalMonth } from '../Common/types';
import Month from './components/Month';
import dayjs from 'dayjs';
import { Button, CircularProgress } from '@mui/material';
import { CreateFab } from '../Common/ButtonLinks';
import { lastLocationState, stringifySearchParams } from '../Common/utilities';

const MONTHS_PER_EXTRA_REQUEST = 3;

const TeamSchedule: React.FC = () => {
  const { teamId } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMonth = dayjs().format('YYYY-MM');
  const focusMonth = searchParams.get('month')?.match(/^\d{4}-(0[1-9]|1[0-2])$/)
    ? searchParams.get('month')
    : currentMonth;

  const [minimalMonths, setMinimalMonths] = useState<MinimalMonth[]>();
  const [loadingMinimalMonths, setLoadingMinimalMonths] = useState(true);
  const [loadingEarlierMonths, setLoadingEarlierMonths] = useState(false);
  const [loadingLaterMonths, setLoadingLaterMonths] = useState(false);

  useEffect(() => {
    setLoadingMinimalMonths(true);
    const date = dayjs(focusMonth, 'YYYY-MM');
    let from_month = date.subtract(1, 'month').format('YYYY-MM');
    let to_month = date.add(3, 'months').format('YYYY-MM');
    const firstMonth = searchParams.get('firstMonth');
    const lastMonth = searchParams.get('lastMonth');
    if (firstMonth && lastMonth) {
      from_month = firstMonth;
      to_month = lastMonth;
    }
    getMinimalMonths(teamId, focusMonth ? { from_month, to_month } : undefined)
      .then(({ data }) => setMinimalMonths(data.data))
      .finally(() => setLoadingMinimalMonths(false));
  }, [teamId, focusMonth]);

  function getEarlierMonths() {
    if (loadingEarlierMonths) return;
    const firstMonth = dayjs(minimalMonths?.[0].month);
    if (!firstMonth.isValid()) return;
    setLoadingEarlierMonths(true);

    getMinimalMonths(teamId, {
      from_month: firstMonth.subtract(MONTHS_PER_EXTRA_REQUEST, 'months').format('YYYY-MM'),
      to_month: firstMonth.subtract(1, 'month').format('YYYY-MM'),
    })
      .then(({ data }) => {
        setSearchParams(
          stringifySearchParams({
            month: searchParams.get('month') ?? undefined,
            firstMonth: data.data[0].month,
            lastMonth: minimalMonths?.[minimalMonths.length - 1].month,
          })
        );
        setMinimalMonths(minimalMonths && [...data.data, ...minimalMonths]);
      })
      .finally(() => setLoadingEarlierMonths(false));
  }

  function getLaterMonths() {
    if (loadingLaterMonths) return;
    const lastMonth = dayjs(minimalMonths?.[minimalMonths.length - 1].month);
    if (!lastMonth.isValid()) return;
    setLoadingLaterMonths(true);
    getMinimalMonths(teamId, {
      from_month: lastMonth.add(1, 'month').format('YYYY-MM'),
      to_month: lastMonth.add(MONTHS_PER_EXTRA_REQUEST, 'months').format('YYYY-MM'),
    })
      .then(({ data }) => {
        setSearchParams(
          stringifySearchParams({
            month: searchParams.get('month') ?? undefined,
            firstMonth: minimalMonths?.[0].month,
            lastMonth: data.data[data.data.length - 1].month,
          })
        );
        setMinimalMonths(minimalMonths && [...minimalMonths, ...data.data]);
      })
      .finally(() => setLoadingLaterMonths(false));
  }

  return (
    <div className="min-h-[calc(100vh-8rem)]">
      <div className="py-4">
        {loadingMinimalMonths ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex flex-col w-full gap-4">
            <Button
              className="self-center"
              disabled={loadingEarlierMonths}
              startIcon={loadingEarlierMonths && <CircularProgress color="inherit" size={22} />}
              onClick={getEarlierMonths}
            >
              Ladda tidigare månader
            </Button>
            {minimalMonths?.map((month) => (
              <Month
                key={month.month}
                minimalMonth={month}
                teamId={teamId!}
                defaultExpanded={month.month === focusMonth}
              />
            ))}
            <Button
              className="self-center"
              disabled={loadingLaterMonths}
              startIcon={loadingLaterMonths && <CircularProgress color="inherit" size={22} />}
              onClick={getLaterMonths}
            >
              Ladda senare månader
            </Button>
          </div>
        )}
      </div>
      <CreateFab link={`/teams/${teamId}/work-shifts/create`} state={lastLocationState(location)} />
    </div>
  );
};

export default TeamSchedule;
