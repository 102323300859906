import { FieldArray, FieldArrayRenderProps } from 'formik';
import React, { ReactElement } from 'react';

import { Add as AddIcon, Clear as ClearIcon } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { FormDateField, FormTextField } from '../../Common/FormItems';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  isEdit?: boolean;
  setFieldValue: any;
};

export const AddPricePeriods: React.FC<Props> = (props) => {
  const emptyPricePeriod = {
    start_date: new Date().toISOString().split('T')[0],
    end_date: new Date().toISOString().split('T')[0],
    hourly_rate: 0,
  };

  return (
    <FieldArray
      name="price_periods"
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
        <div>
          {props.values.price_periods?.map((row: any, index: number) => (
            <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }} key={index}>
              <FormDateField
                size={3}
                fieldName={`price_periods.${index}.start_date`}
                label="Start prisperiod"
                placeholder="Startdatum för prisperioden"
                values={props.values}
                errors={props.errors}
                onChange={props.setFieldValue}
              />
              <FormDateField
                size={3}
                fieldName={`price_periods.${index}.end_date`}
                label="Slut prisperiod"
                placeholder="Slutdatum för prisperioden"
                values={props.values}
                errors={props.errors}
                onChange={props.setFieldValue}
              />

              <FormTextField
                size={props.isEdit ? 2 : 3}
                fieldName={`price_periods.${index}.hourly_rate`}
                label="Timpris"
                placeholder="Timpriset"
                values={props.values}
                errors={props.errors}
                onChange={props.handleChange}
              />

              {index > 0 && (
                <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton color="secondary" size="small" onClick={(): void => arrayHelpers.remove(index)}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
          {props.values.is_active && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                color="secondary"
                style={{ marginBottom: '20px' }}
                onClick={(): void => arrayHelpers.push(emptyPricePeriod)}
              >
                <AddIcon style={{ marginRight: '10px' }} />
                LÄGG TILL PRISPERIOD
              </Button>
            </div>
          )}
        </div>
      )}
    />
  );
};
