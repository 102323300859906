import React, { ReactElement } from 'react';
import { FieldArray } from 'formik';

import { FormDateField, FormTextField, FormCheckbox } from '../../Common/FormItems';
import { commonStyles, css } from '../../Common/styling';
import { Divider, Grid, Paper } from '@mui/material';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
};

export const ResidenceWithTeamsCreate: React.FC<Props> = (props) => {
  return (
    <FieldArray
      name="residences"
      render={(): ReactElement => (
        <div>
          {props.values.residences.map((row: any, index: number) => (
            <div key={index}>
              <Paper elevation={0}>
                <Divider className="!mb-5" />
                <Grid container spacing={2} style={{ marginBottom: '15px' }} alignItems="center" key={index}>
                  <Grid item xs={4} container alignItems="center">
                    <div style={{ paddingLeft: '10px' }}>
                      <b>{row.name}</b>
                    </div>
                  </Grid>
                </Grid>
                {row.teams && (
                  <div className={css(commonStyles.greyRow)}>
                    {row.teams.map((team: any, teamIndex: number) => (
                      <div key={teamIndex}>
                        <Divider />
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                          style={{ marginBottom: '0px', padding: '0 15px' }}
                        >
                          <FormCheckbox
                            size={1}
                            fieldName={`residences.${index}.teams.${teamIndex}.checked`}
                            label=""
                            values={props.values}
                            onChange={props.handleChange}
                          />
                          <Grid item xs={3} container alignItems="center">
                            <div style={{ paddingLeft: '10px' }}>
                              <b>Team: </b>
                              {team.name}
                            </div>
                          </Grid>
                          <FormDateField
                            size={3}
                            fieldName={`residences.${index}.teams.${teamIndex}.start_date`}
                            label="Från"
                            placeholder=""
                            values={props.values}
                            errors={props.errors}
                            onChange={props.setFieldValue}
                          />
                          <FormDateField
                            size={3}
                            fieldName={`residences.${index}.teams.${teamIndex}.end_date`}
                            label="Till"
                            placeholder=""
                            values={props.values}
                            errors={props.errors}
                            onChange={props.setFieldValue}
                          />
                          <FormTextField
                            size={1}
                            fieldName={`residences.${index}.teams.${teamIndex}.budgeted_hours`}
                            label="Timpott"
                            placeholder=""
                            values={props.values}
                            errors={props.errors}
                            onChange={props.handleChange}
                          />

                          <FormTextField
                            size={1}
                            fieldName={`residences.${index}.teams.${teamIndex}.startup_fee`}
                            label="Uppstart"
                            placeholder=""
                            values={props.values}
                            errors={props.errors}
                            onChange={props.handleChange}
                          />
                        </Grid>
                      </div>
                    ))}
                  </div>
                )}
              </Paper>
            </div>
          ))}
        </div>
      )}
    />
  );
};
