import { FieldArray, FieldArrayRenderProps, Formik, FormikHelpers, yupToFormErrors } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Add as AddIcon, Clear as ClearIcon } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import { FormDateField, FormTextField } from '../../Common/FormItems';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { isEmpty } from '../../Common/utilities';
import { batchUpdateAgreements, getAgreement } from '../agreementApi';
import { AgreementInterface, BatchUpdateInterface } from '../types';
import { initialValues, validationSchema } from './batchUpdateAgreementSchema';

type Props = {
  agreements: AgreementInterface[];
  fetchAgreementsList: () => void;
  setModalState: any;
};

const BatchUpdateAgreementModal: React.FC<Props> = (props) => {
  // hooks
  const { notifySuccess, notifyError } = useNotify();
  const [loading, setLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [dialogAgreementsState, setDialogAgreementsState] = useState(initialValues as BatchUpdateInterface);

  useEffect(() => {
    setIsEditable(testIsEditable());

    if (!isEmpty(props.agreements) && props.agreements[0].id) {
      setLoading(true);
      getAgreement(props.agreements[0].id)
        .then(({ data }) => setDialogAgreementsState(data.data))
        .then(() => setLoading(false))
        .catch(() => notifyError('Det gick inte att ladda något av avtalen'));
    }
  }, []); // eslint-disable-line

  // functions
  const testIsEditable = (): boolean => {
    return props.agreements.every((agreement) => {
      if (props.agreements[0].start_date !== agreement.start_date) return false;
      return props.agreements[0].end_date === agreement.end_date;
    });
  };

  const handleSubmit = (values: any, actions: FormikHelpers<any>): void => {
    const ids: number[] = [];
    props.agreements.forEach((agreement) => {
      if (agreement.id) ids.push(parseInt(agreement.id));
    });
    setLoading(true);
    batchUpdateAgreements(ids, {
      start_date: values.start_date,
      end_date: values.end_date,
      price_periods: values.price_periods,
    })
      .then(() => notifySuccess('Avtalen har uppdaterats'))
      .then(() => props.fetchAgreementsList()) // Refresh the agreements list
      .catch((error: any) => {
        notifyError(`Avtalen har inte uppdaterats : ${error}`);
        actions.setSubmitting(false);
      })
      .finally(() => {
        setLoading(false);
        props.setModalState(false);
      });
  };

  const emptyPricePeriod = {
    start_date: new Date().toISOString().split('T')[0],
    end_date: new Date().toISOString().split('T')[0],
    hourly_rate: 0,
  };

  return (
    <div>
      <DialogTitle>Ändra avtal</DialogTitle>
      {props.agreements.length > 1 && (
        <DialogContent>
          <DialogContentText>{props.agreements.length} avtal valda</DialogContentText>
          <DialogContentText>
            <b>Obs! Var säker på att de avtal som är valda har samma prisperioder</b>
          </DialogContentText>
        </DialogContent>
      )}
      {isEditable ? (
        <Formik
          enableReinitialize
          initialValues={dialogAgreementsState}
          validate={(values): Promise<any> => {
            return validationSchema
              .validate(values, {
                abortEarly: false,
                context: {
                  start_date: values.start_date,
                  end_date: values.end_date,
                  price_periods: values.price_periods,
                },
              })
              .then(() => Promise.resolve())
              .catch((err) => Promise.resolve(yupToFormErrors(err))); // In Formik 2.x, rejection will be interpreted as an actual exception and it won't update the form error state, therefore we return a resolved promise of errors instead
          }}
          onSubmit={(values, actions): void => {
            handleSubmit(values, actions);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                  <FormDateField
                    size={4}
                    fieldName="start_date"
                    label="Startdatum"
                    placeholder="Startdatum för perioden"
                    values={values}
                    errors={errors}
                    onChange={setFieldValue}
                  />
                  <FormDateField
                    size={4}
                    fieldName="end_date"
                    label="Slutdatum"
                    placeholder="Slutdatum för perioden"
                    values={values}
                    errors={errors}
                    onChange={setFieldValue}
                  />
                </Grid>

                <Divider className="!mb-5" />

                <FieldArray
                  name="price_periods"
                  render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
                    <div>
                      {values.price_periods &&
                        values.price_periods.map((row: any, index: number) => (
                          <Grid container spacing={2} style={{ marginBottom: '20px' }} key={index}>
                            <FormDateField
                              size={3}
                              fieldName={`price_periods.${index}.start_date`}
                              label="Start prisperiod"
                              placeholder="Startdatum för prisperioden"
                              values={values}
                              errors={errors}
                              onChange={setFieldValue}
                            />

                            <FormDateField
                              size={3}
                              fieldName={`price_periods.${index}.end_date`}
                              label="Slut prisperiod"
                              placeholder="Slutdatum för prisperioden"
                              values={values}
                              errors={errors}
                              onChange={setFieldValue}
                            />

                            <FormTextField
                              size={3}
                              fieldName={`price_periods.${index}.hourly_rate`}
                              label="Timpris (kr)"
                              placeholder="Timpriset (kr)"
                              values={values}
                              errors={errors}
                              onChange={handleChange}
                            />

                            {index > 0 && (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton size="small" onClick={(): void => arrayHelpers.remove(index)}>
                                  <ClearIcon />
                                </IconButton>
                              </div>
                            )}
                          </Grid>
                        ))}
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          style={{ marginBottom: '20px' }}
                          onClick={(): void => arrayHelpers.push(emptyPricePeriod)}
                        >
                          <AddIcon style={{ marginRight: '10px' }} />
                          LÄGG TILL PRISPERIOD
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
                  AVBRYT
                </Button>
                <Button fullWidth color="primary" type="submit">
                  SPARA
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              Tyvärr kan ett eller flera avtal inte ändras tillsammans då start och slut datum måste vara detsamma.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
              AVBRYT
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}
    </div>
  );
};

export default BatchUpdateAgreementModal;
