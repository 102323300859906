import React, { useState, useEffect, ReactElement, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { commonStyles, css } from '../Common/styling';
import { NotificationContextValue, OptionsInterface, RoleOptionsInterface } from '../Common/types';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import NotificationContext from '../Common/NotificationContext';

import JobOpeningForm from './components/JobOpeningForm';
import { JobOpeningInterface } from './types';
import { validationSchema, initialJobOpeningValues } from './jobOpeningSchema';

import { getRolesMinimalList } from '../Common/commonApi';
import { updateJobOpening, getJobOpening } from './jobOpeningApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { getResidenceClustersMinimalList } from '../ResidenceCluster/residenceClusterApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import { getRegionsMinimalList } from '../Region/regionApi';
import { getDefaultsWithActualValues } from '../Common/utilities';

const EditJobOpening: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const { id } = useParams() as { id: string };
  const [jobOpening, setJobOpening] = useState<JobOpeningInterface>(initialJobOpeningValues);
  const [residenceClustersMinimalList, setResidenceClustersMinimalList] = useState([]);
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [regionsMinimalList, setRegionsMinimalList] = useState([]);
  const [roleMinimalList, setRoleMinimalList] = useState<RoleOptionsInterface[]>([]);
  const [residenceClusters, setResidenceClusters] = useState<Array<OptionsInterface>>([]);
  const [residenceGroups, setResidenceGroups] = useState<Array<OptionsInterface>>([]);
  const [regions, setRegions] = useState<Array<OptionsInterface>>([]);
  const [loading, setLoading] = useState(true);
  const [loadingResidenceMinimalList, setLoadingResidenceMinimalList] = useState(true);
  const [loadingRolesMinimalList, setLoadingRolesMinimalList] = useState(true);
  const { refreshJobOpeningsNotification } = useContext(NotificationContext) as NotificationContextValue;

  // functions
  useEffect(() => {
    setLoading(true);
    setLoadingResidenceMinimalList(true);
    setLoadingRolesMinimalList(true);
    getResidenceClustersMinimalList()
      .then(({ data }) => setResidenceClustersMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'));

    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart'));

    getRegionsMinimalList()
      .then(({ data }) => setRegionsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner'));

    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'))
      .finally(() => setLoadingResidenceMinimalList(false));

    getRolesMinimalList({ hide_banned: true })
      .then(({ data }) => {
        setRoleMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över roller'))
      .finally(() => setLoadingRolesMinimalList(false));

    getJobOpening(id)
      .then(({ data }) => {
        setJobOpening(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta jobbannonsen'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  const handleUpdateJobOpening = (
    { residences, roles, ...values }: typeof initialJobOpeningValues,
    setSubmitting: (isSubmitting: boolean) => void
  ): void => {
    updateJobOpening(jobOpening.id, {
      ...values,
      role_ids: roles.map(({ id }) => id),
      residence_ids: residences.map(({ id }) => id as number),
    })
      .then(() => notifySuccess('Jobbannonsen är uppdaterad'))
      .then(() => refreshJobOpeningsNotification())
      .then(() => navigate(`/job_openings`))
      .catch((error: any) => {
        notifyError(`Det gick inte att uppdatera annonsen: \n${HandleError(error)}`);
        setSubmitting(false);
      });
  };

  const handleFormSubmit = (values: typeof initialJobOpeningValues, actions: FormikHelpers<any>): void => {
    setLoading(true);

    const teamLeaderRole = roleMinimalList.find((role) => role.name == 'team_leader');
    const roles = [...values.roles];
    if (!roles.length && roleMinimalList.length > 0 && teamLeaderRole) {
      roles.push(teamLeaderRole);
    }

    if (roles.length == 0) {
      notifyError('Du måste välja minst en roll.');
    } else {
      handleUpdateJobOpening(values, actions.setSubmitting);
    }
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Redigera jobbannons</h1>
        <CancelLink link={`/job_openings`} />
      </div>
      {(loading || loadingResidenceMinimalList || loadingRolesMinimalList) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading || loadingResidenceMinimalList || loadingRolesMinimalList} />
        </div>
      )}
      {!loading && !loadingResidenceMinimalList && !loadingRolesMinimalList && (
        <div>
          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              initialValues={getDefaultsWithActualValues(initialJobOpeningValues, jobOpening)}
              validationSchema={validationSchema}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, isValid }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <JobOpeningForm
                    values={values}
                    handleChange={handleChange}
                    jobOpening={jobOpening}
                    errors={errors}
                    roleMinimalList={roleMinimalList}
                    residenceMinimalList={residenceMinimalList}
                    residenceClustersMinimalList={residenceClustersMinimalList}
                    residenceGroupsMinimalList={residenceGroupsMinimalList}
                    regionsMinimalList={regionsMinimalList}
                    setResidenceClusters={setResidenceClusters}
                    setResidenceGroups={setResidenceGroups}
                    setRegions={setRegions}
                    residenceClusters={residenceClusters}
                    residenceGroups={residenceGroups}
                    regions={regions}
                    isCreate={false}
                    setFieldValue={setFieldValue}
                    submitDisabled={isSubmitting || !isValid}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditJobOpening;
