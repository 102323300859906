import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import React, { ReactElement } from 'react';
import { FormSelect, FormTextField } from '../../../Common/FormItems';
import { useNotify } from '../../../Common/snackbarHooks';
import { createUser } from '../../usersApi';
import { newUserValidationSchema, newUserValues } from '../profileSchema';
import { Grid } from '@mui/material';

type Props = {
  setModalState: any;
  callback: any;
};

const MakeCorrectionsModal: React.FC<Props> = (props) => {
  // hooks
  const { notifySuccess, notifyError } = useNotify();

  const handleSubmit = (values: any): void => {
    createUser(values)
      .then(() => {
        notifySuccess('Det har skickats ett mail till användaren som precis lagts till');
        props.callback();
        props.setModalState(false);
      })
      .catch(() => notifyError('Det gick inte att göra hanteringen'));
  };

  return (
    <div>
      <DialogTitle>Lägg till en användare</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={newUserValues}
        validationSchema={newUserValidationSchema}
        onSubmit={(values): void => handleSubmit(values)}
      >
        {({ values, errors, handleChange, handleSubmit }): ReactElement => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <FormTextField
                  size={12}
                  fieldName="email"
                  label="Email / Användarnamn"
                  placeholder="Email"
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container spacing={4}>
                <FormTextField
                  size={6}
                  fieldName="first_name"
                  label="Namn"
                  placeholder="Namn på användaren"
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />
                <FormTextField
                  size={6}
                  fieldName="surname"
                  label="Efternamn"
                  placeholder="Efternamn på användaren"
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container spacing={2}>
                <FormSelect
                  size={12}
                  fieldName="role"
                  label="Roll"
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                  options={[
                    { id: 'operational_leader', name: 'operational_leader' },
                    { id: 'admin', name: 'admin' },
                  ]}
                />
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
                AVBRYT
              </Button>
              <Button fullWidth color="primary" type="submit">
                LÄGG TILL
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default MakeCorrectionsModal;
