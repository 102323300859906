import React from 'react';
import { BaseFilterPopover } from '../../Common/FilterPopover';
import { FormControlLabel, Switch } from '@mui/material';
import { Dayjs } from 'dayjs';
import FilterDatePicker from '../../Common/FilterPopover/FilterDatePicker';
import FilterFreeDateField from '../../Common/FilterPopover/FilterFreeDateField';

type Props = {
  fromDate: Dayjs | undefined;
  setFromDate: (date: Dayjs | null) => void;
  toDate: Dayjs | undefined;
  setToDate: (date: Dayjs | null) => void;
  showExported: boolean | undefined;
  setShowExported: (val: boolean) => void;
};

export default function ExtraHoursFilterPopover({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  showExported,
  setShowExported,
}: Props) {
  return (
    <BaseFilterPopover>
      <label>Från datum</label>
      <FilterFreeDateField value={fromDate ?? null} onChange={setFromDate} />

      <label>Till datum</label>
      <FilterFreeDateField value={toDate ?? null} onChange={setToDate} />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa exporterade
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={showExported ?? true} onChange={(_, checked) => setShowExported(checked)} />}
        />
      </div>
    </BaseFilterPopover>
  );
}
