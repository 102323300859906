import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';

import { Checkbox, Dialog, Grid, Table, TableBody, TableCell } from '@mui/material';

import { ListParams, ListPagination, AuthContextValue } from '../Common/types';
import BatchUpdateAgreementModal from './components/BatchUpdateAgreementModal';
import BatchRemoveAgreementModal from './components/BatchRemoveAgreementModal';

import { paginationDefaults, filterSortDefaults } from './agreementSchema';
import { CreateFab, UpdateFab, ExportFab, RemoveFab } from '../Common/ButtonLinks';
import { LoadMoreButton, TableHead, TableRow } from '../Common/Table';
import { formatDate, isEmpty, isNotEmpty } from '../Common/utilities';
import { FilterPopover } from './components/FilterPopover';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import ExportModal from './components/ExportModal';
import AuthContext from '../Common/AuthContext';
import { getAgreementList } from './agreementApi';
import { AgreementInterface } from './types';

const Agreements: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const [selectedAgreements, setSelectedAgreements] = useState([] as AgreementInterface[]);
  const [agreementsList, setAgreementsList] = useState<Array<AgreementInterface>>([]);
  const [agreementsCount, setAgreementsCount] = useState<number>(0);
  const [pagination, setPagination] = useState(paginationDefaults as ListPagination);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [editModalState, setEditModalState] = useState(false);
  const [removeModalState, setRemoveModalState] = useState(false);
  const [exportModalState, setExportModalState] = useState(false);
  const [loading, setLoading] = useState(true);
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  useEffect(() => {
    fetchAgreementsList();
    setPagination(paginationDefaults);
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchAgreementsList = (): void => {
    setLoading(true);
    // Uses paginationDefaults because setState is a async function and pagination might not have been set yet
    getAgreementList({ ...listParams, ...paginationDefaults, processing: false })
      .then(({ data }) => {
        setAgreementsList(data.data);
        setAgreementsCount(data.meta.count);
      })
      .catch(() => notifyError('Det gick inte att hämta listan med avtal'))
      .then(() => setLoading(false));
  };

  const rowIsSelected = (id: string | undefined): boolean => {
    return selectedAgreements.some((element) => element.id === id);
  };

  const selectRow = (event: any, row: AgreementInterface): void => {
    // Avoid to navigate to agreement detail view
    event.stopPropagation();

    // Push or pop element from list
    const newSelectedList = event.target.checked
      ? [...selectedAgreements, row]
      : selectedAgreements.filter((a: any) => a.id !== row.id);

    setSelectedAgreements(newSelectedList);
  };

  const allRowsAreSelected = (): boolean => {
    return agreementsList.length !== 0 && agreementsList.length === selectedAgreements.length;
  };

  const selectAllRows = (isChecked: boolean): void => {
    isChecked ? setSelectedAgreements(agreementsList) : setSelectedAgreements([]);
  };

  const SelectionFabs: React.FC = () => {
    return (
      <div className={css(commonStyles.selectionFabContainer)}>
        <UpdateFab onClick={(): void => setEditModalState(true)} text="ÄNDRA AVTAL" />
        <RemoveFab onClick={(): void => setRemoveModalState(true)} text="TA BORT AVTAL" />
      </div>
    );
  };

  const agreementType = (type: string): string => {
    if (type === 'ContinuousAgreement') {
      // Löpande
      return 'L';
    } else {
      if (type === 'FixedAgreement') {
        // Anpassad
        return 'A';
      } else {
        return '';
      }
    }
  };

  // render
  const rows = [
    { id: 'residence_name', label: 'Boende' },
    { id: 'residence_group_name', label: 'Avtalspart' },
    { id: 'type', label: 'Typ' },
    { id: 'budgeted_hours', numeric: true, label: 'Timpott (tim)' },
    { id: 'closest_hourly_rate', numeric: true, label: 'Timpris (kr/tim)', notSortable: true },
    { id: 'start_date', label: 'Startdatum' },
    { id: 'end_date', label: 'Slutdatum' },
  ];

  return (
    <div className={css(commonStyles.listViewWrapper)}>
      <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <h1 className={css(commonStyles.headerTextStyle)}>Avtal</h1>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>{agreementsCount} Avtal</p>
        </Grid>
      </Grid>
      <Grid container className={css(commonStyles.searchBarWrapper)}>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <SearchBar setGetListParams={setGetListParams} listParams={listParams} />
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={2} sm={2} md={2} lg={2}>
          <FilterPopover setGetListParams={setGetListParams} listParams={listParams} />
        </Grid>
      </Grid>

      <Table classes={{ root: css(commonStyles.tableWrapper) }}>
        <TableHead
          rows={rows}
          listParams={listParams}
          setGetListParams={setGetListParams}
          checkboxComponent={
            <Checkbox checked={allRowsAreSelected()} onClick={(e: any): void => selectAllRows(e.target.checked)} />
          }
        />

        <TableBody>
          {isEmpty(agreementsList) ? (
            <EmptyList />
          ) : (
            agreementsList.map((row: AgreementInterface, idx: number) => (
              <TableRow row={row} idx={idx} key={idx} onClick={(): void => navigate(`/agreements/${row.id}`)}>
                <TableCell padding="checkbox">
                  <Checkbox onClick={(e): void => selectRow(e, row)} checked={rowIsSelected(row.id)} />
                </TableCell>
                <TableCell scope="row" className={css(commonStyles.tableCell)}>
                  <b>{row.residence_name}</b>
                </TableCell>
                <TableCell className={css(commonStyles.tableCell)}>{row.residence_group_name}</TableCell>
                <TableCell className={css(commonStyles.tableCellSmall)}>{agreementType(row.type)}</TableCell>
                <TableCell className={css(commonStyles.tableCellSmall)}>{row.budgeted_hours}</TableCell>
                <TableCell className={css(commonStyles.tableCellSmall)}>{row.closest_hourly_rate}</TableCell>
                <TableCell className={css(commonStyles.tableCellSmall)}>{formatDate(row.start_date)}</TableCell>
                <TableCell className={css(commonStyles.tableCellSmall)}>{formatDate(row.end_date)}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      <LoadMoreButton
        loadList={getAgreementList}
        state={agreementsList}
        setState={setAgreementsList}
        params={listParams}
        pagination={pagination}
        setPagination={setPagination}
      />

      {editModalState && (
        <Dialog open={editModalState} onClose={(): void => setEditModalState(false)} fullWidth={true} maxWidth="md">
          <BatchUpdateAgreementModal
            agreements={selectedAgreements}
            setModalState={setEditModalState}
            fetchAgreementsList={fetchAgreementsList}
          />
        </Dialog>
      )}
      {removeModalState && (
        <Dialog open={removeModalState} onClose={(): void => setRemoveModalState(false)} fullWidth={true} maxWidth="md">
          <BatchRemoveAgreementModal
            agreements={selectedAgreements}
            setModalState={setRemoveModalState}
            fetchAgreementsList={fetchAgreementsList}
          />
        </Dialog>
      )}
      {hasPermissions(['admin']) && exportModalState && (
        <Dialog open={exportModalState} onClose={(): void => setExportModalState(false)}>
          <ExportModal setModalState={setExportModalState} />
        </Dialog>
      )}
      {isNotEmpty(selectedAgreements) && <SelectionFabs />}

      <ExportFab onClick={(): void => setExportModalState(true)} />
      <CreateFab link={'/agreements/create'} />
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}
    </div>
  );
};

export default Agreements;
