import { Autocomplete, CircularProgress, AutocompleteChangeReason, TextField } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { createFilterOptions } from '@mui/material/Autocomplete';

// Redecalare forwardRef
declare module 'react' {
  // Required since regular forwardRef declaration doesn't allow generic type
  function forwardRef<T, P = Record<string, never>>(
    render: (props: P, ref: React.Ref<T> | null) => JSX.Element
  ): (props: P & React.RefAttributes<T>) => JSX.Element;
}

interface FilterAutocompleteBoxProps<T extends { id: any }> {
  options: T[];
  value?: T | null;
  onChange?: (option: T | null, event: React.SyntheticEvent) => void;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void;
  groupBy?: (option: T) => string;
  getOptionLabel: (option: T) => string;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  startAdornment?: ReactNode;
  clearable?: boolean;
  className?: string;
}

function FilterAutocompleteBoxInner<T extends { id: any }>(
  {
    options,
    value,
    onChange,
    onInputChange,
    getOptionLabel,
    groupBy,
    placeholder,
    loading = false,
    disabled = false,
    isOpen,
    setIsOpen,
    startAdornment,
    clearable,
    className,
  }: FilterAutocompleteBoxProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Autocomplete
      options={options}
      filterOptions={createFilterOptions({ ignoreAccents: false })}
      value={value!} // Requires it not to be null, but still accepts it
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      className={twMerge('w-56', className)}
      disableClearable={!clearable}
      disabled={disabled}
      onChange={onChange && ((event, option) => onChange(option, event))}
      onInputChange={onInputChange}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option)}
        </li>
      )}
      open={isOpen}
      onOpen={setIsOpen && (() => setIsOpen(true))}
      onClose={setIsOpen && (() => setIsOpen(false))}
      loading={loading}
      renderInput={({ InputProps, ...params }) => (
        <div className="transition hover:bg-gray-100/70 h-full flex items-center cursor-text">
          <TextField
            {...params}
            inputRef={ref}
            InputProps={{
              ...InputProps,
              disableUnderline: true,
              placeholder,
              startAdornment: startAdornment,
              endAdornment: loading ? (
                <span className="pr-2 h-[1em]">
                  <CircularProgress size="1em" />
                </span>
              ) : (
                InputProps.endAdornment
              ),
              className: 'px-2 h-full',
            }}
            variant="standard"
            sx={{
              '& .MuiAutocomplete-input': {
                flexGrow: 1,
                textOverflow: 'ellipsis',
              },
            }}
          />
        </div>
      )}
    />
  );
}

const FilterAutocompleteBox = React.forwardRef(FilterAutocompleteBoxInner);

export default FilterAutocompleteBox;
