import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import minMax from 'dayjs/plugin/minMax';
import { SnackbarProvider } from 'notistack';
import React, { useContext, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { AuthContext } from './Common/AuthContext';
import { NotificationProvider } from './Common/NotificationContext';
import themeStyles from './Common/styling/materialTheme';
import { AuthContextValue } from './Common/types';
import CreateJobApplicationPublic from './JobApplication/CreateJobApplicationPublic';
import ApplicationSentConfirmation from './JobApplication/components/ApplicationSentConfirmation';
import Terms from './JobApplication/components/Terms';
import Login from './Login/Login';
import ResetPasswordPage from './User/ResetPassword';

import Agreements from './Agreement/Agreements';
import CreateAgreement from './Agreement/CreateAgreement';
import EditAgreement from './Agreement/EditAgreement';
import CreateJobApplication from './JobApplication/CreateJobApplication';
import EditJobApplication from './JobApplication/EditJobApplication';
import JobApplications from './JobApplication/JobApplications';
import CreateJobOpening from './JobOpening/CreateJobOpening';
import EditJobOpening from './JobOpening/EditJobOpening';
import JobOpening from './JobOpening/JobOpenings';
import Management from './Management/Management';
import MissedWorktimes from './MissedWorktimes/MissedWorktimes';
import CreateNews from './News/CreateNews';
import EditNews from './News/EditNews';
import News from './News/News';
import EditEducation from './Participants/Education/EditEducation';
import Educations from './Participants/Education/Educations';
import EditEmployeeAgreement from './Participants/EmployeeAgreement/EditEmployeeAgreement';
import EmployeeAgreement from './Participants/EmployeeAgreement/EmployeeAgreement';
import CreateFaq from './Participants/Faq/CreateFaq';
import EditFaq from './Participants/Faq/EditFaq';
import Faqs from './Participants/Faq/Faqs';
import CreateFuture from './Participants/Future/CreateFuture';
import EditFuture from './Participants/Future/EditFuture';
import Future from './Participants/Future/Future';
import CreateRegion from './Region/CreateRegion';
import EditRegion from './Region/EditRegion';
import Regions from './Region/Regions';
import CreateResidence from './Residence/CreateResidence';
import EditResidence from './Residence/EditResidence';
import Residences from './Residence/Residences';
import CreateResidenceCluster from './ResidenceCluster/CreateResidenceCluster';
import EditResidenceCluster from './ResidenceCluster/EditResidenceCluster';
import ResidenceClusters from './ResidenceCluster/ResidenceClusters';
import CreateResidenceGroup from './ResidenceGroup/CreateResidenceGroup';
import EditResidenceGroup from './ResidenceGroup/EditResidenceGroup';
import ResidenceGroups from './ResidenceGroup/ResidenceGroups';
import SavedInvoiceDetail from './SavedInvoices/SavedInvoiceDetail';
import SavedInvoices from './SavedInvoices/SavedInvoices';
import ScheduleOutline from './Schedule/ScheduleOutline';
import SentInvoiceDetail from './SentInvoices/SentInvoiceDetail';
import SentInvoices from './SentInvoices/SentInvoices';
import CreateTeam from './Team/CreateTeam';
import EditTeam from './Team/EditTeam';
import Teams from './Team/Teams';
import CreateUser from './User/CreateUser';
import EditUser from './User/EditUser';
import Users from './User/Users';
import CreateWorkShift from './WorkShift/CreateWorkShift';
import EditWorkShift from './WorkShift/EditWorkShift';
import WorkShifts from './WorkShift/WorkShifts';

import ResidenceMap from './Residence/ResidenceMap';
import ScheduleNoTeam from './Schedule/ScheduleNoTeam';
import TeamSchedule from './Schedule/TeamSchedule';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material';
import SalaryClasses from './SalaryClass/SalaryClasses';
import CreateSalaryClass from './SalaryClass/CreateSalaryClass';
import EditSalaryClass from './SalaryClass/EditSalaryClass';
import ExtraHours from './ExtraHours/ExtraHours';
import CreateExtraHour from './ExtraHours/CreateExtraHour';
import EditExtraHour from './ExtraHours/EditExtraHour';
import Interviews from './Interview/Interviews';
import CreateInterview from './Interview/CreateInterview';
import EditInterview from './Interview/EditInterview';
import Dashboard from './Dashboard/Dashboard';
import DrawerMenu from './Common/Menu/DrawerMenu';
import ResidenceLogin from './ResidencePage/ResidenceLogin';
import ResidencePage from './ResidencePage/ResidencePage';
import { residencePageURL } from './Common/utilities';

dayjs.locale('sv');
dayjs.extend(minMax);

// Create Material UI theme to inject in app
const theme = createTheme(themeStyles);

const StagingBar: React.FC = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return (
      <div
        style={{
          padding: '12px',
          alignItems: 'center',
          color: 'white',
          backgroundColor: '#3f51b5',
          display: 'flex',
          justifyContent: 'center',
          fontSize: 24,
        }}
      >
        <div style={{ marginRight: '50px' }}>Environment: {process.env.REACT_APP_ENVIRONMENT}</div>
        <div style={{ marginLeft: '50px' }}>
          <a
            style={{ color: 'white' }}
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_BUG_REPORTER_URL}
          >
            Anmäl buggar här!
          </a>
        </div>
      </div>
    );
  } else return <div />;
};

const PUBLIC_APPLICATION_DOMAINS = ['staging-ansokningar', 'ansokningar', 'staging-boende', 'boende'];

const App: React.FC = () => {
  const { isAuth, hasPermissions } = useContext(AuthContext) as AuthContextValue;
  const isPublicApplicationsDomain = PUBLIC_APPLICATION_DOMAINS.includes(window.location.host.split('.')[0]);
  const isLocal = window.location.origin.toString() == 'http://localhost:3000';

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
          <NotificationProvider>
            <StagingBar />
            <Routes>
              {(isPublicApplicationsDomain || isLocal) && (
                <React.Fragment>
                  {!isLocal && window.location.hostname === residencePageURL?.hostname && (
                    <Route path="/" element={<Navigate to="/boende" replace />} />
                  )}
                  <Route path="/deltagare" element={<CreateJobApplicationPublic type={'participant'} />} />
                  <Route path="/teamledare" element={<CreateJobApplicationPublic type={'team_leader'} />} />
                  <Route path="/deltagare/skickad" element={<ApplicationSentConfirmation type={'participant'} />} />
                  <Route path="/teamledare/skickad" element={<ApplicationSentConfirmation type={'team_leader'} />} />
                  <Route path="/integritetspolicy" element={<Terms />} />
                  <Route path="/boende" element={<ResidenceLayout />} />
                </React.Fragment>
              )}

              {(!isPublicApplicationsDomain || isLocal) && (
                <>
                  <Route path="/" element={<Navigate to="/login" replace />} />

                  <Route path="/" element={<Layout />}>
                    {isAuth ? (
                      <React.Fragment>
                        {process.env.REACT_APP_FEATURE_DASHBOARD === 'true' && (
                          <Route path="dashboard" element={<Dashboard />} />
                        )}
                        <Route path="profile/:id" element={<EditUser isProfile={true} />} />

                        {hasPermissions(['admin', 'operational_leader']) && (
                          <React.Fragment>
                            <Route path="residence-groups" element={<ResidenceGroups />} />
                            <Route path="residence-groups/create" element={<CreateResidenceGroup />} />
                            <Route path="residence-groups/:id" element={<EditResidenceGroup />} />

                            <Route path="residence-clusters" element={<ResidenceClusters />} />
                            <Route path="residence-clusters/create" element={<CreateResidenceCluster />} />
                            <Route path="residence-clusters/:id" element={<EditResidenceCluster />} />

                            <Route path="residences" element={<Residences />} />
                            <Route path="residences/create" element={<CreateResidence />} />
                            <Route path="residences/:id" element={<EditResidence />} />

                            <Route path="faq" element={<Faqs />} />
                            <Route path="faq/create" element={<CreateFaq />} />
                            <Route path="faq/:id" element={<EditFaq />} />

                            <Route path="future" element={<Future />} />
                            <Route path="future/create" element={<CreateFuture />} />
                            <Route path="future/:id" element={<EditFuture />} />

                            <Route path="employee-agreements" element={<EmployeeAgreement />} />
                            <Route path="employee-agreements/:id" element={<EditEmployeeAgreement />} />

                            <Route path="education" element={<Educations />} />
                            <Route path="education/:id" element={<EditEducation />} />

                            <Route path="teams/create" element={<CreateTeam />} />
                          </React.Fragment>
                        )}

                        <Route path="teams" element={<Teams />} />
                        <Route path="teams/:id" element={<EditTeam />} />

                        {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
                          <React.Fragment>
                            <Route path="regions" element={<Regions />} />
                            <Route path="regions/create" element={<CreateRegion />} />
                            <Route path="regions/:id" element={<EditRegion />} />
                          </React.Fragment>
                        )}

                        {hasPermissions(['admin', 'operational_leader', 'operational_manager', 'regional_manager']) && (
                          <React.Fragment>
                            <Route path="teams/:teamId/work-shifts/create" element={<CreateWorkShift />} />
                            <Route path="work-shifts/:id" element={<EditWorkShift />} />

                            <Route
                              path="job_applications/participant"
                              element={<JobApplications type={'participant'} />}
                            />
                            <Route
                              path="job_applications/participant/create"
                              element={<CreateJobApplication type={'participant'} />}
                            />
                            <Route
                              path="job_applications/participant/:id"
                              element={<EditJobApplication type={'participant'} />}
                            />
                            <Route
                              path="job_applications/team_leader"
                              element={<JobApplications type={'team_leader'} />}
                            />
                            <Route
                              path="job_applications/team_leader/create"
                              element={<CreateJobApplication type={'team_leader'} />}
                            />
                            <Route
                              path="job_applications/team_leader/:id"
                              element={<EditJobApplication type={'team_leader'} />}
                            />

                            <Route path="job_openings" element={<JobOpening />} />
                            <Route path="job_openings/create" element={<CreateJobOpening />} />
                            <Route path="job_openings/:id" element={<EditJobOpening />} />

                            <Route path="news" element={<News />} />
                            <Route path="news/create" element={<CreateNews />} />
                            <Route path="news/:id" element={<EditNews />} />

                            <Route path="schedule" element={<ScheduleOutline />}>
                              <Route path="" element={<ScheduleNoTeam />} />
                              <Route path=":teamId" element={<TeamSchedule />} />
                            </Route>

                            <Route path="users" element={<Users />} />
                            <Route path="users/create" element={<CreateUser />} />
                            <Route path="users/:id" element={<EditUser isProfile={false} />} />

                            <Route path="work-shifts" element={<WorkShifts />} />
                          </React.Fragment>
                        )}

                        {hasPermissions(['admin']) && (
                          <React.Fragment>
                            <Route path="agreements" element={<Agreements />} />
                            <Route path="agreements/create" element={<CreateAgreement />} />
                            <Route path="agreements/:id" element={<EditAgreement />} />

                            <Route path="missed-worktimes" element={<MissedWorktimes />} />

                            <Route path="management" element={<Management />} />

                            <Route path="saved-invoices" element={<SavedInvoices />} />
                            <Route path="saved-invoices/:id" element={<SavedInvoiceDetail />} />

                            <Route path="sent-invoices" element={<SentInvoices />} />
                            <Route path="sent-invoices/:id" element={<SentInvoiceDetail />} />

                            {hasPermissions(['admin']) && (
                              <>
                                <Route path="salary-classes" element={<SalaryClasses />} />
                                <Route path="salary-classes/create" element={<CreateSalaryClass />} />
                                <Route path="salary-classes/:id" element={<EditSalaryClass />} />
                              </>
                            )}
                          </React.Fragment>
                        )}

                        {hasPermissions(['admin', 'operational_leader', 'operational_manager', 'regional_manager']) && (
                          <>
                            <Route path="extra-hours" element={<ExtraHours />} />
                            <Route path="extra-hours/create" element={<CreateExtraHour />} />
                            <Route path="extra-hours/:id" element={<EditExtraHour />} />
                          </>
                        )}

                        {hasPermissions(['admin', 'operational_leader', 'operational_manager', 'regional_manager']) && (
                          <>
                            <Route path="interviews" element={<Interviews />} />
                            <Route path="interviews/create" element={<CreateInterview />} />
                            <Route path="interviews/:id" element={<EditInterview />} />
                          </>
                        )}
                      </React.Fragment>
                    ) : (
                      <Route path="*" element={<Navigate to="/" replace />} />
                    )}
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/reset-password" element={<ResetPasswordPage />} />
                  <Route path="/karta" element={<ResidenceMap />} />
                </>
              )}
            </Routes>
          </NotificationProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const Layout: React.FC = () => {
  const [open, setOpen] = useState(true);

  return (
    <React.Fragment>
      <DrawerMenu open={open} setOpen={setOpen} />

      <main
        className="relative h-full flex flex-col min-h-dvh transition-all"
        style={{ marginLeft: open ? 250 : 64, width: `calc(100% - ${open ? 250 : 64})` }}
      >
        <Outlet />
      </main>
    </React.Fragment>
  );
};

function ResidenceLayout() {
  const [authenticated, setAuthenticated] = useState(false);

  return authenticated ? (
    <ResidencePage setAuthenticated={setAuthenticated} />
  ) : (
    <ResidenceLogin setAuthenticated={setAuthenticated} />
  );
}

export default App;
