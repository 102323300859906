import * as Yup from 'yup';

import { OptionsInterface, RoleOptionsInterface, sort_order } from '../Common/types';

export const initialValues = {
  first_name: '',
  surname: '',
  role: undefined as RoleOptionsInterface | undefined,
  team: undefined as OptionsInterface | undefined,
  email: '',
  person_nr: '',
  mobile: '',
  salary_detail_attributes: {
    account_number: '',
    clearing_number: '',
  },
  address: '',
  zip_code: '',
  city: '',
  employment_documents_attributes: [] as { signed_at: string; document_type: string; _destroy: number }[],
  user_log_attributes: {
    d_education_at: '',
    tl_education_at: '',
    substitute_pool_at: new Date().toLocaleDateString('sv-SE'),
    approved_at: '',
    admin_at: '',
    operational_leader_at: '',
    operational_manager_at: '',
    regional_manager_at: '',
    team_leader_at: '',
    banned_at: '',
    banned_by: {
      id: '',
      name: '',
    },
    last_login_at: '',
    show_rc_info: true,
  },
  person_attributes: {
    person_type: 'guardian',
    name: '',
    mobile: '',
    email: '',
  },

  editable: false,
  deletable: false,
};

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('* Obligatoriskt fält'),
  surname: Yup.string().optional(),
  role: Yup.object().typeError('* Roll är ett obligatoriskt fält').required('* Roll är ett obligatoriskt fält'),
  team: Yup.object().typeError('* Team är ett obligatoriskt fält').required('* Team är ett obligatoriskt fält'),
  email: Yup.string().email('E-postadressen måste vara i formatet namn@address.com').required('* Obligatoriskt fält'),
  person_nr: Yup.string()
    .min(10, '* Måste vara minst 10 tecken')
    .max(13, '* Måste vara max 13 tecken')
    .optional()
    .nullable(),
  mobile: Yup.string().optional().nullable(),
  salary_detail_attributes: Yup.object({
    clearing_number: Yup.string()
      .min(4, '* Måste vara minst 4 tecken')
      .test(
        'clearing_number',
        '* Clearingnummer från Swedbank som börjar på siffran 8 består av 5 tecken.',
        function (value) {
          if (!value) return true;
          // @ts-ignore
          if (value.startsWith('8') && (value.length < 5 || value.length > 5)) return false;
          else return true;
        }
      )
      .test('clearing_number', '* Clearingnummer består av 4 tecken', function (value) {
        if (!value) return true;
        // @ts-ignore
        if (!value.startsWith('8') && value.length > 4) return false;
        else return true;
      })
      .nullable(),
  }),
  address: Yup.string().optional().nullable(),
  zip_code: Yup.string()
    .matches(/^[1-9]\d{2}[ ]?\d{2}$/, '* Postnumret skrivs på formatet XXXXX och enbart siffror')
    .nullable(),
  city: Yup.string().optional().nullable(),
  employment_documents_attributes: Yup.array().of(
    Yup.object({ signed_at: Yup.string().nullable(), document_type: Yup.string(), _destroy: Yup.number() })
  ),
  user_log_attributes: Yup.object({
    d_education_at: Yup.string().nullable().optional(),
    tl_education_at: Yup.string().nullable().optional(),
    substitute_pool_at: Yup.string().nullable(),
    approved_at: Yup.string().nullable().optional(),
    parent_signed_at: Yup.string().nullable().optional(),
    last_login_at: Yup.string().nullable(),
    show_rc_info: Yup.boolean(),
  }),
  person_attributes: Yup.object({
    person_type: Yup.string(),
    name: Yup.string().nullable(),
    mobile: Yup.string().nullable(),
    email: Yup.string().nullable(),
  }).nullable(),
});

export const userValidationSchema = Yup.object().shape({});

export const filterSortDefaults = {
  query: '',
  sort_by: 'name',
  sort_order: sort_order.asc,
  hide_employed: false,
  hide_banned: true,
  banned_at_start: null,
  banned_at_end: null,
  hide_inactive_teams: true,
};
