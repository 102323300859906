import React, { useState, useEffect, useRef } from 'react';
import { encode } from 'base-64';
import { RotateLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid, Table, TableBody, TableCell } from '@mui/material';

import { filterSortDefaults } from './residenceGroupSchema';
import { paginationDefaults } from '../Common/commonSchema';
import { TableHead, TableRow, Pagination } from '../Common/Table';
import { ListPagination, ListParams } from '../Common/types';
import { formatDate, isEmpty } from '../Common/utilities';
import { getResidenceGroupList } from './residenceGroupApi';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import FilterPopover from './components/FilterPopover';
import { CreateFab } from '../Common/ButtonLinks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import { ResidenceGroupInterface } from './types';
import paramsFromUrl from '../Common/Hooks/paramsFromUrl';
import useGlobalPopstateListener from '../Common/Hooks/useGlobalPopstateListener';

const ResidenceGroups: React.FC = () => {
  // hooks
  const pageTopRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyError } = useNotify();
  const [allowSearch, setAllowSearch] = useState(true);
  const [residenceGroupList, setResidenceGroupList] = useState<Array<ResidenceGroupInterface>>([]);
  const [residenceGroupsCount, setResidenceGroupsCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  // get cached filters and pagination from url if any
  // otherwise load default values
  const query = paramsFromUrl('query');
  const page = paramsFromUrl('page');
  const [pagination, setPagination] = useState(
    page === '' ? (paginationDefaults as ListPagination) : (page as ListPagination)
  );
  const [listParams, setGetListParams] = useState(
    query === '' ? (filterSortDefaults as ListParams) : (query as ListParams)
  );

  function handleHistoryChange(event: PopStateEvent) {
    // Handle popstate event
    setPagination(paramsFromUrl('page'));
  }
  useGlobalPopstateListener(handleHistoryChange);

  // render
  const rows = [
    { id: 'name', label: 'Organisation' },
    { id: 'active_team_agreements_count', numeric: true, label: 'Antal avtal' },
    { id: 'active_residences_count', numeric: true, label: 'Antal boenden' },
    { id: 'active_teams_count', numeric: true, label: 'Antal team' },
    { id: 'active_agreement_hours_count', numeric: true, label: 'Avtalstimmar' },
    { id: 'created_at', label: 'Skapad' },
  ];

  useEffect(() => {
    setPagination(pagination);
  }, []); // eslint-disable-line

  // This useEffect takes place whenever listParams or allowSearch changes
  useEffect(() => {
    const query = encode(JSON.stringify(listParams));
    const page = encode(JSON.stringify(pagination));
    navigate(`/residence-groups?query=${query}&page=${page}${location.hash}`, { replace: true });

    if (allowSearch) {
      fetchResidenceGroupsList();
    }
  }, [listParams, allowSearch, pagination]); // eslint-disable-line

  // functions
  const fetchResidenceGroupsList = (): void => {
    setLoading(true);
    getResidenceGroupList({ ...listParams, ...pagination })
      .then(({ data }) => {
        setResidenceGroupList(data.data);
        setResidenceGroupsCount(data.meta.count);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över avtalsparter'))
      .finally(() => {
        const element = document.getElementById(location.hash.substring(1)); // need to drop '#' from the beginning of the id
        if (element) {
          element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
        }
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div ref={pageTopRef} className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Avtalsparter</h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {residenceGroupsCount} Avtalsparter
            </p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <SearchBar
              setGetListParams={setGetListParams}
              listParams={listParams}
              setAllowSearch={setAllowSearch}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={2} sm={2} md={2} lg={2}>
            <FilterPopover
              setGetListParams={setGetListParams}
              listParams={listParams}
              setPagination={setPagination}
              paginationDefaults={paginationDefaults}
            />
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} maxWidth="25px" listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(residenceGroupList) ? (
              <EmptyList />
            ) : (
              residenceGroupList.map((row: ResidenceGroupInterface, idx: number) => (
                <TableRow
                  row={row}
                  idx={idx}
                  id={row.id?.toString()}
                  key={row.id}
                  onClick={(): void => {
                    const query = encode(JSON.stringify(listParams));
                    const page = encode(JSON.stringify(pagination));
                    navigate(`/residence-groups?query=${query}&page=${page}#${row.id}`, { replace: true });
                    navigate(`/residence-groups/${row.id}`);
                  }}
                >
                  <TableCell className={css(commonStyles.tableCell)}>
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{row.active_team_agreements_count}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{row.active_residences_count}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{row.active_teams_count}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{row.active_agreement_hours_count}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{formatDate(row.created_at)}</p>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <Pagination
          pageTopRef={pageTopRef}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={residenceGroupsCount}
        />

        <CreateFab link={'/residence-groups/create'} />
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ResidenceGroups;
