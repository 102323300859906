import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { AutocompleteChangeReason, Button, Divider, FormControl, FormHelperText, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../Common/AuthContext';
import { FormDateField, FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import RichTextEditorNews from '../../Common/TextEditor/RichTextEditorNews';
import { useNotify } from '../../Common/snackbarHooks';
import { StyleSheet, css, theme } from '../../Common/styling';
import { AuthContextValue, OptionsInterface, RoleOptionsInterface } from '../../Common/types';
import { getResidencesMinimalList } from '../../Residence/residenceApi';
import { NewsInterface } from '../types';

type RoleShortName = 'D' | 'TL' | 'RC';

const styles = StyleSheet.create({
  text1: {
    fontSize: '15.8px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: 'rgb(0, 0, 0, 0.87)',
  },
  text2: {
    marginTop: '8px',
    fontSize: '15.8px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
  },
  text3: {
    fontSize: '15.8px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    alignContent: 'center',
  },
  text4: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    alignContent: 'center',
  },
  map: {
    fontSize: '15.8px',
    lineHeight: '15px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    textAlign: 'right',
  },
});

type Props = {
  values: NewsInterface;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled: boolean;
  isCreate: boolean;
  roleMinimalList: RoleOptionsInterface[];
  residenceMinimalList: OptionsInterface[];
  residenceClustersMinimalList: OptionsInterface[];
  residenceGroupsMinimalList: OptionsInterface[];
  regionsMinimalList: OptionsInterface[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  removal?: () => void;
};

const NewsForm: React.FC<Props> = (props) => {
  const { profile } = useContext(AuthContext) as AuthContextValue;
  const { notifyError } = useNotify();
  const [selectedResidenceClusters, setSelectedResidenceClusters] = useState<OptionsInterface[]>([]);
  const [selectedResidenceGroups, setSelectedResidenceGroups] = useState<OptionsInterface[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<OptionsInterface[]>([]);

  const openResidenceMap = (): void => {
    window.open(`${process.env.REACT_APP_BASE_URL}/karta`, '_blank');
  };

  useEffect(() => {
    if (props.values.residences?.length === props.residenceMinimalList.length) {
      setSelectedResidenceClusters(props.residenceClustersMinimalList);
      setSelectedResidenceGroups(props.residenceGroupsMinimalList);
      setSelectedRegions(props.regionsMinimalList);
    } else if (!props.values.residences?.length) {
      setSelectedResidenceClusters([]);
      setSelectedResidenceGroups([]);
      setSelectedRegions([]);
    }
  }, [props.values.residences]);

  // functions
  async function setMultipleResidences(
    type: 'cluster' | 'group' | 'region',
    act: AutocompleteChangeReason,
    option?: OptionsInterface
  ) {
    if (act === 'blur' || act === 'createOption') return;

    let options: OptionsInterface[] = option ? [option] : [];
    if (!option) {
      options =
        type === 'cluster' ? selectedResidenceClusters : type === 'group' ? selectedResidenceGroups : selectedRegions;
    }

    const ids = options.map(({ id }) => id);
    const residences = await getResidencesMinimalList({
      is_active: true,
      residence_cluster_id: type === 'cluster' ? ids : undefined,
      residence_group_id: type === 'group' ? ids : undefined,
      region_id: type === 'region' ? ids : undefined,
    }).then(({ data }) => data.data);
    let newResidences: OptionsInterface[];
    if (act === 'selectOption') {
      newResidences = [...(props.values.residences ?? [])];
      residences.forEach((residence) => {
        if (!newResidences.some(({ id }) => residence.id === id)) {
          newResidences.push(residence);
        }
      });
    } else {
      newResidences =
        props.values.residences?.filter(({ id }) => !residences.some((residence) => id === residence.id)) ?? [];
    }

    props.setFieldValue('residences', newResidences);
    switch (type) {
      case 'cluster':
        return setSelectedResidenceClusters((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
      case 'group':
        return setSelectedResidenceGroups((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
      case 'region':
        return setSelectedRegions((curr) =>
          act === 'selectOption' ? [...curr, ...options] : curr.filter(({ id }) => !ids.includes(id))
        );
    }
  }

  const getRole = (roleName: RoleShortName) => {
    return props.roleMinimalList.find((role) => role.short_name === roleName);
  };

  function roleIsSet(shortName: RoleShortName) {
    return props.values.roles?.some((role) => role.short_name === shortName);
  }

  const clickRoleButton = (roleName: RoleShortName): void => {
    const role = getRole(roleName);
    if (role) {
      if (props.values.roles?.some(({ id }) => id === role.id)) {
        props.setFieldValue(
          'roles',
          props.values.roles.filter(({ id }) => id !== role.id)
        );
      } else if (props.values.roles) {
        props.setFieldValue('roles', [...props.values.roles, role]);
      } else {
        props.setFieldValue('roles', [role]);
      }
    } else {
      notifyError(
        'Det gick inte att spara inställningen för rollen (kunde inte hitta roll ID). \n Du måste välja minst en roll.'
      );
    }
  };

  const body = `Skriv din nyhetstext här...`;

  // 1. Convert the HTML
  const contentHTML = convertFromHTML(body);
  // 2. Create the ContentState object
  const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
  // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
  const defaultContent = JSON.stringify(convertToRaw(state));

  return (
    <div style={{ minWidth: '900px', maxWidth: '100%' }}>
      <Grid container spacing={2} alignItems="center" alignContent="flex-start">
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <p className={css(styles.text1)}>Visa för:</p>
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} style={{ display: 'flex' }}>
          <Button
            style={{ marginRight: '10px' }}
            variant={roleIsSet('D') ? 'contained' : 'outlined'}
            color={roleIsSet('D') ? 'primary' : 'secondary'}
            onClick={() => {
              if (props.isCreate || props.values.editable) clickRoleButton('D');
            }}
          >
            D
          </Button>
          <Button
            variant={roleIsSet('TL') ? 'contained' : 'outlined'}
            color={roleIsSet('TL') ? 'primary' : 'secondary'}
            style={{ marginRight: '10px' }}
            onClick={() => {
              if (props.isCreate || props.values.editable) clickRoleButton('TL');
            }}
          >
            TL
          </Button>
          <Button
            variant={roleIsSet('RC') ? 'contained' : 'outlined'}
            color={roleIsSet('RC') ? 'primary' : 'secondary'}
            style={{ marginRight: '10px' }}
            onClick={() => {
              if (props.isCreate || props.values.editable) clickRoleButton('RC');
            }}
          >
            RC
          </Button>
          <FormHelperText error>{props.errors.roles}</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="pb-2">
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" alignContent="space-between" className="pb-4">
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <p className={css(styles.text2)}>För vilka boenden ska nyheten visas?</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <p className={css(styles.text3)}>{props.values.residences?.length + ' Boenden valda'}</p>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            style={{ marginTop: '2px', marginRight: '19px', color: theme.greenLight }}
            variant="outlined"
            color="secondary"
            onClick={() => {
              if (props.isCreate || props.values.editable) {
                props.setFieldValue('residences', props.residenceMinimalList);
              }
            }}
          >
            Välj alla
          </Button>
          <Button
            style={{ marginTop: '2px', color: theme.greenLight }}
            variant="outlined"
            color="secondary"
            startIcon={<LocationOnIcon />}
            onClick={() => {
              openResidenceMap();
            }}
          >
            Karta
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
          <FormControl variant="standard" margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="multiple"
              id="residence_cluster_ids"
              label="Grupp"
              options={props.residenceClustersMinimalList}
              values={selectedResidenceClusters}
              onChange={(_e, _v, reason, details) => setMultipleResidences('cluster', reason, details?.option)}
              renderTags="simple"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
          <FormControl variant="standard" margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="multiple"
              id="residence_group_ids"
              label="Avtalspart"
              options={props.residenceGroupsMinimalList}
              values={selectedResidenceGroups}
              onChange={(_e, _v, reason, details) => setMultipleResidences('group', reason, details?.option)}
              renderTags="simple"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
          <FormControl variant="standard" margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="multiple"
              id="region_ids"
              label="Region"
              options={props.regionsMinimalList}
              values={selectedRegions}
              onChange={(_e, _v, reason, details) => setMultipleResidences('region', reason, details?.option)}
              renderTags="simple"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
          <FormControl variant="standard" margin="normal" required fullWidth>
            <FormAutocompleteBox
              type="multiple"
              id="residences"
              name="residences"
              label="Boenden för nyheten"
              options={props.residenceMinimalList}
              values={props.values.residences}
              onChange={(_, values) => props.setFieldValue('residences', values)}
              renderTags="simple"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={12} style={{ marginBottom: '20px', marginTop: '20px' }}>
          <div className={css(styles.text4)} style={{ textAlign: 'center' }}>
            {props.values.residences?.length == props.residenceMinimalList.length
              ? 'Alla boenden valda'
              : props.values.residences
                  ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((r) => r.name)
                  .join(', ')}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Divider />
        </Grid>
      </Grid>
      {props.isCreate && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>
              <b>
                OBS. Lediga tjänster får inte läggas upp som nyheter utan ska läggas upp på sidan Internrekrytering.
              </b>
            </p>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <FormTextField
          disabled={!(props.isCreate || props.values.editable)}
          required={true}
          size={6}
          fieldName="title"
          label="Nyhetsartikelns titel"
          placeholder="Titel"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormDateField
          required={true}
          size={3}
          fieldName="published_at"
          label="Publiceringsdatum"
          placeholder="Publiceringsdatum"
          minDate={dayjs().format('YYYY-MM-DD')}
          values={props.values}
          errors={props.errors}
          onChange={props.setFieldValue}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <RichTextEditorNews
            disabled={!(props.isCreate || props.values.editable)}
            handleChange={props.handleChange}
            initialValue={props.isCreate ? defaultContent : props.values.raw_content}
            setFieldValue={props.setFieldValue}
            fieldName="raw_content"
            subheader="Nyhetstext"
            controls={[
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'link',
              'media',
              'numberList',
              'bulletList',
              'quote',
            ]}
            signature={
              props.isCreate
                ? `${profile?.first_name} ${profile?.surname}`
                : `${props.values.author?.first_name} ${props.values.author?.surname}`
            }
            label={'Skriv din nyhetstext här...'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={5} />
        {(props.isCreate || props.values.editable) && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button
                type="submit"
                variant="contained"
                disabled={props.submitDisabled || props.values.roles?.length == 0}
              >
                {props.isCreate && 'Publicera'}
                {!props.isCreate && 'Spara'}
              </Button>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={3} />
        {!props.isCreate && props.values.deletable && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button
                variant="outlined"
                onClick={(): void => {
                  if (props.removal) props.removal();
                }}
              >
                Arkivera
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default NewsForm;
