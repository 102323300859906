import React, { useState, useEffect } from 'react';
import { RotateLoader } from 'react-spinners';
import { useNotify } from '../../Common/snackbarHooks';
import { DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import { commonStyles, css } from '../../Common/styling';
import { getInfo } from './../usersApi';
import InfoTabs from './InfoTabs';
import { ListParams } from '../../Common/types';

type Props = {
  setModalState: (modalState: boolean) => void;
  params: ListParams;
};

const InfoModal: React.FC<Props> = (props) => {
  const { notifyError } = useNotify();
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState<string>('');
  const [mobiles, setMobiles] = useState<string>('');
  const [statistics, setStatistics] = useState<string>('');
  const [showWarningText, setShowWarningText] = useState(false);

  useEffect(() => {
    handleGetInfo();
  }, []);

  const handleGetInfo = (): void => {
    setLoading(true);
    getInfo(props.params)
      .then(({ data }) => {
        setEmails(data.info.emails.join('\n'));
        setMobiles(data.info.mobiles.join(', '));
        setStatistics(data.info.statistics);
        setShowWarningText(data.info.show_warning_text);
      })
      .catch(() => notifyError('Det gick inte att hämta data'))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <DialogTitle>Hämta info</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            {showWarningText && (
              <span style={{ color: '#ad2220' }}>
                Obs! Om du använder <i>sök</i> och det finns fler än 1000 användare så visas endast 1000 av dem här.
              </span>
            )}
            <Grid item>
              <InfoTabs emails={emails} mobiles={mobiles} statistics={statistics} />
            </Grid>
            <DialogActions>
              <Button
                fullWidth
                color="error"
                onClick={(): void => {
                  props.setModalState(false);
                }}
              >
                STÄNG
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </div>
  );
};

export default InfoModal;
